import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useMediaQuery } from "react-responsive";
import BuyUSDTusingINR from "./pages/BuyUSDTusingINR/BuyUSDTusingINR";
import FooterMain from "./components/FooterMain/FooterMain";
import LoginOptions from "./pages/LoginOptions/LoginOptions";
import { AppContext } from "./context";
import styles from "./styles/app.module.scss";
import Header from "./components/header/header";
import { NavLink, useLocation } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import PurchaseHistory from "./pages/PurchaseHistory/PurchaseHistory";
import NavBarMobile from "./pages/Mobile";
import Spinner from "./components/Spinner/Spinner";
import { getAuth } from "firebase/auth";
import KycLandingPage from "./pages/KycLandingPage/KycLandingPage";
import AddBankAccount from "./pages/AddBankAccount/AddBankAccount";
import { useEthers, useLookupAddress } from "@usedapp/core";
import SupportLandingPage from "./pages/Support/SupportLandingPage";
import LoginToContinue from "./pages/LoginToContinue/LoginToContinue";
import { Helmet } from "react-helmet";
import EmailPswdSignIn from "./pages/EmailPswdSignIn/EmailPswdSignIn";

function App() {
  // global variable
  const hasInrt = false;
  // State variables
  //state to set the page number of login with google flow
  const [googleLoginFlowPageNumber, setGoogleLoginFlowPageNumber] =
    useState(false);
  // state to set the buy USDT flow page number
  const [buyUSDTFlowPageNumber, setBuyUSDTFlowPageNumber] = useState(null);
  // state to set when clicking login with google button
  const [onClickLoginButton, setOnClickLoginButton] = useState(false);
  const [profileEmail, setProfileEmail] = useState(false); // State for profile email
  const [profile, setProfile] = useState([]); // State for profile information
  const [amountInUsdt, setAmountInUsdt] = useState(""); // state for amount in USDT
  const [amountInINR, setAmountInINR] = useState(""); // state for amount in INR
  const [loading, setLoading] = useState(false); //loading state
  const [isLoggedIn, setIsLoggedIn] = useState(null); // state to check is loggedin or not for implementing protected routes
  // state for hamburger menu open/close
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // State for the transaction Id
  const [sessionTxId, setSessionTxId] = useState(null);
  // state to check if metamask is connected or not
  const [isWalletConnected, setWalletConnected] = useState(false);
  // state to set support flow page number
  const [supportFlowPageNumber, setSupportFlowPageNumber] = useState(null);
  // state to set the unique id for collections in firebase
  const [recipientWalletAddressID, setRecipientWalletAddressID] =
    useState(null);
  // State for setting the kyc flow page number
  const [KYCPageNumber, setKYCPageNumber] = useState(false);
  // state to store the purchase history details
  const [purchaseHistoryDetails, setPurchaseHistoryDetails] = useState([]);
  // State to store fetched tickets
  const [supportTickets, setSupportTickets] = useState([]);
  // status to store KYC status
  const [KycStatus, setKycStatus] = useState(false);
  const [isAnimationRequired, setIsAnimationRequired] = useState(null); //state to track whether animation required or not
  const [isNavBarClicked, setIsNavbarClicked] = useState(null);
  // state to set the error message if there is any at the time of login
  const [errorMessage, setErrorMessage] = useState("");
  // state to hide the facebook login button if there is error
  const [hideFacebookButton, setHideFacebookButton] = useState(false);
  // state to hide the twitter login button if there is error
  const [hideTwitterButton, setHideTwitterButton] = useState(false);
  // metamask address from useEthers
  const ens = useLookupAddress();
  const { account, activateBrowserWallet, deactivate } = useEthers();

  const isConnected = !!(ens || account);
  const location = useLocation();
  const auth = getAuth(); // Get the Firebase Auth instance
  //  Function for hamburger menu open/close
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleClickNavbar = () => {
    console.log("navbar clicked");
    setIsNavbarClicked(false);
  };
  // mobile view resolution setting
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" });
  // based on login with google
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoading(false);
        setProfileEmail(user.email);
        setGoogleLoginFlowPageNumber(-1);
        setOnClickLoginButton(false);
        setIsLoggedIn(true);
      }
    });
    return () => unregisterAuthObserver();
  }, [auth]);

  const AppLoginFlow = () => {
    switch (googleLoginFlowPageNumber) {
      case 0:
        return (
          <div
            className={cx(styles.loginWithGoogleContainer, {
              [styles.loginWithGoogleContainerMob]: isMobile,
            })}
          >
            <LoginOptions
              setProfileEmail={setProfileEmail}
              setIsLoggedIn={setIsLoggedIn}
              setGoogleLoginFlowPageNumber={setGoogleLoginFlowPageNumber}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
              setHideFacebookButton={setHideFacebookButton}
              hideFacebookButton={hideFacebookButton}
              setHideTwitterButton={setHideTwitterButton}
              hideTwitterButton={hideTwitterButton}
            />
          </div>
        );
      case 1:
        return (
          <div
            className={cx(styles.loginWithGoogleContainer, {
              [styles.loginWithGoogleContainerMob]: isMobile,
            })}
          >
            <EmailPswdSignIn
              setProfileEmail={setProfileEmail}
              setIsLoggedIn={setIsLoggedIn}
              setGoogleLoginFlowPageNumber={setGoogleLoginFlowPageNumber}
            />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Get the best rates for USDT in India | USDT-INR On ramp"
        />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        {/* You can add more meta tags or change existing ones as needed */}
        <title>TetherX</title>
      </Helmet>
      <AppContext.Provider
        value={{
          isMobile,
          buyUSDTFlowPageNumber,
          setBuyUSDTFlowPageNumber,
          loading,
          setLoading,
          profileEmail,
          recipientWalletAddressID,
          setRecipientWalletAddressID,
          sessionTxId,
          setSessionTxId,
          hasInrt,
          ens,
          account,
          activateBrowserWallet,
          deactivate,
          isWalletConnected,
          setWalletConnected,
          isConnected,
          supportFlowPageNumber,
          setSupportFlowPageNumber,
          KYCPageNumber,
          setKYCPageNumber,
          purchaseHistoryDetails,
          setPurchaseHistoryDetails,
          supportTickets,
          setSupportTickets,
          KycStatus,
          setKycStatus,
          isAnimationRequired,
          setIsAnimationRequired,
          isNavBarClicked,
          setIsNavbarClicked,
        }}
      >
        <Header
          profileEmail={profileEmail}
          setProfileEmail={setProfileEmail}
          profile={profile}
          setProfile={setProfile}
          setGoogleLoginFlowPageNumber={setGoogleLoginFlowPageNumber}
          googleLoginFlowPageNumber={googleLoginFlowPageNumber}
          onClickLoginButton={onClickLoginButton}
          setOnClickLoginButton={setOnClickLoginButton}
          toggleMenu={toggleMenu}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          setAmountInINR={setAmountInINR}
          setAmountInUSD={setAmountInINR}
        />
        {isMobile && profileEmail && (
          <NavBarMobile
            profileEmail={profileEmail}
            setProfile={setProfile}
            setAmountInINR={setAmountInINR}
            setAmountInUSD={setAmountInUsdt}
            setProfileEmail={setProfileEmail}
          />
        )}
        {loading && <Spinner />}
        {!loading && (
          <div className={styles.spinnerDiv}>
            <AppLoginFlow />
          </div>
        )}
        {!onClickLoginButton && (
          <div
            className={cx(styles.mainContentDiv, {
              [styles.mainContentDivMob]: isMobile,
            })}
          >
            {!isMobile && (
              <nav className={styles.navBar}>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  <li className={styles.navBarItems}>
                    <NavLink
                      onClick={handleClickNavbar}
                      className={`${styles.navLink} ${
                        location.pathname === "/buy" ? styles.activeNavLink : ""
                      }`}
                      to="/buy"
                    >
                      Buy
                    </NavLink>
                  </li>
                  {/*display only if user is logged in */}
                  {profileEmail && (
                    <li className={styles.navBarItems}>
                      <NavLink
                        onClick={handleClickNavbar}
                        className={`${styles.navLink} ${
                          location.pathname === "/profile"
                            ? styles.activeNavLink
                            : ""
                        }`}
                        to="/profile"
                      >
                        Profile
                      </NavLink>
                    </li>
                  )}
                  {/*display only if user is logged in */}
                  {profileEmail && (
                    <li className={styles.navBarItems}>
                      <NavLink
                        onClick={handleClickNavbar}
                        className={`${styles.navLink} ${
                          location.pathname === "/purchases"
                            ? styles.activeNavLink
                            : ""
                        }`}
                        to="/purchases"
                      >
                        Purchases
                      </NavLink>
                    </li>
                  )}
                  {/*display only if user is logged in */}
                  {profileEmail && (
                    <li className={styles.navBarItems}>
                      <NavLink
                        onClick={handleClickNavbar}
                        className={`${styles.navLink} ${
                          location.pathname === "/support"
                            ? styles.activeNavLink
                            : ""
                        }`}
                        to="/support"
                      >
                        Support
                      </NavLink>
                    </li>
                  )}
                  {/*display only if user is logged in */}
                  {profileEmail && hasInrt && (
                    <li className={styles.navBarItems}>
                      <NavLink
                        onClick={handleClickNavbar}
                        className={`${styles.navLink} ${
                          location.pathname === "/inrt"
                            ? styles.activeNavLink
                            : ""
                        }`}
                        to="/inrt"
                      >
                        INRT
                      </NavLink>
                    </li>
                  )}
                  {/*display only if user is logged in */}
                  {/*Discord button */}
                  {profileEmail && (
                    <div className={styles.navBarItems}>
                      <a
                        href="https://discord.gg/WVBmQ2sjU8"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.navLink}
                      >
                        Discord
                      </a>
                    </div>
                  )}
                  {/*display only if user is logged in */}
                  {/* Footer component */}
                  <div className={styles.footerDiv}>
                    <FooterMain />
                  </div>
                </ul>
              </nav>
            )}
            <div
              className={cx(styles.layoutDiv, {
                [styles.layoutDivMob]: isMobile,
              })}
            >
              <Routes>
                <Route path="/" element={<Navigate to="/buy" replace />} />
                <Route
                  path="/buy"
                  element={
                    <BuyUSDTusingINR
                      profileEmail={profileEmail}
                      setGoogleLoginFlowPageNumber={
                        setGoogleLoginFlowPageNumber
                      }
                      setOnClickLoginButton={setOnClickLoginButton}
                      setAmountInUsdt={setAmountInUsdt}
                      amountInUsdt={amountInUsdt}
                      amountInINR={amountInINR}
                      setAmountInINR={setAmountInINR}
                    />
                  }
                />
                {/*display only if user is logged in */}
                {isLoggedIn && (
                  <Route path="/profile" element={<KycLandingPage />} />
                )}
                {isLoggedIn && (
                  <Route path="/purchases" element={<PurchaseHistory />} />
                )}
                <Route
                  path="/support"
                  element={
                    isLoggedIn ? (
                      <SupportLandingPage />
                    ) : (
                      <div
                        className={cx(styles.loginToContinueContainer, {
                          [styles.loginToContinueContainerMob]: isMobile,
                        })}
                      >
                        <LoginToContinue
                          setGoogleLoginFlowPageNumber={
                            setGoogleLoginFlowPageNumber
                          }
                          setOnClickLoginButton={setOnClickLoginButton}
                        />
                      </div>
                    )
                  }
                />
                {hasInrt && isLoggedIn && (
                  <Route path="/inrt" element={<AddBankAccount />} />
                )}
              </Routes>
            </div>
          </div>
        )}
      </AppContext.Provider>
    </>
  );
}

export default App;
