import { shortenAddress } from "@usedapp/core";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MetaMaskIcon from "../../assets/metamaskIcon.svg";
import { AppContext } from "../../context";
import { memo } from "react";
import styles from "./index.module.scss";
import logoutIcon from "../../assets/LogoutIcon.svg";
import cx from "classnames";
const MetaIconElem = styled.img`
  margin: auto 0 auto 6px;
  height: 15px;
  width: 15px;
  @media screen and (min-width: 1500px) {
    width: 18px;
    height: 18px;
  }
  @media screen and (min-width: 1680px) {
    width: 19px;
    height: 19px;
  }
`;

const MetaDiv = styled.div`
  display: flex;
  background: linear-gradient(90deg, #212d3d 0%, #0e1a2c 100%);
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 0px;
  height: 33px;
  @media screen and (min-width: 993px) {
    width: 152px;
  }
  @media screen and (min-width: 1050px) {
    width: 160px;
  }
  @media screen and (min-width: 1180px) {
    width: 155px;
  }
  @media screen and (min-width: 1300px) {
    width: 175px;
  }
  @media screen and (min-width: 1500px) {
    width: 200px;
  }
  @media screen and (min-width: 1680px) {
    width: 215px;
  }
  @media screen and (min-width: 1740px) {
    width: 230px;
  }
`;
const MetaAddress = styled.span`
  font-family: "metrophobic";
  color: #f9fafa;
  font-weight: 400;
  align-self: center;
  padding: 0 9px 0 4px;
  letter-spacing: -0.02em;
  @media screen and (min-width: 993px) {
    font-size: 15px;
    padding: 0 5px 0 5px;
  }
  @media screen and (min-width: 1300px) {
    font-size: 17px;
    padding: 0 7px 0 7px;
  }
  @media screen and (min-width: 1500px) {
    font-size: 18px;
    padding: 0 8px 0 8px;
  }
  @media screen and (min-width: 1740px) {
    font-size: 20px;
  }
`;

const HeaderWalletButton = () => {
  const [rendered, setRendered] = useState("");
  const {
    isMobile,
    ens,
    account,
    activateBrowserWallet,
    deactivate,
    setWalletConnected,
  } = useContext(AppContext);

  useEffect(() => {
    if (ens && ens.address) {
      setRendered(ens.address);
    } else if (account) {
      setRendered(shortenAddress(account));
    } else {
      setRendered("");
    }
  }, [account, ens, setRendered]);
  const handleClickCrossIcon = () => {
    deactivate();
    setWalletConnected(false);
  };

  if ((account || ens) && !isMobile) {
    return (
      <MetaDiv>
        <MetaIconElem src={MetaMaskIcon} alt="metaMask" />
        <MetaAddress>{rendered}</MetaAddress>
        <button
          onClick={() => {
            if (!account) {
              activateBrowserWallet();
            } else {
              console.log("calling deactivate");
              handleClickCrossIcon();
            }
          }}
          className={cx(styles.logoutButton, {
            [styles.logoutButtonMob]: isMobile,
          })}
        >
          <img
            src={logoutIcon}
            alt="logout"
            className={cx(styles.logoutIcon, {
              [styles.logoutIconMob]: isMobile,
            })}
          />
        </button>
      </MetaDiv>
    );
  }
  return null;
};

export default memo(HeaderWalletButton);
