import React, { useState, useEffect, useContext } from "react";
import attachmentLogo from "../../assets/attachmentLogo.svg";
import { firestore } from "../../firebase";
import ImagePreview from "./ImagePreview";
import PdfPreview from "./PdfPreview";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import styles from "./index.module.scss";
import { AppContext } from "../../context";

function FileUpload({
  id,
  setErrorForID,
  panCard,
  setPanCard,
  aadharCard,
  setAadharCard,
  setErrorForPancard,
  setErrorForAadhar,
  reSubmitKyc,
  setIsFileUploaded,
  pancardFileType,
  setPancardFileType,
  aadharFileType,
  setAadharFileType,
  setIsLoadingAadhar,
  setIsLoadingPancard,
  selectedAadharFileName,
  setSelectedAadharFileName,
  selectedPancardFileName,
  setSelectedPancardFileName,
  aadharBack,
  setAadharBack,
  setErrorForAadharBack,
  aadharBackFileType,
  setAadharBackFileType,
  selectedAadharBackFileName,
  setSelectedAadharBackFileName,
  setIsLoadingAadharBack,
}) {
  const { profileEmail } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFileUpload = async (file) => {
    if (file && file.size <= 5000000 && /(jpeg|png|pdf)$/i.test(file.name)) {
      const storage = getStorage(); // Get the storage object
      const storageRef = ref(storage); // Create a reference to the storage root
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const isImage = /(jpeg|png|jpg)$/i.test(fileExtension);
      let filePath = ""; // Initialize the file path variable

      if (id === "pancard") {
        filePath = `kycDetails/${profileEmail}/panCard/${file.name}`;
      } else if (id === "aadharFront") {
        filePath = `kycDetails/${profileEmail}/aadharFront/${file.name}`;
      } else if (id === "aadharBack") {
        filePath = `kycDetails/${profileEmail}/aadharBack/${file.name}`;
      }

      if (filePath !== "") {
        const fileRef = ref(storageRef, filePath); // Create a reference to the file

        try {
          if (id === "pancard") {
            setIsLoadingPancard(true);
          } else if (id === "aadharFront") {
            setIsLoadingAadhar(true);
          } else if (id === "aadharBack") {
            setIsLoadingAadharBack(true);
          }
          // Upload the file to Firebase Storage
          await uploadBytes(fileRef, file);
          // Retrieve the download URL for the uploaded file
          const downloadURL = await getDownloadURL(fileRef);

          if (id === "pancard") {
            setPanCard(downloadURL); // Set the download URL
            setIsFileUploaded(true);
            setErrorForPancard(false); // Reset the error for pancard
            setPancardFileType(isImage ? "image" : "pdf"); // Set pancardFileType
          } else if (id === "aadharFront") {
            setAadharCard(downloadURL); // Set the download URL
            setIsFileUploaded(true);
            setErrorForAadhar(false); // Reset the error for aadhar card
            setAadharFileType(isImage ? "image" : "pdf"); // Set aadharFileType
          } else if (id === "aadharBack") {
            setAadharBack(downloadURL);
            setIsFileUploaded(true);
            setErrorForAadharBack(false);
            setAadharBackFileType(isImage ? "image" : "pdf"); // Set pancardFileType
          }
          setErrorMessage(null);
        } catch (error) {
          console.error("Error uploading file to Firebase Storage:", error);
        } finally {
          setIsLoadingAadhar(false); // Stop loading
          setIsLoadingPancard(false);
          setIsLoadingAadharBack(false);
        }
      }
    } else {
      if (id === "pancard") {
        setPanCard(null);
      } else if (id === "aadharFront") {
        setAadharCard(null);
      } else if (id === "aadharBack") {
        setAadharBack(null);
      }

      setErrorMessage(
        "Please select a valid file (JPEG, PNG, or PDF) that is under 5MB!"
      );
    }
  };
  async function fetchDetails() {
    const profileDataRef = collection(firestore, "userData"); // Reference to the "profileData" collection
    const profileDocRef = doc(profileDataRef, profileEmail); // Reference to the document with email ID as document ID
    const profileData = {
      Pancard: panCard,
      AadharCard: aadharCard,
      AadharBackside: aadharBack,
    };
    await updateDoc(profileDocRef, profileData);
  }

  useEffect(() => {
    if (panCard && aadharCard && aadharBack) {
      fetchDetails();
    }
  }, []);

  const handleFileSelect = (event) => {
    setErrorForID(false);
    const file = event.target.files[0];
    const selectedFile = file.name; // Set the selected file name
    handleFileUpload(file);
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const isImage = /(jpeg|png|jpg)$/i.test(fileExtension);

    if (id === "pancard") {
      setSelectedPancardFileName(selectedFile); // Set the selected pancard file name
      setPancardFileType(isImage ? "image" : "pdf");
    } else if (id === "aadharFront") {
      setSelectedAadharFileName(selectedFile); // Set the selected aadhar file name
      setAadharFileType(isImage ? "image" : "pdf");
    } else if (id === "aadharBack") {
      setSelectedAadharBackFileName(selectedFile);
      setAadharBackFileType(isImage ? "image" : "pdf");
    }
  };

  const handleImageClick = () => {
    if (reSubmitKyc !== "Pending" && reSubmitKyc !== "Verified") {
      // Trigger file selection when the image is clicked
      document.getElementById(`${id}-upload`).click();
    }
  };
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const profileDataRef = collection(firestore, "userData");
        const documentId = profileEmail; // Email of the user
        const userDocRef = doc(profileDataRef, documentId);
        const documentSnapshot = await getDoc(userDocRef);
        if (documentSnapshot.exists()) {
          const userData = documentSnapshot.data();
          // Set the values from Firestore to the state
          setPanCard(userData.Pancard || "");
          setAadharCard(userData.AadharCard || "");
          setAadharBack(userData.AadharBackside || "");
          const isPancardImage =
            userData.Pancard.includes("image") ||
            /(png|jpeg|jpg)/i.test(userData.Pancard);

          setPancardFileType(isPancardImage ? "image" : "pdf");

          const isAadharImage =
            userData.AadharCard.includes("image") ||
            /(png|jpeg|jpg)/i.test(userData.AadharCard);

          setAadharFileType(isAadharImage ? "image" : "pdf");

          const isAadharBackImage =
            userData.AadharBackside.includes("image") ||
            /(png|jpeg|jpg)/i.test(userData.AadharBackside);

          setAadharBackFileType(isAadharBackImage ? "image" : "pdf");
        } else {
          // The document does not exist
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error retrieving profile information:", error);
      }
    };

    fetchProfileData();
  }, [profileEmail]);

  return (
    <div>
      {(panCard && id === "pancard") ||
      (aadharCard && id === "aadharFront") ||
      (aadharBack && id === "aadharBack") ? (
        <div className={styles.previewDiv}>
          {id === "pancard" && pancardFileType === "image" ? (
            <ImagePreview
              src={panCard}
              alt={`attachment-${id}`}
              onClick={handleImageClick}
            />
          ) : id === "aadharFront" && aadharFileType === "image" ? (
            <ImagePreview
              src={aadharCard}
              alt={`attachment-${id}`}
              onClick={handleImageClick}
            />
          ) : id === "aadharBack" && aadharBackFileType === "image" ? (
            <ImagePreview
              src={aadharBack}
              alt={`attachment-${id}`}
              onClick={handleImageClick}
            />
          ) : (
            <PdfPreview
              onClick={handleImageClick}
              selectedFileName={
                id === "pancard"
                  ? selectedPancardFileName
                  : id === "aadharFront"
                  ? selectedAadharFileName
                  : id === "aadharBack"
                  ? selectedAadharBackFileName
                  : null
              }
            />
          )}
          <label htmlFor={`${id}-upload`}>
            <div className={styles.imageUpload}></div>
          </label>
          <input
            type="file"
            id={`${id}-upload`}
            accept=".jpeg,.png,.pdf,.jpg"
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
        </div>
      ) : (
        <div className={styles.previewDivWithoutFile}>
          <label htmlFor={`${id}-upload`}>
            <div className={styles.imageUpload}>
              <div className={styles.uploadIcon}>
                <img src={attachmentLogo} alt="attachment-logo" />
              </div>
              <div className={styles.dragAndDropText}>
                <div className={styles.browseLink}>Upload attachment</div>
              </div>
            </div>
          </label>
          <input
            type="file"
            id={`${id}-upload`}
            accept=".jpeg,.png,.pdf,.jpg"
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
        </div>
      )}
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
}

export default FileUpload;
