import React, { useEffect } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import { useContext, useState } from "react";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
import Profile from "../Profile/Profile";
import { PROFILE_ENUM } from "../../enums/profileEnum";
import KYCCompleted from "../KycStatus/KycStatus";
import { firestore } from "../../firebase";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
const KYC = () => {
  const { isMobile, profileEmail, KYCPageNumber, setKYCPageNumber } =
    useContext(AppContext);
  const handleClickKYCAMLButton = () => {
    setKYCPageNumber(0);
  };
  const [reSubmitKyc, setResubmitKyc] = useState(null);
  // state for storing first name
  const [firstName, setFirstName] = useState("");
  // state for storing last name
  const [lastName, setLastName] = useState("");
  // state for storing phone number
  const [phoneNumber, setPhoneNumber] = useState("");
  // state for storing email address
  const [email, setEmail] = useState("");
  // state for storing pancard id
  const [panCard, setPanCard] = useState("");
  // state for storing aadhar card id
  const [aadharCard, setAadharCard] = useState("");
  // state for storing aadhar card backside
  const [aadharBack, setAadharBack] = useState(null);
  // state for storing user holding aadhar
  const [aadharHolding, setAadharHolding] = useState(null);
  // state for opening the modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // state to set error for invalid email
  const [invalidEmail, setInvalidEmail] = useState(false);
  // state to set error for profile updation
  const [errorForProfileUpdate, setErrorForProfileUpdate] = useState(false);
  //state to set error for invalid phone number
  const [invalidNumber, setInvalidNumber] = useState(false);
  //state to set error for name
  const [errorForFirstName, setErrorForFirstName] = useState(false);
  const [errorForLastName, setErrorForLastName] = useState(false);
  //state to set error for phone number
  const [errorForNumber, setErrorForNumber] = useState(false);
  //state to set error for email
  const [errorForEmail, setErrorForEmail] = useState(false);
  //state to set error for id upload
  const [errorForID, setErrorForID] = useState(false);
  //state to set error for idBackSide id upload
  const [errorForPancard, setErrorForPancard] = useState(false);
  //state to set error for idFrontSide id upload
  const [errorForAadhar, setErrorForAadhar] = useState(false);
  // state to set error for aadhar back side
  const [errorForAadharBack, setErrorForAadharBack] = useState(false);
  //state to set error for idFrontSide id upload
  const [errorForAadharHold, setErrorForAadharHold] = useState(false);
  // state to display update successfull message
  const [updationSuccessful, setUpdationSuccesful] = useState(false);
  // state for storing pancard number
  const [pancardNumber, setPancardNumber] = useState("");
  // state for storing aadhar card number
  const [aadharNumber, setAadharNumber] = useState("");
  //state to set error for pancard number
  const [errorForPancardNumber, setErrorForPancardNumber] = useState(false);
  //state to set error for aadhar number
  const [errorForAadharNumber, setErrorForAadharNumber] = useState(false);
  // state for displaying error message
  const [error, setError] = useState("");
  //state to know whether any file is uploaded or not
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [pancardFileType, setPancardFileType] = useState(""); // selected file type for pancard
  const [aadharFileType, setAadharFileType] = useState(""); // selected file type for aadhar
  const [aadharBackFileType, setAadharBackFileType] = useState(""); // selected file type for aadhar back side
  const [selectedPancardFileName, setSelectedPancardFileName] = useState("");
  const [selectedAadharFileName, setSelectedAadharFileName] = useState("");
  const [selectedAadharBackFileName, setSelectedAadharBackFileName] =
    useState("");
  // Function to validate the phone number
  const handlePhoneNumberChange = (e) => {
    setErrorForNumber(false);
    setInvalidNumber(false);
    setErrorForProfileUpdate(false);
    const enteredValue = e.target.value;
    const numericValue = enteredValue.replace(/\D/g, ""); // Remove non-digit characters
    const limitedValue = numericValue.slice(0, 10); // Limit to 10 digits
    setPhoneNumber(limitedValue);
  };
  // Function to validate the aadhar number
  const handleAadharNumberChange = (e) => {
    setErrorForPancardNumber(false);
    setErrorForProfileUpdate(false);
    const enteredValue = e.target.value;
    const numericValue = enteredValue.replace(/\D/g, ""); // Remove non-digit characters
    const limitedValue = numericValue.slice(0, 12); // Limit to 12 digits
    setAadharNumber(limitedValue);
  };
  // Function to validate PAN card number format
  const isValidPancardNumber = (value) => {
    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    return panPattern.test(value);
  };

  // Function to validate the pancard number
  const handlePancardNumberChange = (e) => {
    setError("");
    setErrorForPancardNumber(false); // Clear any previous error for Aadhar number
    setErrorForProfileUpdate(false);
    const enteredValue = e.target.value.toUpperCase(); // Convert to uppercase to handle both upper and lower case letters
    const limitedValue = enteredValue.slice(0, 10); // Limit to 10 digits
    setPancardNumber(limitedValue);
  };

  // function to handle name input field
  const handleChangeInputFieldFirstName = (e) => {
    setErrorForFirstName(false);
    setErrorForProfileUpdate(false);
    setFirstName(e.target.value);
  };
  const handleChangeInputFieldLastName = (e) => {
    setErrorForLastName(false);
    setErrorForProfileUpdate(false);
    setLastName(e.target.value);
  };
  // validation of email
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  // function to handle email input field
  const handleChangeInputFieldEmail = (e) => {
    setErrorForEmail(false);
    setInvalidEmail(false);
    setErrorForProfileUpdate(false);
    setEmail(e.target.value);
  };
  // function to update the profile details and open the popup
  const handlesubmitUpdateProfile = async () => {
    if (
      !email &&
      !firstName &&
      !lastName &&
      !phoneNumber &&
      !pancardNumber &&
      !aadharNumber &&
      !panCard &&
      !aadharCard &&
      !aadharBack
    ) {
      setErrorForProfileUpdate(true);
    } else if (!firstName) {
      setErrorForFirstName(true);
    } else if (!lastName) {
      setErrorForLastName(true);
    } else if (email && !isValidEmail(email)) {
      setInvalidEmail(true);
    } else if (!email) {
      setErrorForEmail(true);
    } else if (phoneNumber && phoneNumber.length < 10) {
      setInvalidNumber(true);
    } else if (!phoneNumber) {
      setErrorForNumber(true);
    } else if (!pancardNumber) {
      setErrorForPancardNumber(true);
    } else if (!isValidPancardNumber(pancardNumber)) {
      setError("Invalid pancard number!");
    } else if (!aadharNumber) {
      setErrorForAadharNumber(true);
    } else if (!aadharCard) {
      setErrorForAadhar(true);
    } else if (!aadharBack) {
      setErrorForAadharBack(true);
    } else if (!panCard) {
      setErrorForPancard(true);
    } else if (!aadharHolding) {
      setErrorForAadharHold(true);
    } else {
      setInvalidEmail(false);
      setInvalidNumber(false);
      setErrorForEmail(false);
      setErrorForFirstName(false);
      setErrorForNumber(false);
      setErrorForID(false);
      setErrorForProfileUpdate(false);
      setModalIsOpen(true);

      const profileDataRef = collection(firestore, "userData"); // Reference to the "profileData" collection
      const profileDocRef = doc(profileDataRef, profileEmail); // Reference to the document with email ID as document ID
      try {
        const profileData = {
          email: profileEmail,
          firstName,
          lastName,
          phoneNumber,
          pancardNumber,
          aadharNumber,
          KYCStatus: "Pending",
        };
        await updateDoc(profileDocRef, profileData);
      } catch (error) {
        console.error("Error saving profile information:", error);
        setError("Error saving profile information. Please try again later.");
      }
    }
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const profileDataRef = collection(firestore, "userData");
        const documentId = profileEmail; // email for the logged in user, to check if exists
        const userDocRef = doc(profileDataRef, documentId);

        const documentSnapshot = await getDoc(userDocRef);

        if (documentSnapshot.exists()) {
          // The document with the specified email address exists
          const userData = documentSnapshot.data();
          // Set the values from Firestore to the state
          setFirstName(userData.firstName || "");
          setLastName(userData.lastName || "");
          setPhoneNumber(userData.phoneNumber || "");
          setEmail(userData.email || "");
          setPancardNumber(userData.pancardNumber || "");
          setAadharNumber(userData.aadharNumber || "");
        } else {
          // The document does not exist
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error retrieving profile information:", error);
        setError(
          "Error retrieving profile information. Please try again later."
        );
      }
    };

    fetchProfileData();
  }, [profileEmail]);
  const CardBody = () => (
    <div
      className={cx(styles.KYCMainDiv, {
        [styles.KYCMainDivWeb]: !isMobile,
        [styles.KYCMainDivMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.KYCDiv, {
          [styles.KYCDivWeb]: !isMobile,
          [styles.KYCDivMob]: isMobile,
        })}
      >
        {PROFILE_ENUM.profileIsNotverified}
      </div>
      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.KYCAMLButton, {
            [styles.KYCAMLButtonWeb]: !isMobile,
            [styles.KYCAMLButtonMob]: isMobile,
          })}
          onClick={handleClickKYCAMLButton}
        >
          {PROFILE_ENUM.KycButton}
        </ButtonRade>
      </div>
    </div>
  );

  return (
    <>
      {(() => {
        switch (KYCPageNumber) {
          case 0:
            return (
              <Profile
                reSubmitKyc={reSubmitKyc}
                setResubmitKyc={setResubmitKyc}
                firstName={firstName}
                lastName={lastName}
                phoneNumber={phoneNumber}
                email={email}
                handleChangeInputFieldFirstName={
                  handleChangeInputFieldFirstName
                }
                handleChangeInputFieldLastName={handleChangeInputFieldLastName}
                handlePhoneNumberChange={handlePhoneNumberChange}
                setErrorForID={setErrorForID}
                panCard={panCard}
                setPanCard={setPanCard}
                handleChangeInputFieldEmail={handleChangeInputFieldEmail}
                setErrorForPancard={setErrorForPancard}
                aadharCard={aadharCard}
                setAadharCard={setAadharCard}
                errorForAadhar={errorForAadhar}
                errorForPancard={errorForPancard}
                setErrorForAadhar={setErrorForAadhar}
                handlesubmitUpdateProfile={handlesubmitUpdateProfile}
                invalidEmail={invalidEmail}
                errorForProfileUpdate={errorForProfileUpdate}
                invalidNumber={invalidNumber}
                errorForFirstName={errorForFirstName}
                errorForLastName={errorForLastName}
                errorForEmail={errorForEmail}
                errorForNumber={errorForNumber}
                error={error}
                errorForID={errorForID}
                updationSuccessful={updationSuccessful}
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                setUpdationSuccesful={setUpdationSuccesful}
                isFileUploaded={isFileUploaded}
                setIsFileUploaded={setIsFileUploaded}
                pancardFileType={pancardFileType}
                setPancardFileType={setPancardFileType}
                aadharFileType={aadharFileType}
                setAadharFileType={setAadharFileType}
                aadharHolding={aadharHolding}
                setAadharHolding={setAadharHolding}
                errorForAadharHold={errorForAadharHold}
                setErrorForAadharHold={setErrorForAadharHold}
                selectedAadharFileName={selectedAadharFileName}
                setSelectedAadharFileName={setSelectedAadharFileName}
                selectedPancardFileName={selectedPancardFileName}
                setSelectedPancardFileName={setSelectedPancardFileName}
                aadharBack={aadharBack}
                setAadharBack={setAadharBack}
                errorForAadharBack={errorForAadharBack}
                setErrorForAadharBack={setErrorForAadharBack}
                aadharBackFileType={aadharBackFileType}
                setAadharBackFileType={setAadharBackFileType}
                selectedAadharBackFileName={selectedAadharBackFileName}
                setSelectedAadharBackFileName={setSelectedAadharBackFileName}
                aadharNumber={aadharNumber}
                pancardNumber={pancardNumber}
                errorForAadharNumber={errorForAadharNumber}
                errorForPancardNumber={errorForPancardNumber}
                handleAadharNumberChange={handleAadharNumberChange}
                handlePancardNumberChange={handlePancardNumberChange}
              />
            );
          case 1:
            return (
              <KYCCompleted
                reSubmitKyc={reSubmitKyc}
                setResubmitKyc={setResubmitKyc}
                profileEmail={profileEmail}
                firstName={firstName}
                lastName={lastName}
                phoneNumber={phoneNumber}
                email={email}
                handleChangeInputFieldFirstName={
                  handleChangeInputFieldFirstName
                }
                handleChangeInputFieldLastName={handleChangeInputFieldLastName}
                handlePhoneNumberChange={handlePhoneNumberChange}
                setErrorForID={setErrorForID}
                panCard={panCard}
                setPanCard={setPanCard}
                aadharCard={aadharCard}
                setAadharCard={setAadharCard}
                handleChangeInputFieldEmail={handleChangeInputFieldEmail}
                setErrorForPancard={setErrorForPancard}
                errorForAadhar={errorForAadhar}
                errorForPancard={errorForPancard}
                setErrorForAadhar={setErrorForAadhar}
                handlesubmitUpdateProfile={handlesubmitUpdateProfile}
                invalidEmail={invalidEmail}
                errorForProfileUpdate={errorForProfileUpdate}
                invalidNumber={invalidNumber}
                errorForFirstName={errorForFirstName}
                errorForLastName={errorForLastName}
                errorForEmail={errorForEmail}
                errorForNumber={errorForNumber}
                error={error}
                errorForID={errorForID}
                updationSuccessful={updationSuccessful}
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                setUpdationSuccesful={setUpdationSuccesful}
                isFileUploaded={isFileUploaded}
                setIsFileUploaded={setIsFileUploaded}
                pancardFileType={pancardFileType}
                setPancardFileType={setPancardFileType}
                aadharFileType={aadharFileType}
                setAadharFileType={setAadharFileType}
                aadharHolding={aadharHolding}
                setAadharHolding={setAadharHolding}
                errorForAadharHold={errorForAadharHold}
                setErrorForAadharHold={setErrorForAadharHold}
                selectedAadharFileName={selectedAadharFileName}
                setSelectedAadharFileName={setSelectedAadharFileName}
                selectedPancardFileName={selectedPancardFileName}
                setSelectedPancardFileName={setSelectedPancardFileName}
                aadharBack={aadharBack}
                setAadharBack={setAadharBack}
                setErrorForAadharBack={setErrorForAadharBack}
                aadharBackFileType={aadharBackFileType}
                setAadharBackFileType={setAadharBackFileType}
                selectedAadharBackFileName={selectedAadharBackFileName}
                setSelectedAadharBackFileName={setSelectedAadharBackFileName}
                aadharNumber={aadharNumber}
                pancardNumber={pancardNumber}
              />
            );
          default:
            return <CardBody />;
        }
      })()}
    </>
  );
};

export default KYC;
