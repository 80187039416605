import React from "react";
import styles from "../../components/RadeButtons/index.module.scss";
const ButtonRade = ({
  outline,
  children,
  customStyling,
  disabled,
  ...otherProps
}) => {
  return (
    <button
      className={
        ({
          [styles.outline]: outline,
          [styles.filled]: !outline,
          [styles.disabled]: disabled,
        },
        customStyling)
      }
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  );
};
export default ButtonRade;
