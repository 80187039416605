import React, { useState } from "react";
import ButtonRade from "../../components/RadeButtons";
import { PROFILE_ENUM } from "../../enums/profileEnum";
import styles from "./index.module.scss";
import cx from "classnames";
import { AppContext } from "../../context";
import { useContext } from "react";
import KYCInProgess from "../../assets/KYCInProcessIcon.svg";
import KYCVerified from "../../assets/KYCCompleted.svg";
import KYCRejected from "../../assets/KYCRejectedIcon.svg";
import { getDoc, doc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import ProfileDetailsFetch from "../Profile/ProfileDetailsFetch";
import ConfirmUpdateProfile from "../ConfirmUpdateProfile/ConfirmUpdateProfile";
import Modal from "react-modal";

function KYCCompleted({
  profileEmail,
  firstName,
  lastName,
  phoneNumber,
  email,
  handlePhoneNumberChange,
  setErrorForID,
  handleChangeInputFieldEmail,
  setErrorForPancard,
  setErrorForAadhar,
  reSubmitKyc,
  handleChangeInputFieldFirstName,
  handleChangeInputFieldLastName,
  panCard,
  setPanCard,
  aadharCard,
  setAadharCard,
  setResubmitKyc,
  handlesubmitUpdateProfile,
  setIsFileUploaded,
  isFileUploaded,
  pancardFileType,
  setPancardFileType,
  aadharFileType,
  setAadharFileType,
  aadharHolding,
  setAadharHolding,
  errorForAadharHold,
  setErrorForAadharHold,
  selectedAadharFileName,
  setSelectedAadharFileName,
  selectedPancardFileName,
  setSelectedPancardFileName,
  aadharBack,
  setAadharBack,
  setErrorForAadharBack,
  aadharBackFileType,
  setAadharBackFileType,
  selectedAadharBackFileName,
  setSelectedAadharBackFileName,
  aadharNumber,
  pancardNumber,
}) {
  const { isMobile, setKYCPageNumber, KycStatus, setKycStatus } =
    useContext(AppContext);

  const [confirmUpdateModalOpen, setConfirmUpdateModalOpen] = useState(null);
  useEffect(() => {
    async function fetchKYCStatus() {
      const usersRef = collection(db, "userData");
      const userDocRef = doc(usersRef, profileEmail);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const kYCStatus = userData.KYCStatus;

        if (kYCStatus === "Pending") {
          setKycStatus("Pending");
          setResubmitKyc("Pending");
        } else if (kYCStatus === "Verified") {
          setKycStatus("Verified");
          setResubmitKyc("Verified");
        } else if (kYCStatus === "Rejected") {
          setKycStatus("Rejected");
          setResubmitKyc("Verified");
        }
      }
    }
    fetchKYCStatus();
  }, [KycStatus, confirmUpdateModalOpen]);

  const handleClickResubmitKyc = () => {
    setResubmitKyc("Rejected");
  };
  const handleOnClickResubmitButton = () => {
    setConfirmUpdateModalOpen(true);
  };

  return (
    <>
      <div
        className={cx(styles.KYCInProgressContainer, {
          [styles.KYCInProgressContainerWeb]: !isMobile,
          [styles.KYCInProgressContainerMob]: isMobile,
        })}
      >
        {KycStatus === "Pending" && (
          <>
            <div
              className={cx(styles.KYCApplicationSubmitted, {
                [styles.KYCApplicationSubmittedWeb]: !isMobile,
                [styles.KYCApplicationSubmittedMob]: isMobile,
              })}
            >
              {PROFILE_ENUM.KYCApplicationSubmitted}
            </div>
            <div
              className={cx(styles.weWillLetYouKnow, {
                [styles.weWillLetYouKnowWeb]: !isMobile,
                [styles.weWillLetYouKnowMob]: isMobile,
              })}
            >
              {PROFILE_ENUM.weWillLetYouKnow}
            </div>
          </>
        )}
        <div
          className={cx(styles.KYCButtonDiv, {
            [styles.KYCButtonDivWeb]: !isMobile,
            [styles.KYCButtonDivMob]: isMobile,
          })}
        >
          <ButtonRade
            customStyling={cx(styles.KYCButton, {
              [styles.KYCButtonWeb]: !isMobile,
              [styles.KYCButtonMob]: isMobile,
            })}
          >
            {PROFILE_ENUM.KYC}

            {KycStatus === "Pending" && (
              <img
                src={KYCInProgess}
                alt="KYC in progress"
                className={cx(styles.KYCInProgess, {
                  [styles.KYCInProgessWeb]: !isMobile,
                  [styles.KYCInProgessMob]: isMobile,
                })}
              />
            )}
            {KycStatus === "Verified" && (
              <img
                src={KYCVerified}
                alt="KYC Verified"
                className={cx(styles.KYCInProgess, {
                  [styles.KYCInProgessWeb]: !isMobile,
                  [styles.KYCInProgessMob]: isMobile,
                })}
              />
            )}
            {KycStatus === "Rejected" && (
              <img
                src={KYCRejected}
                alt="KYC Rejeceted"
                className={cx(styles.KYCInProgess, {
                  [styles.KYCInProgessWeb]: !isMobile,
                  [styles.KYCInProgessMob]: isMobile,
                })}
              />
            )}
          </ButtonRade>
          {KycStatus === "Rejected" && (
            <div>
              <ButtonRade
                customStyling={cx(styles.reSubmitKycButton, {
                  [styles.reSubmitKycButtonMob]: isMobile,
                })}
                onClick={handleClickResubmitKyc}
              >
                {PROFILE_ENUM.reSubmitKyc}
              </ButtonRade>
            </div>
          )}
        </div>
        <div
          className={cx(styles.statusContainer, {
            [styles.statusContainerWeb]: !isMobile,
            [styles.statusContainerMob]: isMobile,
          })}
        >
          {" "}
          <div
            className={cx(styles.statusOfKYC, {
              [styles.statusOfKYCWeb]: !isMobile,
              [styles.statusOfKYCMob]: isMobile,
            })}
          >
            {PROFILE_ENUM.statusOfKYC}
          </div>
          <div
            className={cx(styles.submittedAndWaiting, {
              [styles.submittedAndWaitingWeb]: !isMobile,
              [styles.submittedAndWaitingMob]: isMobile,
            })}
          >
            {KycStatus === "Pending" && (
              <div
                className={cx(styles.submittedAndWaiting, {
                  [styles.submittedAndWaitingWeb]: !isMobile,
                  [styles.submittedAndWaitingMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.submittedAndWaiting}
              </div>
            )}
            {KycStatus === "Verified" && (
              <div
                className={cx(styles.yourAccountIsVerified, {
                  [styles.yourAccountIsVerifiedWeb]: !isMobile,
                  [styles.yourAccountIsVerifiedMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.yourAccountIsVerified}
              </div>
            )}
            {KycStatus === "Rejected" && (
              <div
                className={cx(styles.yourApplicationIsRejected, {
                  [styles.yourApplicationIsRejectedWeb]: !isMobile,
                  [styles.yourApplicationIsRejectedMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.yourApplicationIsRejected}
                {/* To be implemented later */}
                {/* <div
                  className={cx(styles.reasonStatement, {
                    [styles.reasonStatementMob]: isMobile,
                  })}
                >
                  {PROFILE_ENUM.KycRejectedReason}
                </div> */}
              </div>
            )}
          </div>
        </div>
        <ProfileDetailsFetch
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          email={email}
          handleChangeInputFieldFirstName={handleChangeInputFieldFirstName}
          handleChangeInputFieldLastName={handleChangeInputFieldLastName}
          handlePhoneNumberChange={handlePhoneNumberChange}
          setErrorForID={setErrorForID}
          handleChangeInputFieldEmail={handleChangeInputFieldEmail}
          setErrorForPancard={setErrorForPancard}
          setErrorForAadhar={setErrorForAadhar}
          reSubmitKyc={reSubmitKyc}
          setResubmitKyc={setResubmitKyc}
          panCard={panCard}
          setPanCard={setPanCard}
          aadharCard={aadharCard}
          setAadharCard={setAadharCard}
          setIsFileUploaded={setIsFileUploaded}
          isFileUploaded={isFileUploaded}
          pancardFileType={pancardFileType}
          setPancardFileType={setPancardFileType}
          aadharFileType={aadharFileType}
          setAadharFileType={setAadharFileType}
          aadharHolding={aadharHolding}
          setAadharHolding={setAadharHolding}
          errorForAadharHold={errorForAadharHold}
          setErrorForAadharHold={setErrorForAadharHold}
          selectedAadharFileName={selectedAadharFileName}
          setSelectedAadharFileName={setSelectedAadharFileName}
          selectedPancardFileName={selectedPancardFileName}
          setSelectedPancardFileName={setSelectedPancardFileName}
          aadharBack={aadharBack}
          setAadharBack={setAadharBack}
          setErrorForAadharBack={setErrorForAadharBack}
          aadharBackFileType={aadharBackFileType}
          setAadharBackFileType={setAadharBackFileType}
          selectedAadharBackFileName={selectedAadharBackFileName}
          setSelectedAadharBackFileName={setSelectedAadharBackFileName}
          aadharNumber={aadharNumber}
          pancardNumber={pancardNumber}
        />
        {reSubmitKyc === "Rejected" && (
          <div className={styles.reSubmitKycButtonContainer}>
            {" "}
            <ButtonRade
              onClick={handleOnClickResubmitButton}
              customStyling={cx(styles.reSubmitKycButton, {
                [styles.reSubmitKycButtonMob]: isMobile,
              })}
            >
              {PROFILE_ENUM.submit}
            </ButtonRade>
          </div>
        )}
      </div>
      <Modal
        isOpen={confirmUpdateModalOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ConfirmUpdateProfile
          confirmUpdateModalOpen={confirmUpdateModalOpen}
          setConfirmUpdateModalOpen={setConfirmUpdateModalOpen}
          setKYCPageNumber={setKYCPageNumber}
          handlesubmitUpdateProfile={handlesubmitUpdateProfile}
        />
      </Modal>
    </>
  );
}

export default KYCCompleted;
