import React from "react";
import styles from "./index.module.scss";
const GoogleLoginCard = ({ titleComponent, children }) => {
  return (
    <div className={styles.cardMainDiv}>
      <div className={styles.titleContainer}>{titleComponent}</div>
      <div className={styles.bodyContainer}>{children}</div>
    </div>
  );
};

export default GoogleLoginCard;
