import React, { useContext } from "react";
import styles from "./index.module.scss";
import { SEND_ENUM } from "../../enums/sendEnum";
import USDLogo from "../../assets/maticLogo.svg";
import ButtonRade from "../../components/RadeButtons";
import NextIcon from "../../assets/NextIcon";
import CrossIcon from "../../assets/crossIcon";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context";
import cx from "classnames";
const InrtToUsdtTransactionSuccessful = ({
  setModalIsOpen,
  amountInINRT,
  amountInUSDT,
}) => {
  const { isMobile, account } = useContext(AppContext);
  let navigate = useNavigate();
  const closeTransactionsuccessfulWindow = () => {
    setModalIsOpen(false);
    navigate("/purchases"); //return back to purchase history component
  };
  return (
    <div className={styles.transactionSuccessfulMainDiv}>
      <div className={styles.transactionSuccessfulContent}>
        <div
          className={cx(styles.transactionSuccessful, {
            [styles.transactionSuccessfulMob]: isMobile,
          })}
        >
          {SEND_ENUM.transactionSuccesfull}
          <div className={styles.button}>
            <button
              className={cx(styles.crossButton, {
                [styles.crossButtonMob]: isMobile,
              })}
              onClick={closeTransactionsuccessfulWindow}
            >
              <CrossIcon />
            </button>
          </div>
        </div>
      </div>
      <div
        className={cx(styles.INDRsDiv, {
          [styles.INDRsDivMob]: isMobile,
        })}
      >
        <div className={styles.firstFlex}>
          <div
            className={cx(styles.INDRs, {
              [styles.INDRsMob]: isMobile,
            })}
          >
            {amountInINRT} INRT
          </div>
        </div>
        <div
          className={cx(styles.dateAndTime, {
            [styles.dateAndTimeMob]: isMobile,
          })}
        >
          25 May 23, 12:50 pm
        </div>
      </div>
      <div
        className={cx(styles.USDTDiv, {
          [styles.USDTDivMob]: isMobile,
        })}
      >
        <div className={styles.firstFlex}>
          <img src={USDLogo} alt="IND-logo" />
          <div
            className={cx(styles.USDT, {
              [styles.USDTMob]: isMobile,
            })}
          >
            {amountInUSDT} USDT
          </div>
        </div>
        <div className={styles.successButtonDiv}>
          <ButtonRade
            customStyling={cx(styles.successButton, {
              [styles.successButtonMob]: isMobile,
            })}
          >
            {SEND_ENUM.success}
          </ButtonRade>
        </div>
      </div>
      <div
        className={cx(styles.transactionDetailsDiv, {
          [styles.transactionDetailsDivMob]: isMobile,
        })}
      >
        <div className={styles.chainDetails}>
          <div
            className={cx(styles.key, {
              [styles.keyMob]: isMobile,
            })}
          >
            {SEND_ENUM.chain}
          </div>
          <div
            className={cx(styles.value, {
              [styles.valueMob]: isMobile,
            })}
          >
            MATIC20
          </div>
        </div>
        <div className={styles.orderIdDetails}>
          <div
            className={cx(styles.key, {
              [styles.keyMob]: isMobile,
            })}
          >
            {SEND_ENUM.orderId}
          </div>
          <div
            className={cx(styles.value, {
              [styles.valueMob]: isMobile,
            })}
          >
            127733
          </div>
        </div>
      </div>
      <div
        className={cx(styles.accountDetails, {
          [styles.accountDetailsMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.destination, {
            [styles.destinationMob]: isMobile,
          })}
        >
          {SEND_ENUM.destination}
        </div>
        <div
          className={cx(styles.address, {
            [styles.addressMob]: isMobile,
          })}
        >
          {account}
        </div>
      </div>
      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.explorerButton, {
            [styles.explorerButtonMob]: isMobile,
          })}
        >
          {SEND_ENUM.explorerButton}
          <NextIcon />
        </ButtonRade>
      </div>
      <div className={styles.walletCheck}>{SEND_ENUM.walletCheck}</div>
    </div>
  );
};

export default InrtToUsdtTransactionSuccessful;
