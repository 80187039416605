export const SUPPORT_ENUM = {
  supportTitle: "Help & Support",
  supportTicket: "Create a support ticket",
  exploreDocs: "Explore Docs",
  issueType: "Issue type",
  enterOrderId: "Enter your Order ID",
  message: "Message",
  create: "Create",
  notRobot: "I’m not a robot",
  myTickets: "My tickets",
  ticket: "ticket #",
  type: "type",
  ticketStatus: "status",
  support: "Support",
  reply: "Reply",
  supportNote: "For any other queries, please write to us at ",
  issueFacing: "Explain briefly the issue that you are facing.",
};
