import React from "react";
import styles from "./index.module.scss";
import ButtonRade from "../RadeButtons";
import { SEND_ENUM } from "../../enums/sendEnum";
import { useNavigate } from "react-router-dom";
import { signOutHandler } from "../../firebase";
import { useContext } from "react";
import { AppContext } from "../../context";
import cx from "classnames";
const GoogleLogout = ({
  setModalIsOpen,
  setProfile,
  setProfileEmail,
  setAmountInINR,
  setAmountInUSD,
}) => {
  const { setBuyUSDTFlowPageNumber, isMobile } = useContext(AppContext);
  let navigate = useNavigate();
  const LogOut = async () => {
    setModalIsOpen(false);
    await signOutHandler();
    navigate("/buy"); //return back to homescreen
    setBuyUSDTFlowPageNumber(0);
    setProfile(null);
    setProfileEmail(false);
    setAmountInINR(null);
    setAmountInUSD(null);
  };

  //Function to close the Modal.
  const handleOnClickCancel = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <div className={styles.popUpContent}>{SEND_ENUM.logoutMessage}</div>
      <div className={styles.popUpButtonDiv}>
        <ButtonRade
          outline
          customStyling={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={handleOnClickCancel}
        >
          {SEND_ENUM.cancel}
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={cx(styles.continueButton, {
            [styles.continueButtonMob]: isMobile,
          })}
          onClick={LogOut}
        >
          {SEND_ENUM.logout}
        </ButtonRade>
      </div>
    </div>
  );
};

export default GoogleLogout;
