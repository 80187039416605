import React from "react";
import App from "./App";
import "typeface-metrophobic";
import "typeface-roboto";
import "@fontsource/zen-dots";
import "./index.css";
import { DAppProvider } from "@usedapp/core";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
const config = {};
const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </BrowserRouter>
);
