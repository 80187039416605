import React, { useContext, useRef, useState } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import FileUpload from "../../components/FileUpload/FileUpload";
import { PROFILE_ENUM } from "../../enums/profileEnum";
import styles from "./index.module.scss";
import LoadingSpinner from "./LoadingSpinner";
import KycUpload from "../../components/KycUpload/KycUpload";
import AttachFile from "../Feedback/AttachFile";

// Custom hook to focus input on div click
const useFocusOnClick = () => {
  const inputRef = useRef(null);

  const handleDivClick = () => {
    inputRef.current.focus();
  };

  return {
    inputRef,
    handleDivClick,
  };
};

const ProfileDetailsFetch = ({
  firstName,
  lastName,
  phoneNumber,
  email,
  handleChangeInputFieldFirstName,
  handleChangeInputFieldLastName,
  handlePhoneNumberChange,
  setErrorForID,
  handleChangeInputFieldEmail,
  setErrorForPancard,
  setErrorForAadhar,
  reSubmitKyc,
  panCard,
  setPanCard,
  aadharCard,
  setAadharCard,
  setResubmitKyc,
  isFileUploaded,
  setIsFileUploaded,
  pancardFileType,
  setPancardFileType,
  aadharFileType,
  setAadharFileType,
  aadharHolding,
  setAadharHolding,
  setErrorForAadharHold,
  selectedAadharFileName,
  setSelectedAadharFileName,
  selectedPancardFileName,
  setSelectedPancardFileName,
  aadharBack,
  setAadharBack,
  setErrorForAadharBack,
  aadharBackFileType,
  setAadharBackFileType,
  selectedAadharBackFileName,
  setSelectedAadharBackFileName,
  aadharNumber,
  pancardNumber,
  handleAadharNumberChange,
  handlePancardNumberChange,
}) => {
  const { isMobile } = useContext(AppContext);
  const [isLoadingPancard, setIsLoadingPancard] = useState(false);
  const [isLoadingAadhar, setIsLoadingAadhar] = useState(false);
  const [isLoadingAadharBack, setIsLoadingAadharBack] = useState(false);
  const [isLoadingAadharHold, setIsLoadingAadharHold] = useState(false);
  const { inputRef: inputRef1, handleDivClick: handleDivClick1 } =
    useFocusOnClick();
  const { inputRef: inputRef2, handleDivClick: handleDivClick2 } =
    useFocusOnClick();
  const { inputRef: inputRef3, handleDivClick: handleDivClick3 } =
    useFocusOnClick();
  const { inputRef: inputRef4, handleDivClick: handleDivClick4 } =
    useFocusOnClick();
  const { inputRef: inputRef5, handleDivClick: handleDivClick5 } =
    useFocusOnClick();
  const { inputRef: inputRef6, handleDivClick: handleDivClick6 } =
    useFocusOnClick();

  return (
    <div>
      <div
        className={cx(styles.profileNameEmailContainer, {
          [styles.profileNameEmailContainerWeb]: !isMobile,
          [styles.profileNameEmailContainerMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.firstFlex, {
            [styles.firstFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.profileName, {
              [styles.profileNameWeb]: !isMobile,
              [styles.profileNameMob]: isMobile,
            })}
            onClick={handleDivClick1}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              First Name *
            </p>
            <input
              ref={inputRef1}
              key="name"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "Pending" || reSubmitKyc === "Verified",
              })}
              placeholder="Enter your first name (as per your ID)"
              onChange={handleChangeInputFieldFirstName}
              value={firstName}
              disabled={reSubmitKyc === "Pending" || reSubmitKyc === "Verified"}
            />
          </div>
          <div
            className={cx(styles.lastName, {
              [styles.lastNameWeb]: !isMobile,
              [styles.lastNameMob]: isMobile,
            })}
            onClick={handleDivClick2}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Last Name *
            </p>
            <input
              ref={inputRef2}
              key="name"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "Pending" || reSubmitKyc === "Verified",
              })}
              placeholder="Enter your last name (as per your ID)"
              onChange={handleChangeInputFieldLastName}
              value={lastName}
              disabled={reSubmitKyc === "Pending" || reSubmitKyc === "Verified"}
            />
          </div>
        </div>
        <div
          className={cx(styles.secondFlex, {
            [styles.secondFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
            onClick={handleDivClick3}
          >
            <p
              className={cx(styles.emailContainer, {
                [styles.emailContainerWeb]: !isMobile,
                [styles.emailContainerMob]: isMobile,
              })}
            >
              Email *
            </p>
            <input
              ref={inputRef3}
              key="email"
              type="email"
              className={cx(styles.inputEmail, {
                [styles.inputEmailWeb]: !isMobile,
                [styles.inputEmailMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "Pending" || reSubmitKyc === "Verified",
              })}
              placeholder="Enter your email"
              onChange={handleChangeInputFieldEmail}
              value={email}
              disabled
            />
          </div>
          <div
            className={cx(styles.phoneNumber, {
              [styles.phoneNumberWeb]: !isMobile,
              [styles.phoneNumberMob]: isMobile,
            })}
            onClick={handleDivClick4}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Phone *
            </p>
            <input
              ref={inputRef4}
              key="name"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "Pending" || reSubmitKyc === "Verified",
              })}
              placeholder="Enter your phone number"
              onChange={handlePhoneNumberChange}
              value={phoneNumber}
              disabled={reSubmitKyc === "Pending" || reSubmitKyc === "Verified"}
            />
          </div>
        </div>
        <div
          className={cx(styles.secondFlex, {
            [styles.secondFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
            onClick={handleDivClick5}
          >
            <p
              className={cx(styles.emailContainer, {
                [styles.emailContainerWeb]: !isMobile,
                [styles.emailContainerMob]: isMobile,
              })}
            >
              Pancard Number *
            </p>
            <input
              ref={inputRef5}
              key="pancardNumber"
              type="text"
              className={cx(styles.inputEmail, {
                [styles.inputEmailWeb]: !isMobile,
                [styles.inputEmailMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "Pending" || reSubmitKyc === "Verified",
              })}
              placeholder="Enter your pancard number"
              onChange={handlePancardNumberChange}
              value={pancardNumber}
              disabled={reSubmitKyc === "Pending" || reSubmitKyc === "Verified"}
            />
          </div>
          <div
            className={cx(styles.phoneNumber, {
              [styles.phoneNumberWeb]: !isMobile,
              [styles.phoneNumberMob]: isMobile,
            })}
            onClick={handleDivClick6}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Aadhaar Number *
            </p>
            <input
              ref={inputRef6}
              key="aadharNumber"
              type="number"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "Pending" || reSubmitKyc === "Verified",
              })}
              placeholder="Enter your aadhaar number"
              onChange={handleAadharNumberChange}
              value={aadharNumber}
              disabled={reSubmitKyc === "Pending" || reSubmitKyc === "Verified"}
            />
          </div>
        </div>
      </div>
      <div
        className={cx(styles.supportedFiles, {
          [styles.supportedFilesMob]: isMobile,
        })}
      >
        {PROFILE_ENUM.supportedFiles}
      </div>

      <div
        className={cx(styles.idUploadContainer, {
          [styles.idUploadContainerMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.pancardIdUpload, {
            [styles.pancardIdUploadMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.idAndName, {
              [styles.idAndNameMob]: isMobile,
            })}
          >
            <div className={styles.loadingContainer}>
              <div
                className={cx(styles.idUpload, {
                  [styles.idUploadMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.aadharFront} *
              </div>
              {isLoadingAadhar && (
                <div className={styles.loading}>
                  {PROFILE_ENUM.loading}
                  <div className={styles.spinner}>
                    <LoadingSpinner />
                  </div>
                </div>
              )}
            </div>

            {!isMobile && (
              <div
                className={cx(styles.pancard, {
                  [styles.pancardMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.idUpload}
              </div>
            )}
          </div>
          <div
            className={cx(styles.fileAttachDiv, {
              [styles.fileAttachDivMob]: isMobile,
            })}
          >
            <FileUpload
              id="aadharFront"
              setErrorForID={setErrorForID}
              aadharCard={aadharCard}
              setAadharCard={setAadharCard}
              setErrorForAadhar={setErrorForAadhar}
              panCard={panCard}
              setPanCard={setPanCard}
              reSubmitKyc={reSubmitKyc}
              setResubmitKyc={setResubmitKyc}
              setIsFileUploaded={setIsFileUploaded}
              pancardFileType={pancardFileType}
              setPancardFileType={setPancardFileType}
              aadharFileType={aadharFileType}
              setAadharFileType={setAadharFileType}
              setIsLoadingAadhar={setIsLoadingAadhar}
              setIsLoadingPancard={setIsLoadingPancard}
              selectedAadharFileName={selectedAadharFileName}
              setSelectedAadharFileName={setSelectedAadharFileName}
              selectedPancardFileName={selectedPancardFileName}
              setSelectedPancardFileName={setSelectedPancardFileName}
              aadharBack={aadharBack}
              setAadharBack={setAadharBack}
              setErrorForAadharBack={setErrorForAadharBack}
              aadharBackFileType={aadharBackFileType}
              setAadharBackFileType={setAadharBackFileType}
              selectedAadharBackFileName={selectedAadharBackFileName}
              setSelectedAadharBackFileName={setSelectedAadharBackFileName}
              setIsLoadingAadharBack={setIsLoadingAadharBack}
            />
          </div>
        </div>
        <div
          className={cx(styles.aadharIdUpload, {
            [styles.aadharIdUploadMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.idAndName, {
              [styles.idAndNameMob]: isMobile,
            })}
          >
            <div className={styles.loadingContainer}>
              <div
                className={cx(styles.idUpload, {
                  [styles.idUploadMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.aadharBack} *
              </div>
              {isLoadingAadharBack && (
                <div className={styles.loading}>
                  {PROFILE_ENUM.loading}
                  <div className={styles.spinner}>
                    <LoadingSpinner />
                  </div>
                </div>
              )}
            </div>

            {!isMobile && (
              <div
                className={cx(styles.aadhar, {
                  [styles.aadharMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.idUpload}
              </div>
            )}
          </div>
          <div
            className={cx(styles.fileAttachDiv, {
              [styles.fileAttachDivMob]: isMobile,
            })}
          >
            <FileUpload
              id="aadharBack"
              setErrorForID={setErrorForID}
              aadharCard={aadharCard}
              setAadharCard={setAadharCard}
              setErrorForAadhar={setErrorForAadhar}
              panCard={panCard}
              setPanCard={setPanCard}
              reSubmitKyc={reSubmitKyc}
              setResubmitKyc={setResubmitKyc}
              setIsFileUploaded={setIsFileUploaded}
              pancardFileType={pancardFileType}
              setPancardFileType={setPancardFileType}
              aadharFileType={aadharFileType}
              setAadharFileType={setAadharFileType}
              setIsLoadingAadhar={setIsLoadingAadhar}
              setIsLoadingPancard={setIsLoadingPancard}
              selectedAadharFileName={selectedAadharFileName}
              setSelectedAadharFileName={setSelectedAadharFileName}
              selectedPancardFileName={selectedPancardFileName}
              setSelectedPancardFileName={setSelectedPancardFileName}
              aadharBack={aadharBack}
              setAadharBack={setAadharBack}
              setErrorForAadharBack={setErrorForAadharBack}
              aadharBackFileType={aadharBackFileType}
              setAadharBackFileType={setAadharBackFileType}
              selectedAadharBackFileName={selectedAadharBackFileName}
              setSelectedAadharBackFileName={setSelectedAadharBackFileName}
              setIsLoadingAadharBack={setIsLoadingAadharBack}
            />
          </div>
        </div>
      </div>
      <div
        className={cx(styles.aadharHoldContainer, {
          [styles.aadharHoldContainerMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.pancardIdUpload, {
            [styles.pancardIdUploadMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.idAndName, {
              [styles.idAndNameMob]: isMobile,
            })}
          >
            <div className={styles.loadingContainer}>
              <div
                className={cx(styles.idUpload, {
                  [styles.idUploadMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.pancard} *
              </div>
              {isLoadingPancard && (
                <div className={styles.loading}>
                  {PROFILE_ENUM.loading}
                  <div className={styles.spinner}>
                    <LoadingSpinner />
                  </div>
                </div>
              )}
            </div>

            {!isMobile && (
              <div
                className={cx(styles.aadhar, {
                  [styles.aadharMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.idUpload}
              </div>
            )}
          </div>
          <div
            className={cx(styles.fileAttachDiv, {
              [styles.fileAttachDivMob]: isMobile,
            })}
          >
            <FileUpload
              id="pancard"
              setErrorForID={setErrorForID}
              panCard={panCard}
              setPanCard={setPanCard}
              setErrorForPancard={setErrorForPancard}
              aadharCard={aadharCard}
              setAadharCard={setAadharCard}
              reSubmitKyc={reSubmitKyc}
              setResubmitKyc={setResubmitKyc}
              setIsFileUploaded={setIsFileUploaded}
              pancardFileType={pancardFileType}
              setPancardFileType={setPancardFileType}
              aadharFileType={aadharFileType}
              setAadharFileType={setAadharFileType}
              setIsLoadingPancard={setIsLoadingPancard}
              setIsLoadingAadhar={setIsLoadingAadhar}
              selectedAadharFileName={selectedAadharFileName}
              setSelectedAadharFileName={setSelectedAadharFileName}
              selectedPancardFileName={selectedPancardFileName}
              setSelectedPancardFileName={setSelectedPancardFileName}
              aadharBack={aadharBack}
              setAadharBack={setAadharBack}
              setErrorForAadharBack={setErrorForAadharBack}
              aadharBackFileType={aadharBackFileType}
              setAadharBackFileType={setAadharBackFileType}
              selectedAadharBackFileName={selectedAadharBackFileName}
              setSelectedAadharBackFileName={setSelectedAadharBackFileName}
              setIsLoadingAadharBack={setIsLoadingAadharBack}
            />
          </div>
        </div>
        <div
          className={cx(styles.aadharIdUpload, {
            [styles.aadharIdUploadMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.idAndName, {
              [styles.idAndNameMob]: isMobile,
            })}
          >
            <div className={styles.loadingContainer}>
              <div
                className={cx(styles.idUpload, {
                  [styles.idUploadMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.aadharHolding} *
              </div>
              {isLoadingAadharHold && (
                <div className={styles.loading}>
                  {PROFILE_ENUM.loading}
                  <div className={styles.spinner}>
                    <LoadingSpinner />
                  </div>
                </div>
              )}
            </div>

            {!isMobile && (
              <div
                className={cx(styles.aadhar, {
                  [styles.aadharMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.idHolding}
              </div>
            )}
          </div>
          <div
            className={cx(styles.fileAttachDiv, {
              [styles.fileAttachDivMob]: isMobile,
            })}
          >
            <KycUpload
              id="holdAadhar"
              aadharHolding={aadharHolding}
              setAadharHolding={setAadharHolding}
              setErrorForAadharHold={setErrorForAadharHold}
              reSubmitKyc={reSubmitKyc}
              setIsFileUploaded={setIsFileUploaded}
              setIsLoadingAadharHold={setIsLoadingAadharHold}
            />
          </div>
        </div>
      </div>
      {isFileUploaded &&
        reSubmitKyc !== "Pending" &&
        reSubmitKyc !== "Verified" && (
          <div className={styles.changeFile}>{PROFILE_ENUM.changeFile}</div>
        )}
      <div
        className={cx(styles.holdAadharContent, {
          [styles.holdAadharContentMob]: isMobile,
          [styles.holdAadharContentAfterFileUploaded]: isFileUploaded,
        })}
      >
        Upload photo of you holding aadhar with clearly legible writing on the
        ID card (PNG or JPEG).
      </div>
    </div>
  );
};

export default ProfileDetailsFetch;
