import React, { useState, useContext, useEffect } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import styles from "./index.module.scss";
import { SEND_ENUM } from "../../enums/sendEnum";
import Card from "../../components/Card/Card";
import ButtonRade from "../../components/RadeButtons";
import Modal from "react-modal";
import PaymentVerificationModal from "../PaymentVerificationPopup/PaymentVerificationPopup";
import TransactionProgressModal from "../TransactionProgressModal/TransactionProgressModal";
import InrtToUsdtTransactionSuccessful from "../UsdtUsingInrtTransactionSuccessfulModal/UsdtUsingInrtTransactionSuccessfulModal";

const MAX_DECIMAL_PLACE = 2;
const SwapInrtToUsdt = () => {
  const { isMobile, setBuyUSDTFlowPageNumber } = useContext(AppContext);
  //state to set USDT to INR conversion
  const [USDConvertionRate, setUSDConvertionRate] = useState([]);
  const [amountInUSDT, setAmountInUSDT] = useState(null); // state for amount in USDT
  const [amountInINRT, setAmountInINRT] = useState(null); // state for amount in INRT

  //state to set the modal to open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //state to set the page number for upcoming modal
  const [modalPageNumber, setModalPageNumber] = useState(null);
  // state to set error before proceed
  const [errorForProceed, setErrorForProceed] = useState("");
  // price fetching section
  useEffect(() => {
    // Create a stripe connection through the backend as soon as you reach this page
    fetch(process.env.REACT_APP_TETHERX_SERVER_URI + "/get-usdtinr-rate").then(
      async (res) => {
        const { UsdToInr } = await res.json();
        setUSDConvertionRate(UsdToInr);
      }
    );
  }, []);
  useEffect(() => {
    // Function to convert the currency
    const convert = () => {
      if (USDConvertionRate) {
        setAmountInINRT(amountInUSDT * USDConvertionRate);
      }
    };
    convert();
  }, [amountInUSDT, USDConvertionRate, setAmountInINRT]);
  // handle input field to enter the USDT that user wish to buy
  const handleOnEnterInput = (e) => {
    setAmountInUSDT(e.target.value);
  };
  // handle proceed button on click
  const handleClickProceedButton = () => {
    if (!amountInUSDT) {
      setErrorForProceed("Please enter an amount of USDT you wish to buy!");
    } else if (amountInUSDT === "0") {
      setErrorForProceed("Please enter a valid amount of USDT!");
    } else {
      setErrorForProceed("");
      setModalIsOpen(true);
      setModalPageNumber(0);
    }
  };
  const handleClickBackButton = () => {
    setBuyUSDTFlowPageNumber(1);
  };
  const CardTitle = () => (
    <div
      className={cx(styles.purchaseInrtTitle, {
        [styles.purchaseInrtTitleMob]: isMobile,
      })}
    >
      {SEND_ENUM.purchaseUSDT}
    </div>
  );
  const CardBody = () => (
    <div
      className={cx(styles.purchaseInrtMainContainer, {
        [styles.purchaseInrtMainContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.purchaseContent, {
          [styles.purchaseContentMob]: isMobile,
        })}
      >
        {SEND_ENUM.purchaseUsdtContent}
      </div>
      <div
        className={cx(styles.payUSD, {
          [styles.payUSDMob]: isMobile,
        })}
      >
        <div className={styles.payCryptoDiv}>
          <div
            className={cx(styles.payingUSD, {
              [styles.payingUSDMob]: isMobile,
            })}
          >
            {SEND_ENUM.get}
          </div>
        </div>
        <div
          className={cx(styles.payingUSDValue, {
            [styles.payingUSDValueMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.dollarSymbol, {
              [styles.dollarSymbolMob]: isMobile,
            })}
          >
            $
          </div>
          <input
            type="number"
            value={amountInUSDT}
            onChange={handleOnEnterInput}
            placeholder="0.00"
            min="0.00"
            step="0.01"
            max="1.00"
            presicion={2}
            autoFocus
            autoComplete="off"
            className={cx(styles.inputAmount, {
              [styles.inputAmountMob]: isMobile,
            })}
          ></input>
          <div
            className={cx(styles.payingCrypto, {
              [styles.payingCryptoMob]: isMobile,
            })}
          >
            USDT
          </div>
        </div>
      </div>
      <div
        className={cx(styles.getRUD, {
          [styles.getRUDMob]: isMobile,
        })}
      >
        <div className={styles.recievingRUD}>{SEND_ENUM.pay}</div>
        <div
          className={cx(styles.recievingRUDVal, {
            [styles.recievingRUDValMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.equivalentRUD, {
              [styles.equivalentRUDMob]: isMobile,
            })}
          >
            {Number(amountInINRT).toFixed(MAX_DECIMAL_PLACE)}
          </div>
          <div
            className={cx(styles.cryptoRUD, {
              [styles.cryptoRUDMob]: isMobile,
            })}
          >
            INRT
          </div>
        </div>
      </div>
      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.backButton, {
            [styles.backButtonMob]: isMobile,
          })}
          onClick={handleClickBackButton}
        >
          {SEND_ENUM.back}
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={cx(styles.proceedButton, {
            [styles.proceedButtonMob]: isMobile,
          })}
          onClick={handleClickProceedButton}
        >
          {SEND_ENUM.proceedButton}
        </ButtonRade>
      </div>
      {errorForProceed && (
        <div className={styles.errorMessage}>{errorForProceed}</div>
      )}
    </div>
  );
  return (
    <>
      <Card titleComponent={<CardTitle />}>{CardBody()}</Card>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {(() => {
          switch (modalPageNumber) {
            case 0:
              return (
                <PaymentVerificationModal
                  setModalPageNumber={setModalPageNumber}
                />
              );
            case 1:
              return (
                <TransactionProgressModal
                  setModalPageNumber={setModalPageNumber}
                />
              );
            case 2:
              return (
                <InrtToUsdtTransactionSuccessful
                  setModalIsOpen={setModalIsOpen}
                  amountInINRT={amountInINRT}
                  amountInUSDT={amountInUSDT}
                />
              );
            default:
              return null;
          }
        })()}
      </Modal>
    </>
  );
};
export default SwapInrtToUsdt;
