import React from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import { useContext, useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import { SEND_ENUM } from "../../enums/sendEnum";
import RadeButton from "../../components/RadeButtons";
import styles from "./index.module.scss";
import NextIcon from "../../assets/NextIcon";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import { getDoc, doc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import Modal from "react-modal";
import CompleteKycModal from "../CompleteKycModal/CompleteKycModal";
// import PaymentSection from "../PaymentSection/PaymentSection";
import { auth } from "../../firebase";
import EnterWalletAddress from "../EnterWalletAddress/EnterWalletAddress";
import SwapInrtToUsdt from "../SwapInrtToUsdt/SwapInrtToUsdt";

const MAX_DECIMAL_PLACE = 2;
const MIN_ORDER_AMT = 10;
const MAX_ORDER_AMT = 2000;
const ONE_PERCENT = 0.01;
const TETHERX_PLATFORM_FEES = 0;
const BuyUSDTusingINR = ({
  profileEmail,
  setAmountInUsdt,
  amountInUsdt,
  amountInINR,
  setAmountInINR,
  setGoogleLoginFlowPageNumber,
  setOnClickLoginButton,
}) => {
  // take the required values as props
  // import the required values from the context
  const {
    isMobile,
    buyUSDTFlowPageNumber,
    setBuyUSDTFlowPageNumber,
    isAnimationRequired,
    setIsAnimationRequired,
    isNavBarClicked,
    setIsNavbarClicked,
  } = useContext(AppContext);

  //state to set error message for login or proceed
  const [errorForLogin, setErrorForLogin] = useState(false);
  // state for opening the modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //state to set USDT to INR conversion
  const [USDConvertionRate, setUSDConvertionRate] = useState([]);
  //state to set is price fetching or not
  const [fetchingPrice, setFetchingPrice] = useState(null);
  //state to store the treasury balance
  const [treasuryBalance, setTreasuryBalance] = useState("");

  // price fetching section
  useEffect(() => {
    // Create a stripe connection through the backend as soon as you reach this page

    const getUsdtInrRate = async () => {
      try {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await fetch(
          process.env.REACT_APP_TETHERX_SERVER_URI + "/get-usdtinr-rate",
          requestOptions
        );
        if (response.ok) {
          // Request was successful
          const { UsdtToInr } = await response.json();
          setUSDConvertionRate(UsdtToInr);
        } else {
          // Handle error response
          const errorData = await response.json();
          // Handle the error data
          console.log("errorData, usdt-inrt rate :", errorData);
        }
      } catch (error) {
        console.error("Error fetching USDT to INR rate:", error);
      }
    };

    getUsdtInrRate();
  }, []);

  // treasury balance fetching
  useEffect(() => {
    const getUsdtTreasuryBalance = async () => {
      try {
        // Obtain ID token
        const idToken = await auth.currentUser.getIdToken(
          /* forceRefresh */ true
        );
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
          },
        };
        const res = await fetch(
          process.env.REACT_APP_TETHERX_SERVER_URI +
            "/get-usdt-treasury-balance",
          requestOptions
        );
        if (res.ok) {
          // Request was successful
          const { balanceAmount, balanceDecimals } = await res.json();
          setTreasuryBalance(balanceAmount / 10 ** balanceDecimals);
        } else {
          // Handle error response
          const errorData = await res.json();
          // Handle the error data
          console.log("errorData, usdt treasury balance :", errorData);
        }
      } catch (error) {
        console.error("Error fetching USDT treasury balance:", error);
      }
    };
    if (auth.currentUser) {
      getUsdtTreasuryBalance();
    } else {
      console.log("No authenticated user");
    }
  }, [auth.currentUser]);

  useEffect(() => {
    // Function to convert the currency
    const convert = () => {
      if (USDConvertionRate) {
        setAmountInINR(
          amountInUsdt * (USDConvertionRate + USDConvertionRate * ONE_PERCENT)
        );
      } else {
        setFetchingPrice(true);
      }
    };
    convert();
  }, [amountInUsdt, USDConvertionRate, setAmountInINR]);
  const tdsusdtRate = amountInUsdt * USDConvertionRate * ONE_PERCENT;
  // component unmounting
  useEffect(() => {
    return () => {
      console.log("Component unmounted");
      setBuyUSDTFlowPageNumber(null);
    };
  }, []);

  //switch component to render this and upcoming pages based on buyUSDTFlowPageNumber
  const renderComponents = () => {
    switch (buyUSDTFlowPageNumber) {
      //TODO: to be implemented later
      // case 1:
      //   return (
      //     <PaymentSection
      //       amountInINR={amountInINR}
      //       amountInUsdt={amountInUsdt}
      //     />
      //   );
      case 1:
        return <EnterWalletAddress />;
      case 2:
        return <SwapInrtToUsdt />;
      case 3:
        return (
          <PaymentMethod
            amountInINR={amountInINR}
            amountInUsdt={amountInUsdt}
          />
        );

      default:
        return (
          <>
            <Card titleComponent={<CardTitle />}> {CardBody()} </Card>
            {isMobile && (
              <div className={styles.footer}>
                <div
                  className={cx(styles.footerContent, {
                    [styles.footerFixed]: amountInUsdt,
                  })}
                >
                  <span className={styles.copyright}>tetherX &copy; 2023</span>
                  <div className={styles.allRightsReserved}>
                    All rights reserved.
                  </div>
                  <div className={styles.privacyAndTerms}>
                    <a
                      href="https://docs.tetherx.co.in/platform/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.privacyPolicy}
                    >
                      Privacy
                    </a>
                    <a
                      href="https://docs.tetherx.co.in/platform/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.termsOfUse}
                    >
                      Terms
                    </a>
                  </div>
                </div>
              </div>
            )}
          </>
        );
    }
  };
  // function to handle on clicking the proceed button
  const handleClickProceedButton = async () => {
    if (!profileEmail && amountInUsdt) {
      if (amountInUsdt < MIN_ORDER_AMT) {
        setErrorForLogin(
          `Only orders with a minimum of $ ${MIN_ORDER_AMT} will be processed.`
        );
      } else {
        setGoogleLoginFlowPageNumber(0);
        setOnClickLoginButton(true);
      }
    } else if (!amountInUsdt) {
      setErrorForLogin("Please enter a valid amount of USDT to continue");
    } else if (amountInUsdt < MIN_ORDER_AMT) {
      setErrorForLogin(
        `Only orders with a minimum of $ ${MIN_ORDER_AMT} will be processed.`
      );
    } else if (amountInUsdt > treasuryBalance) {
      setErrorForLogin(
        "Error : Server in maintenance. Please try again later."
      );
    } else if (amountInUsdt > MAX_ORDER_AMT) {
      setErrorForLogin(
        `Only orders of up to $ ${MAX_ORDER_AMT} will be processed.`
      );
    } else {
      // Query the document to retrieve KYCStatus
      const usersRef = collection(db, "userData");
      const userDocRef = doc(usersRef, profileEmail);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const kYCStatus = userData.KYCStatus;

        if (kYCStatus === "Verified") {
          setBuyUSDTFlowPageNumber(1);
        } else {
          setModalIsOpen(true);
        }
        setIsNavbarClicked(true);
      } else {
        setErrorForLogin("User data not found. Please try again later.");
      }
    }
  };

  // handle input field to enter the USDT that user wish to buy
  const handleOnEnterInput = (e) => {
    const enteredValue = e.target.value;
    const numericValue = enteredValue.replace(/\D/g, ""); // Remove non-digit characters
    const limitedValue = numericValue.slice(0, 6); // Limit to 6 digits
    setAmountInUsdt(limitedValue);
    setErrorForLogin(false);
  };

  const CardTitle = () => (
    <>
      <div
        className={cx(styles.enterAmountOfUSDWishToSend, {
          [styles.enterAmountOfUSDWishToSendMob]: isMobile,
        })}
      >
        {SEND_ENUM.enterAmountOfUSDWishToSend}
      </div>
    </>
  );
  const CardBody = () => (
    <div
      className={cx(styles.sendRUDMainContainer, {
        [styles.sendRUDMainContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.sendRUD, {
          [styles.sendRUDMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.sendRUDDiv, {
            [styles.sendRUDDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.sendingRUD, {
              [styles.sendingRUDMob]: isMobile,
            })}
          >
            {SEND_ENUM.youSend}
          </div>
        </div>
        {profileEmail && (
          <div className={styles.maxAllowed}>
            Maximum Allowed: $
            {treasuryBalance < MAX_ORDER_AMT
              ? Number(treasuryBalance).toFixed(MAX_DECIMAL_PLACE)
              : MAX_ORDER_AMT}
          </div>
        )}
        <div
          className={cx(styles.sendingRUDValue, {
            [styles.sendingRUDValueMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.dollarSymbol, {
              [styles.dollarSymbolMob]: isMobile,
            })}
          >
            $
          </div>
          <input
            value={amountInUsdt}
            onChange={handleOnEnterInput}
            onKeyDown={(e) => {
              if (e.key === "-" || e.key === "e") {
                e.preventDefault();
              }
            }}
            type="number"
            placeholder="00.00"
            autoFocus
            autoComplete="off"
            className={cx(styles.inputAmount, {
              [styles.inputAmountMob]: isMobile,
            })}
          ></input>

          <span
            className={cx(styles.USD, {
              [styles.USDMob]: isMobile,
            })}
          >
            USDT
          </span>
        </div>
      </div>
      <div>
        <div
          className={cx(styles.getINRContainer, {
            [styles.getINRContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.getINRAmount, {
              [styles.getINRAmountMob]: isMobile,
            })}
          >
            {SEND_ENUM.recipientGets}
          </div>

          <div
            className={cx(styles.getINRAmountValue, {
              [styles.getINRAmountValueMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.dollarSymbol, {
                [styles.dollarSymbolMob]: isMobile,
              })}
            >
              ₹
            </div>
            <input
              value={Number(amountInINR).toFixed(MAX_DECIMAL_PLACE)}
              onChange={handleOnEnterInput}
              type="number"
              placeholder="00.00"
              disabled
              autoComplete="off"
              className={cx(styles.inputAmount, {
                [styles.inputAmountMob]: isMobile,
              })}
            ></input>
            <div
              className={cx(styles.INR, {
                [styles.INRMob]: isMobile,
              })}
            >
              INR
            </div>
          </div>
        </div>
      </div>
      {/* Hidden section that appears only when user enter some USDT amount in the input filed */}
      {USDConvertionRate && amountInUsdt && (
        <>
          <div
            className={cx(styles.USDConvertionRate, {
              [styles.USDConvertionRateMob]: isMobile,
            })}
          >
            <div className={styles.priceBreakdown}>
              <div className={styles.bestPrice}>Amount Exchanged</div>
              <div className={styles.bestPriceValue}>
                {Number(amountInUsdt).toFixed(MAX_DECIMAL_PLACE)} USDT
              </div>
            </div>
            <div className={styles.priceBreakdown}>
              <div className={styles.bestPrice}>Exchange Rate (Best Price)</div>
              <div className={styles.bestPriceValue}>
                ₹{Number(USDConvertionRate).toFixed(MAX_DECIMAL_PLACE)}
              </div>
            </div>
            <div className={styles.priceBreakdown}>
              <div
                className={cx(styles.tdsValue, {
                  [styles.tdsValueMob]: isMobile,
                })}
              >
                Total Cost of Acquisition
              </div>
              <div className={styles.bestPriceValue}>
                ₹{(amountInUsdt * USDConvertionRate).toFixed(MAX_DECIMAL_PLACE)}
              </div>
            </div>
            <div className={styles.priceBreakdown}>
              <div
                className={cx(styles.tdsValue, {
                  [styles.tdsValueMob]: isMobile,
                })}
              >
                TDS @ 1%
              </div>
              <div className={styles.bestPriceValue}>
                ₹{tdsusdtRate.toFixed(MAX_DECIMAL_PLACE)}
              </div>
            </div>
            <div className={styles.priceBreakdown}>
              <div
                className={cx(styles.tdsValue, {
                  [styles.tdsValueMob]: isMobile,
                })}
              >
                TetherX Platform Fees
              </div>
              <div className={styles.bestPriceValue}>
                ₹{TETHERX_PLATFORM_FEES}
              </div>
            </div>
            <div className={styles.priceBreakdown}>
              <div
                className={cx(styles.tdsValue, {
                  [styles.tdsValueMob]: isMobile,
                })}
              >
                Total Payment Due
              </div>
              <div className={styles.bestPriceValue}>
                ₹{Number(amountInINR).toFixed(MAX_DECIMAL_PLACE)}
              </div>
            </div>
            <p
              className={cx(styles.tdsNote, {
                [styles.tdsNoteMob]: isMobile,
              })}
            >
              {SEND_ENUM.tdsNote}
            </p>
          </div>
        </>
      )}
      {/*error for login or proceed displaying section */}
      {errorForLogin && (
        <div
          className={cx(styles.errorMessageForLogin, {
            [styles.errorMessageForLoginMob]: isMobile,
          })}
        >
          {errorForLogin}
        </div>
      )}
      {/*spinner to show until the proce is fetched */}
      {fetchingPrice && !USDConvertionRate && amountInUsdt && (
        <>
          <div className={styles.spinnerOverlay}>
            <div className={styles.spinnerContainer} />
          </div>
        </>
      )}
      {/*custom styled proceed button */}
      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        <RadeButton
          onClick={handleClickProceedButton}
          customStyling={cx(styles.proceedButton, {
            [styles.proceedButtonMob]: isMobile,
          })}
          disabled={fetchingPrice}
        >
          {SEND_ENUM.proceedButton}
          <NextIcon />
        </RadeButton>
      </div>
    </div>
  );
  return (
    <>
      <div>{renderComponents()}</div>
      {/*Modal to show if the user is not KYC verified */}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <CompleteKycModal setModalIsOpen={setModalIsOpen} />
      </Modal>
    </>
  );
};

export default BuyUSDTusingINR;
