import React, { useContext, useState, useEffect } from "react";
import styles from "./index.module.scss";
import Card from "../../components/Card/Card";
import { SEND_ENUM } from "../../enums/sendEnum";
import googlePayLogo from "../../assets/googlePayLogo.svg";
import paytmLogo from "../../assets/paytmLogo.svg";
import phonePeLogo from "../../assets/phonePeLogo.svg";
import raritiQRcode from "../../assets/raritiQRcode.png";
import disclaimerLogo from "../../assets/DisclaimerLogo.svg";
import ButtonRade from "../../components/RadeButtons";
import NextIcon from "../../assets/NextIcon";
import { AppContext } from "../../context";
import PaymentVerificationModal from "../PaymentVerificationPopup/PaymentVerificationPopup";
import Modal from "react-modal";
// import TransactionProgressModal from "../TransactionProgressModal/TransactionProgressModal";
import TransactionSuccessfulModal from "../TransactionSuccessfulModal/TransactionSuccessfulModal";
import cx from "classnames";
import FormatTimer from "../../components/CountDownTimer/CountDownTimer";
import { auth } from "../../firebase";
import Loading from "./Loading";
import copyIcon from "../../assets/copyIconWhite.svg";
import tickIcon from "../../assets/tickIconWhite.svg";

const MAX_DECIMAL_PLACE = 2;
let interval = null;
const PaymentMethod = ({ amountInINR, amountInUsdt }) => {
  // import the required values from the context
  const {
    isMobile,
    setBuyUSDTFlowPageNumber,
    profileEmail,
    setSessionTxId,
    sessionTxId,
    recipientWalletAddressID,
  } = useContext(AppContext);
  // state for storing UPI transaction id
  const [upiTransactionID, setUpiTransactionId] = useState(null);
  // state to check if the checkbox is marked or not
  const [isChecked, setIsChecked] = useState(false);
  //state to set error message for checkbox
  const [errorForCheckbox, setErrorForCheckbox] = useState(false);
  //state to set error message for upi transaction id
  const [errorForTransactionId, setErrorForTransactionId] = useState(false);
  //state to set error message for invalid upi transaction id
  const [invalidTransactionId, setInvalidTransactionId] = useState(false);
  //state to set the modal to open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //state to set the page number for upcoming modal
  const [modalPageNumber, setModalPageNumber] = useState(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  // state to set payment for transaction details
  const [paymentTxDetails, setPaymentTxDetails] = useState("");
  //state to set loading until sessionTxid is set
  const [isLoading, setIsLoading] = useState(false);

  // Function to validate the phone number
  const handleOnChangeTransactionId = (e) => {
    setErrorForTransactionId(false);
    setInvalidTransactionId(false);
    const enteredValue = e.target.value;
    const numericValue = enteredValue.replace(/\D/g, ""); // Remove non-digit characters
    const limitedValue = numericValue.slice(0, 12); // Limit to 10 digits
    setUpiTransactionId(limitedValue);
  };
  // handle checkbox on change
  const handleCheckboxChange = () => {
    setErrorForCheckbox(false);
    setIsChecked(!isChecked);
  };
  // handle on click cancel button
  const handleClickCancelButton = () => {
    setBuyUSDTFlowPageNumber(1);
    localStorage.removeItem(localStorageKey);
  };

  // handle on click copy button
  const handleCopyClick = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        // Copying to clipboard succeeded
        console.log("Copied to clipboard:", value);
        setCopiedIndex(value);
        setTimeout(() => {
          setCopiedIndex(false); // Change back to copy icon after a delay
        }, 5000);
      })
      .catch((error) => {
        // Copying to clipboard failed
        console.error("Failed to copy to clipboard:", error);
      });
  };
  useEffect(() => {
    // This effect will run whenever isLoading changes
    const handleLoadingChange = async () => {
      console.log("isLoading updated", isLoading);

      // All good, proceed to next page
      if (isLoading !== true && sessionTxId) {
        setModalIsOpen(true);
        setModalPageNumber(0);
      } else {
        console.log("Couldn't finish loading UPI Module");
      }
    };

    handleLoadingChange(); // Call the handleLoadingChange function when the effect runs
  }, [isLoading, sessionTxId]);

  // handle on click I have paid button
  const handleClickPaidButton = async () => {
    if (!isChecked && !upiTransactionID) {
      setErrorForTransactionId(true);
    } else if (!upiTransactionID) {
      setErrorForTransactionId(true);
    } else if (upiTransactionID.length < 12) {
      setInvalidTransactionId(true);
    } else if (!isChecked) {
      setErrorForCheckbox(true);
    } else {
      // remove the item stored in local storage
      localStorage.removeItem(localStorageKey);

      // Create payment intent object to create sessionTxId from the server
      const paymentIntent = {
        amount_captured: amountInUsdt, // USDT Amount captured from frontend
        email: profileEmail, // User email
        receiver_id: recipientWalletAddressID, // Wallet address captured
      };
      // Obtain ID token
      const idToken = await auth.currentUser.getIdToken(
        /* forceRefresh */ true
      );
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
          // Add other headers if needed
        },
        body: JSON.stringify(paymentIntent),
      };
      // receiver_wallet_address string
      // usdt_amount integer
      // usdt_chain integer
      // usdt_decimal integer
      // console.log("Payment intent charges data: ", paymentIntent.charges.data);
      // paymentIntent.amount_captured;
      // paymentIntent.email;
      // paymentIntent.chain;
      // paymentIntent.decimals;
      // paymentIntent.sender_id;
      // paymentIntent.receiver_id;
      setIsLoading(true); // Set loading state
      try {
        const response = await fetch(
          process.env.REACT_APP_TETHERX_SERVER_URI + `/create-payment-intent`,
          requestOptions
        );
        if (response.ok) {
          // Request was successful
          const responseData = await response.json();
          // Handle the response data
          setSessionTxId(responseData.txId);
          if (responseData.txId) {
            setIsLoading(false);
          }
        } else {
          // Handle error response
          const errorData = await response.json();
          // Handle the error data
          console.log("errorData, payment intent:", errorData);
        }
      } catch (error) {
        // Handle network or fetch-related errors
        console.error("Error:", error);
      }
    }
  };

  ////// --- Start Timer implementation --- //////////

  const TIMER_DURATION = 5 * 60 * 1000; // minutes in milliseconds
  const localStorageKey = "timerExpiration";

  const [expirationTime, setExpirationTime] = useState(
    localStorage.getItem(localStorageKey) || null
  );
  const [timeRemaining, setTimeRemaining] = useState(
    expirationTime ? expirationTime - Date.now() : TIMER_DURATION
  );

  useEffect(() => {
    if (!expirationTime) {
      // Set the expiration time if it doesn't exist
      const expiration = Date.now() + TIMER_DURATION;
      localStorage.setItem(localStorageKey, expiration);
      setExpirationTime(expiration);
    } else if (timeRemaining <= 0) {
      // Do something when the time expires
      // Remove storage ket and sent to home/buy
      localStorage.removeItem(localStorageKey);
      setBuyUSDTFlowPageNumber(-1);
      // // If you want to reset the timer:
      // const expiration = Date.now() + TIMER_DURATION;
      // localStorage.setItem(localStorageKey, expiration);
      // setExpirationTime(expiration);
      // setTimeRemaining(TIMER_DURATION);
    }

    // Start the interval to update the timer
    interval = setInterval(() => {
      setTimeRemaining(expirationTime - Date.now());
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
      // clear the value in local storage
      if (timeRemaining <= 0) {
        localStorage.removeItem(localStorageKey);
      }
    };
  }, [expirationTime, timeRemaining]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem(localStorageKey);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  ////// --- End Timer implementation --- //////////

  const CardTitle = () => (
    <div
      className={cx(styles.CardTitle, {
        [styles.CardTitleMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.cardTitleContent, {
          [styles.cardTitleContentMob]: isMobile,
        })}
      >
        {SEND_ENUM.paymentMethodTitle}
      </div>
      <div
        className={cx(styles.paymentMethodsLogo, {
          [styles.paymentMethodsLogoMob]: isMobile,
        })}
      >
        <img src={googlePayLogo} alt="googlePay-logo" />
        <img src={paytmLogo} alt="googlePay-logo" />
        <img
          src={phonePeLogo}
          alt="googlePay-logo"
          className={styles.phonePeLogo}
        />
      </div>
    </div>
  );
  const CardBody = () => (
    <div
      className={cx(styles.paymentMethodMainDiv, {
        [styles.paymentMethodMainDivMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.qrCodeSection, {
          [styles.qrCodeSectionMob]: isMobile,
        })}
      >
        {isMobile && (
          <div className={styles.timerSectionMob}>
            <div className={styles.timerMob}>
              <FormatTimer ms={timeRemaining} />
            </div>
            <div className={styles.remainingTimeKeyMob}>
              {SEND_ENUM.remainingTime}
            </div>
          </div>
        )}
        <img
          src={raritiQRcode}
          alt="rarity-QRcode"
          className={cx(styles.raritiQRcode, {
            [styles.raritiQRcodeMob]: isMobile,
          })}
        />
        <div
          className={cx(styles.scanQRContent, {
            [styles.scanQRContentMob]: isMobile,
          })}
        >
          {SEND_ENUM.scanQR}
        </div>
        <div
          className={cx(styles.scanQRContent, {
            [styles.scanQRContentMob]: isMobile,
          })}
        >
          ₹{Number(amountInINR).toFixed(MAX_DECIMAL_PLACE)}
        </div>
        {!isMobile && (
          <div className={styles.timerSection}>
            <div className={styles.timer}>
              <FormatTimer ms={timeRemaining} />
            </div>
            <div className={styles.remainingTimeKey}>
              {SEND_ENUM.remainingTime}
            </div>
          </div>
        )}
      </div>
      <div
        className={cx(styles.upiTransactionSection, {
          [styles.upiTransactionSectionMob]: isMobile,
        })}
      >
        {!isMobile && (
          <div
            className={cx(styles.payUPI, {
              [styles.payUPIMob]: isMobile,
            })}
          >
            {SEND_ENUM.payUPI}
          </div>
        )}
        <div
          className={cx(styles.sendViaUPI, {
            [styles.sendViaUPIMob]: isMobile,
          })}
        >
          Send ₹{Number(amountInINR).toFixed(MAX_DECIMAL_PLACE)} to:
        </div>
        <div
          className={cx(styles.upiIdDiv, {
            [styles.upiIdDivMob]: isMobile,
          })}
        >
          <div>{SEND_ENUM.upiId}</div>
          <div>
            <img
              src={copiedIndex ? tickIcon : copyIcon}
              alt="Copy"
              className={styles.copyIcon}
              onClick={() => handleCopyClick(SEND_ENUM.upiId)}
            />
          </div>
        </div>
        <div
          className={cx(styles.transactionId, {
            [styles.transactionIdMob]: isMobile,
          })}
        >
          {SEND_ENUM.enterTransactionId}
        </div>
        <div
          className={cx(styles.inputField, {
            [styles.inputFieldMob]: isMobile,
          })}
        >
          <input
            type="number"
            value={upiTransactionID}
            onChange={handleOnChangeTransactionId}
            autoComplete="off"
            className={styles.inputAmount}
          />
        </div>
        <div className={styles.disclaimerNote}>
          {!isMobile && <img src={disclaimerLogo} alt="disclaimer-logo" />}
          <div className={styles.note}>
            {SEND_ENUM.transactionNote}
            {isMobile && <span>{SEND_ENUM.checkBoxNote} </span>}
          </div>
        </div>
        {!isMobile && (
          <div className={styles.checkBoxNote}>{SEND_ENUM.checkBoxNote}</div>
        )}
        <div
          className={cx(styles.checklist, {
            [styles.checklistMob]: isMobile,
          })}
        >
          <div>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className={cx(styles["checkbox-input"], {
                [styles.checked]: isChecked,
              })}
            />
          </div>
          <div className={styles["checkbox-label"]}>{SEND_ENUM.paid}</div>
        </div>
        <div
          className={cx(styles.buttonContainer, {
            [styles.buttonContainerMob]: isMobile,
          })}
        >
          {/*custom styled cancel button */}
          <ButtonRade
            className={cx(styles.cancelButton, {
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={handleClickCancelButton}
          >
            {SEND_ENUM.cancel}
          </ButtonRade>
          {/*custom styled paid button */}
          {isLoading ? (
            <ButtonRade
              className={cx(styles.paidButton, {
                [styles.paidButtonMob]: isMobile,
              })}
              disabled={true}
            >
              <Loading />
            </ButtonRade>
          ) : (
            <ButtonRade
              className={cx(styles.paidButton, {
                [styles.paidButtonMob]: isMobile,
              })}
              onClick={handleClickPaidButton}
            >
              {SEND_ENUM.paidButton}
              <NextIcon />
            </ButtonRade>
          )}
        </div>
        {/* error message displaying section */}
        {errorForCheckbox && (
          <div
            className={cx(styles.errorMessage, {
              [styles.errorMessageMob]: isMobile,
            })}
          >
            {SEND_ENUM.checkBoxError}
          </div>
        )}
        {errorForTransactionId && (
          <div
            className={cx(styles.errorMessage, {
              [styles.errorMessageMob]: isMobile,
            })}
          >
            {SEND_ENUM.transactionIdError}
          </div>
        )}
        {invalidTransactionId && (
          <div
            className={cx(styles.errorMessage, {
              [styles.errorMessageMob]: isMobile,
            })}
          >
            {SEND_ENUM.invalidTransactionId}
          </div>
        )}
      </div>
    </div>
  );
  return (
    <>
      <div className={styles.cardMainDiv}>
        <Card titleComponent={<CardTitle />}>{CardBody()}</Card>
      </div>
      {/*switch statement to make upcoming modals open based on modalPageNumber */}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {(() => {
          switch (modalPageNumber) {
            case 0:
              return (
                <PaymentVerificationModal
                  setModalPageNumber={setModalPageNumber}
                  upiTransactionID={upiTransactionID}
                  amountInUsdt={amountInUsdt}
                  setPaymentTxDetails={setPaymentTxDetails}
                />
              );
            // to be implemented later
            // case 1:
            //   return (
            //     <TransactionProgressModal
            //       setModalPageNumber={setModalPageNumber}
            //     />
            //   );
            case 1:
              return (
                <TransactionSuccessfulModal
                  setModalIsOpen={setModalIsOpen}
                  paymentTxDetails={paymentTxDetails}
                />
              );
            default:
              return null;
          }
        })()}
      </Modal>
    </>
  );
};

export default PaymentMethod;
