import React, { useContext, useState, useEffect } from "react";
import cx from "classnames";
import Card from "../../components/Card/Card";
import styles from "./index.module.scss";
import { SUPPORT_ENUM } from "../../enums/supportEnum";
import NextIcon from "../../assets/NextIcon";
import { AppContext } from "../../context";
import ButtonRade from "../../components/RadeButtons";
import SupportTicketRadioButtons from "../SupportTicket/SupportTicket";
import FailedTransaction from "../FailedTransaction/FailedTransaction";
import FeedBack from "../Feedback/Feedback";
import MyTickets from "../MyTickets/MyTickets";
import { db } from "../../firebase";
import { collection, doc, getDocs } from "firebase/firestore";

const SupportLandingPage = () => {
  const {
    isMobile,
    profileEmail,
    supportFlowPageNumber,
    setSupportFlowPageNumber,
    supportTickets,
    setSupportTickets,
  } = useContext(AppContext);

  // handle on click support ticket button
  const handleOnClickSupportTicket = () => {
    setSupportFlowPageNumber(1);
  };
  // handle on click my tickets button
  const handleOnClickMyTickets = () => {
    fetchUserTickets();
    setSupportFlowPageNumber(4);
  };

  //state to set the order id
  const [orderId, setOrderId] = useState("");
  // state to set the message
  const [message, setMessage] = useState("");
  // state to set feedback message
  const [feedbackMessage, setFeedbackMessage] = useState("");
  // state to store the button value that user selected
  const [userModeSelection, setUserModeSelection] = useState(null);

  // fetch the requested support tickets
  const fetchUserTickets = async () => {
    try {
      // Create a reference to the user's profile using the email
      const profileRef = doc(db, "ticketCollection", profileEmail); // Assuming "profiles" is the collection name

      // Create a reference to the user's ticket list under their profile
      const ticketListRef = collection(profileRef, "ticketList");

      const querySnapshot = await getDocs(ticketListRef);

      const fetchedTickets = [];
      querySnapshot.forEach((doc) => {
        fetchedTickets.push({ id: doc.id, data: doc.data() });
      });

      setSupportTickets(fetchedTickets); // Assuming you have a state variable to store the fetched tickets
    } catch (error) {
      console.error("Error fetching user tickets: ", error);
    }
  };

  useEffect(() => {
    fetchUserTickets();
  }, [profileEmail]);

  const CardTitle = () => (
    <div
      className={cx(styles.supportTitle, {
        [styles.supportTitleMob]: isMobile,
      })}
    >
      {SUPPORT_ENUM.supportTitle}
    </div>
  );
  const CardBody = () => (
    <div
      className={cx(styles.supportMainContainer, {
        [styles.supportMainContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.supportTicketContainer, {
          [styles.supportTicketContainerMob]: isMobile,
        })}
        onClick={handleOnClickSupportTicket}
      >
        <ButtonRade
          customStyling={cx(styles.proceedButton, {
            [styles.proceedButtonMob]: isMobile,
          })}
        >
          {SUPPORT_ENUM.supportTicket}
          <div
            className={cx(styles.nextIcon, {
              [styles.nextIconMob]: isMobile,
            })}
          >
            <NextIcon />
          </div>
        </ButtonRade>
      </div>
      {supportTickets.length !== 0 && (
        <div
          className={cx(styles.supportTicketContainer, {
            [styles.supportTicketContainerMob]: isMobile,
          })}
          onClick={handleOnClickMyTickets}
        >
          <ButtonRade
            customStyling={cx(styles.proceedButton, {
              [styles.proceedButtonMob]: isMobile,
            })}
          >
            {SUPPORT_ENUM.myTickets}
            <div
              className={cx(styles.nextIcon, {
                [styles.nextIconMob]: isMobile,
              })}
            >
              <NextIcon />
            </div>
          </ButtonRade>
        </div>
      )}
      <a
        href="https://docs.tetherx.co.in/overview/tetherx"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.docsLink}
      >
        <div
          className={cx(styles.supportTicketContainer, {
            [styles.supportTicketContainerMob]: isMobile,
          })}
        >
          <ButtonRade
            customStyling={cx(styles.proceedButton, {
              [styles.proceedButtonMob]: isMobile,
            })}
          >
            {SUPPORT_ENUM.exploreDocs}
            <div
              className={cx(styles.nextIcon, {
                [styles.nextIconMob]: isMobile,
              })}
            >
              <NextIcon />
            </div>
          </ButtonRade>
        </div>
      </a>
      <div
        className={cx(styles.note, {
          [styles.noteMob]: isMobile,
        })}
      >
        {SUPPORT_ENUM.supportNote}
        <div className={styles.supportMail}>tetherx-support@kugabana.com</div>
      </div>
    </div>
  );

  return (
    <>
      {(() => {
        //Update to next pages in the stake flow based on the switch statement and the Page number defined.
        switch (supportFlowPageNumber) {
          case 1:
            return (
              <SupportTicketRadioButtons
                userModeSelection={userModeSelection}
                setUserModeSelection={setUserModeSelection}
              />
            );
          case 2:
            return (
              <FailedTransaction
                orderId={orderId}
                setOrderId={setOrderId}
                message={message}
                setMessage={setMessage}
                userModeSelection={userModeSelection}
              />
            );
          case 3:
            return (
              <FeedBack
                feedbackMessage={feedbackMessage}
                setFeedbackMessage={setFeedbackMessage}
                userModeSelection={userModeSelection}
              />
            );
          case 4:
            return <MyTickets fetchUserTickets={fetchUserTickets} />;
          default:
            return <Card titleComponent={<CardTitle />}>{CardBody()}</Card>;
        }
      })()}
    </>
  );
};

export default SupportLandingPage;
