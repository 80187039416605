import React, { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../context";
import styles from "./index.module.scss";
import { SUPPORT_ENUM } from "../../enums/supportEnum";
import ButtonRade from "../../components/RadeButtons";
import Spinner from "../../components/Spinner/Spinner";

const MyTickets = ({ fetchUserTickets }) => {
  const { isMobile, setSupportFlowPageNumber, supportTickets } =
    useContext(AppContext);

  // state to expand the purchase details
  const [openTicketDetails, setOpenTicketDetails] = useState({});

  useEffect(() => {
    fetchUserTickets();
  }, []);

  const handleClickTicketDetails = (dataId) => {
    setOpenTicketDetails((prevState) => {
      const updatedState = {};

      // Close all other divs
      for (const key in prevState) {
        updatedState[key] = false;
      }

      // Toggle the clicked div
      updatedState[dataId] = !prevState[dataId];

      return updatedState;
    });
  };
  const handleClickBackButton = () => {
    setSupportFlowPageNumber(-1);
  };

  const CardTitle = () => (
    <div
      className={cx(styles.myTicketsTitle, {
        [styles.myTicketsTitleMob]: isMobile,
      })}
    >
      <div>{SUPPORT_ENUM.myTickets}</div>
      <div>
        <ButtonRade
          onClick={handleClickBackButton}
          customStyling={cx(styles.backButton, {
            [styles.backButtonMob]: isMobile,
          })}
        >
          {SUPPORT_ENUM.support}
        </ButtonRade>
      </div>
    </div>
  );
  const CardBody = () => (
    <div
      className={cx(styles.myTicketsContainer, {
        [styles.myTicketsContainerMob]: isMobile,
      })}
    >
      {!supportTickets ? (
        <Spinner />
      ) : (
        supportTickets
          .slice()
          .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
          .map((ticket) => (
            <div
              className={cx(styles.tickets, {
                [styles.ticketsMob]: isMobile,
              })}
              key={ticket.id}
              onClick={() => handleClickTicketDetails(ticket.id)}
            >
              <div
                className={cx(styles.ticketDetails, {
                  [styles.ticketDetailsMob]: isMobile,
                })}
              >
                <div
                  className={cx(styles.ticketContainer, {
                    [styles.ticketContainerMob]: isMobile,
                  })}
                >
                  <div className={styles.keys}>{SUPPORT_ENUM.ticket}</div>
                  <div className={styles.values}>{ticket.id}</div>
                </div>
                {!isMobile && (
                  <div className={styles.typeContainer}>
                    <div className={styles.keys}>{SUPPORT_ENUM.type}</div>
                    <div className={styles.values}>
                      {ticket.data.ticketType}
                    </div>
                  </div>
                )}
                <div className={styles.statusContainer}>
                  <div className={styles.keys}>{SUPPORT_ENUM.ticketStatus}</div>
                  <div
                    className={cx(styles.statusValue, {
                      [styles.submitted]:
                        ticket.data.ticketStatus === "Submitted",
                      [styles.open]: ticket.data.ticketStatus === "Open",
                      [styles.resolved]:
                        ticket.data.ticketStatus === "Resolved",
                    })}
                  >
                    {ticket.data.ticketStatus}
                  </div>
                </div>
              </div>
              {isMobile && openTicketDetails[ticket.id] && (
                <div className={styles.typeContainerMob}>
                  <div className={styles.keys}>{SUPPORT_ENUM.type}</div>
                  <div className={styles.values}>{ticket.data.ticketType}</div>
                </div>
              )}
              {openTicketDetails[ticket.id] && (
                <div className={styles.openTicketdetails}>
                  <div
                    className={cx(styles.messageDetails, {
                      [styles.messageDetailsMob]: isMobile,
                    })}
                  >
                    <div
                      className={cx(styles.messageContainer, {
                        [styles.messageContainerMob]: isMobile,
                      })}
                    >
                      <div className={styles.keys}>{SUPPORT_ENUM.message}</div>
                      <div className={styles.messageValue}>
                        {ticket.data.message}
                      </div>
                    </div>
                    {ticket.data.ticketReply !== "" && (
                      <div className={styles.replyContainer}>
                        <div className={styles.keys}>{SUPPORT_ENUM.reply}</div>
                        <div className={styles.messageValue}>
                          {ticket.data.ticketReply}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))
      )}
    </div>
  );
  return <Card titleComponent={<CardTitle />}>{CardBody()}</Card>;
};

export default MyTickets;
