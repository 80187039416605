import React, { useState } from "react";
import attachmentLogo from "../../assets/attachmentLogo.svg";
import styles from "./index.module.scss";

function AttachFile() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];

    if (
      selectedFile &&
      selectedFile.size <= 5000000 &&
      /(jpeg|png)$/i.test(selectedFile.name)
    ) {
      setErrorMessage(null);
      setFilePreview(URL.createObjectURL(selectedFile)); // Set the preview URL
    } else {
      setErrorMessage(
        "Please select a valid file (JPEG or PNG) that is under 5MB!"
      );
      setFilePreview(null);
    }
  };

  return (
    <div className={styles.attachFileContainer}>
      {filePreview ? (
        <div className={styles.previewDivWithFile}>
          <img
            src={filePreview}
            alt="attachment-preview"
            className={styles.previewImage}
          />
          <label htmlFor="file-upload">
            <div className={styles.imageUpload}>
              <div className={styles.uploadIcon}>
                <img
                  src={attachmentLogo}
                  alt="attachment-logo"
                  className={styles.attachmentLogo}
                />
              </div>
              <div className={styles.dragAndDropText}>
                <span className={styles.browseLink}>Change attachment</span>
              </div>
            </div>
          </label>
        </div>
      ) : (
        <div className={styles.previewDivWithoutFile}>
          <label htmlFor="file-upload">
            <div className={styles.imageUpload}>
              <div className={styles.uploadIcon}>
                <img
                  src={attachmentLogo}
                  alt="attachment-logo"
                  className={styles.attachmentLogo}
                />
              </div>
              <div className={styles.dragAndDropText}>
                <span className={styles.browseLink}>Upload attachment</span>
              </div>
            </div>
          </label>
        </div>
      )}
      <input
        type="file"
        id="file-upload"
        accept=".jpeg,.jpg,.png"
        onChange={handleFileSelect}
        style={{ display: "none" }}
      />
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
}

export default AttachFile;
