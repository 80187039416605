import React, { useContext } from "react";
import styles from "./index.module.scss";
import { POPUP_ENUM } from "../../enums/popUpEnum";
import ButtonRade from "../../components/RadeButtons";
import cx from "classnames";
import { AppContext } from "../../context";

const ServiceTicketSubmit = ({ setModalIsOpen }) => {
  const { isMobile, setSupportFlowPageNumber } = useContext(AppContext);

  const handleClickCloseButton = () => {
    setModalIsOpen(false);
    setSupportFlowPageNumber(4);
  };
  const CardBody = () => (
    <div>
      <div
        className={cx(styles.serviceTicketSubmittedTitle, {
          [styles.serviceTicketSubmittedTitleMob]: isMobile,
        })}
      >
        {POPUP_ENUM.serviceTicketSubmittedTitle}
      </div>
      <div
        className={cx(styles.serviceTicketSubmittedContent, {
          [styles.serviceTicketSubmittedContentMob]: isMobile,
        })}
      >
        {POPUP_ENUM.serviceTicketSubmitted}
      </div>
      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          className={cx(styles.continueButton, {
            [styles.continueButtonMob]: isMobile,
          })}
          onClick={handleClickCloseButton}
        >
          {POPUP_ENUM.close}
        </ButtonRade>
      </div>
    </div>
  );
  return CardBody();
};

export default ServiceTicketSubmit;
