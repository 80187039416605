import React, { useContext, useState, useRef } from "react";
import cx from "classnames";
import styles from "./index.module.scss";
import { AppContext } from "../../context";
import { SEND_ENUM } from "../../enums/sendEnum";
import { SUPPORT_ENUM } from "../../enums/supportEnum";
import ButtonRade from "../../components/RadeButtons";
import NextIcon from "../../assets/NextIcon";
import Card from "../../components/Card/Card";
import AttachFile from "./AttachFile";
import Modal from "react-modal";
import ServiceTicketSubmit from "../ServiceTicketSubmitModal/ServiceTicketSubmitModal";
import ReCAPTCHA from "react-google-recaptcha";
import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
const FeedBack = ({
  feedbackMessage,
  setFeedbackMessage,
  userModeSelection,
}) => {
  const { isMobile, profileEmail, setSupportFlowPageNumber } =
    useContext(AppContext);
  // state to set error message
  const [errorMessage, setErrorMessage] = useState("");
  //state to set the modal to open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);

  //state to check if reCAPTCHA is verified or not
  const [isVerified, setIsVerified] = useState(false);
  const handleRecaptchaChange = (response) => {
    console.log("response", response);
    if (response) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  };

  const handleMessageChange = (e) => {
    setErrorMessage("");
    setFeedbackMessage(e.target.value);
  };
  const inputRef = useRef(null);
  const handleDivClick = () => {
    // When the div is clicked, focus the input field
    inputRef.current.focus();
  };
  const handleSubmitProceedButton = async () => {
    if (!feedbackMessage) {
      setErrorMessage("Please enter your message!");
    } else if (!isVerified) {
      setErrorMessage("reCAPTCHA verification failed. Please try again.");
    } else {
      setErrorMessage("");
      setModalIsOpen(true);
      try {
        // Prepare the support ticket data
        const supportTicketData = {
          message: feedbackMessage,
          ticketType: userModeSelection,
          ticketStatus: "Submitted",
          ticketReply: "",
        };

        // Create a reference to the user's profile using the email
        const profileRef = doc(db, "ticketCollection", profileEmail); // Assuming "profiles" is the collection name

        // Create the user's profile document if it doesn't exist
        await setDoc(profileRef, {}); // Create an empty document

        // Create a reference to the user's ticket list under their profile
        const ticketListRef = collection(profileRef, "ticketList");

        // Add the support ticket data to the ticket list
        await addDoc(ticketListRef, supportTicketData);

        console.log("Support ticket submitted successfully!");

        // Fetch updated tickets after submission
      } catch (error) {
        console.error("Error adding support ticket document: ", error);
      }
    }
  };
  //const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const handleSubmitBackButton = () => {
    setSupportFlowPageNumber(1);
  };
  const CardTitle = () => (
    <div
      className={cx(styles.supportTicketTitle, {
        [styles.supportTicketTitleMob]: isMobile,
      })}
    >
      {SUPPORT_ENUM.supportTicket}
    </div>
  );
  const CardBody = () => (
    <div
      className={cx(styles.failedTransactionMainContainer, {
        [styles.failedTransactionMainContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.fillInputField, {
          [styles.fillInputFieldMob]: isMobile,
        })}
        onClick={handleDivClick}
      >
        <div
          className={cx(styles.inputkey, {
            [styles.inputkeyMob]: isMobile,
          })}
        >
          {SUPPORT_ENUM.issueFacing}
        </div>
        <div
          className={cx(styles.inputValue, {
            [styles.inputValueMob]: isMobile,
          })}
        >
          <textarea
            type="text"
            ref={inputRef} // Attach the ref to the input element
            value={feedbackMessage}
            onChange={handleMessageChange}
            autoComplete="off"
            className={styles.message}
            rows={3}
          />
        </div>
      </div>
      <div
        className={cx(styles.fileAttachDiv, {
          [styles.fileAttachDivMob]: isMobile,
        })}
      >
        <AttachFile />
      </div>
      {/* reCAPTCHA section*/}
      <div className={styles.reCaptchaContainer}>
        <ReCAPTCHA
          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          onChange={handleRecaptchaChange}
          theme="dark"
        />
      </div>
      <div
        className={cx(styles.errorMessage, {
          [styles.errorMessageMob]: isMobile,
        })}
      >
        {errorMessage}
      </div>
      <div
        className={cx(styles.proceedButtonDiv, {
          [styles.proceedButtonDivMob]: isMobile,
        })}
      >
        {/* Custom styled cancel button */}
        <ButtonRade
          outline
          customStyling={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={handleSubmitBackButton}
        >
          {SEND_ENUM.back}
        </ButtonRade>
        {/* Custom styled button for proceed */}
        <ButtonRade
          outline
          customStyling={cx(styles.proceedButton, {
            [styles.proceedButtonMob]: isMobile,
          })}
          onClick={() => {
            handleSubmitProceedButton();
          }}
        >
          {SUPPORT_ENUM.create}
          <NextIcon />
        </ButtonRade>
      </div>
    </div>
  );

  return (
    <>
      <Card titleComponent={<CardTitle />}>{CardBody()}</Card>
      {/* Code for opening the modal */}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ServiceTicketSubmit setModalIsOpen={setModalIsOpen} />
      </Modal>
    </>
  );
};

export default FeedBack;
