import React, { useContext, useState } from "react";
import cx from "classnames";
import { CSSTransition } from "react-transition-group";
import styles from "./mobile.module.scss";
import { useRef } from "react";
import { AppContext } from "../../context";
import { NavLink, useLocation } from "react-router-dom";
import hamburgerClose from "../../assets/hamburgerClose.svg";
import hamburgerMenu from "../../assets/hamburgerMenu.svg";
import FooterMain from "../../components/FooterMain/FooterMain";
import GoogleLogout from "../../components/GoogleLogout/GoogleLogOut";
import Modal from "react-modal";
import { SEND_ENUM } from "../../enums/sendEnum";
const duration = 100;

const NavBarMobile = ({
  profileEmail,
  setProfileEmail,
  setAmountInINR,
  setAmountInUSD,
  setProfile,
}) => {
  // default mobile view navbar
  const [showMenu, setShowMenu] = useState(false);
  const nodeRef = useRef();
  const location = useLocation();
  const transitionStyles = {
    enterActive: styles.menuBarEnterActive,
    enterDone: styles.menuBarEnter,
    exitActive: styles.menuBarExitActive,
    exitDone: styles.menuBarExit,
  };

  const { isMobile } = useContext(AppContext);
  // State to set the Modal open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    setShowMenu(false);
    setModalIsOpen(true);
  };

  const handleNavLinkClick = () => {
    setShowMenu(false);
  };

  return (
    <>
      <div className={styles.mainDiv}>
        <div
          className={cx(styles.mobileButtons, {
            [styles.mobileButtonsMob]: showMenu,
          })}
        >
          {showMenu && (
            <div
              className={cx(styles.profileEmailContainer, {
                [styles.profileEmailContainerMob]: isMobile,
              })}
            >
              <CSSTransition
                in={showMenu}
                timeout={duration}
                classNames={transitionStyles}
                mountOnEnter
                unmountOnExit
              >
                <div
                  className={cx(styles.profileEmail, {
                    [styles.profileEmailMob]: isMobile,
                  })}
                >
                  {profileEmail}
                </div>
              </CSSTransition>
            </div>
          )}
          <button
            className={cx(styles.hamburgerButton, {
              [styles.hbWhite]: !showMenu,
              [styles.hbGreen]: showMenu,
            })}
            onClick={() => setShowMenu((pre) => !pre)}
          >
            {showMenu ? (
              <>
                <img
                  src={hamburgerClose}
                  alt="cross"
                  className={styles.menuIcon}
                />
              </>
            ) : (
              <img
                src={hamburgerMenu}
                alt="menu icon"
                className={styles.menuIcon}
              />
            )}
          </button>
        </div>
        <CSSTransition
          nodeRef={nodeRef}
          in={showMenu}
          timeout={duration}
          classNames={transitionStyles}
          mountOnEnter
        >
          <div ref={nodeRef} className={styles.menuBar}>
            <nav className={styles.navBar}>
              <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                <li className={styles.navBarItems}>
                  <NavLink
                    className={`${styles.navLink} ${
                      location.pathname === "/buy" ? styles.activeNavLink : ""
                    }`}
                    to="/buy"
                    onClick={handleNavLinkClick}
                  >
                    Buy
                  </NavLink>
                </li>
                <li className={styles.navBarItems}>
                  <NavLink
                    className={`${styles.navLink} ${
                      location.pathname === "/profile"
                        ? styles.activeNavLink
                        : ""
                    }`}
                    to="/profile"
                    onClick={handleNavLinkClick}
                  >
                    Profile
                  </NavLink>
                </li>
                <li className={styles.navBarItems}>
                  <NavLink
                    className={`${styles.navLink} ${
                      location.pathname === "/purchases"
                        ? styles.activeNavLink
                        : ""
                    }`}
                    to="/purchases"
                    onClick={handleNavLinkClick}
                  >
                    Purchases
                  </NavLink>
                </li>
                <li className={styles.navBarItems}>
                  <NavLink
                    className={`${styles.navLink} ${
                      location.pathname === "/support"
                        ? styles.activeNavLink
                        : ""
                    }`}
                    to="/support"
                    onClick={handleNavLinkClick}
                  >
                    Support
                  </NavLink>
                </li>
                <div className={styles.discordButtonContainer}>
                  <a
                    href="https://discord.gg/WVBmQ2sjU8"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.navLink}
                  >
                    Discord
                  </a>
                </div>
                <div className={styles.logoutButtonContainer}>
                  <button
                    onClick={logOut}
                    className={cx(styles.logoutButton, {
                      [styles.logoutButtonMob]: isMobile,
                    })}
                  >
                    {SEND_ENUM.logout}
                  </button>
                </div>
                {/* Footer component */}
                <div className={styles.footerMob}>
                  <FooterMain />
                </div>
              </ul>
            </nav>
          </div>
        </CSSTransition>
      </div>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <GoogleLogout
          setModalIsOpen={setModalIsOpen}
          setProfile={setProfile}
          setProfileEmail={setProfileEmail}
          setAmountInINR={setAmountInINR}
          setAmountInUSD={setAmountInUSD}
        />
      </Modal>
    </>
  );
};

export default NavBarMobile;
