import React, { useState } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import { useContext } from "react";
import styles from "./index.module.scss";
import { SEND_ENUM } from "../../enums/sendEnum";
import Timer from "../../components/CountDownTimer/Timer";
import { auth } from "../../firebase";

const PaymentVerificationModal = ({
  setModalPageNumber,
  upiTransactionID,
  amountInUsdt,
  setPaymentTxDetails,
}) => {
  const { isMobile, profileEmail, sessionTxId } = useContext(AppContext);
  // state for displaying error message
  const [error, setError] = useState("");
  const expiryTime = new Date();
  expiryTime.setSeconds(expiryTime.getSeconds() + 10); // 10 seconds timer

  const onExpire = async () => {
    try {
      const transactionDetails = {
        amount_captured: amountInUsdt, // USDT Amount captured from frontend
        email: profileEmail, // User email
        session_txId: sessionTxId, // transaction Id
        upi_tx_reference_id: upiTransactionID, // UPI transaction reference id
      };
      // Obtain ID token
      const idToken = await auth.currentUser.getIdToken(
        /* forceRefresh */ true
      );

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
          // Add other headers if needed
        },
        body: JSON.stringify(transactionDetails),
      };
      const response = await fetch(
        process.env.REACT_APP_TETHERX_SERVER_URI + `/verify-transaction`,
        requestOptions
      );
      if (response.ok) {
        // Request was successful
        const responseData = await response.json();
        setPaymentTxDetails(responseData.paymentTxDetails);
      } else {
        // Handle error response
        const errorData = await response.json();
        // Handle the error data
        console.log("errorData, transaction verification:", errorData);
      }
    } catch (err) {
      console.error("Error fetching payment status:", err);
      setError("Error fetching payment status!");
    }
    setModalPageNumber(1);
  };

  return (
    <div
      className={cx(styles.paymentVerificationDiv, {
        [styles.paymentVerificationDivMob]: isMobile,
      })}
    >
      {/* Animated Circle Loader*/}
      <ul className={styles.loaderList}>
        <li>
          <div
            className={cx(styles.loaderCircle, {
              [styles.loaderCircleMob]: isMobile,
            })}
          >
            <span></span>
          </div>
        </li>
      </ul>
      <div
        className={cx(styles.verifyingContent, {
          [styles.verifyingContentMob]: isMobile,
        })}
      >
        {SEND_ENUM.paymentVerification}
      </div>
      <div className={styles.timer}>
        <Timer expiryTimestamp={expiryTime} onTimerExpire={onExpire} />
      </div>
      {error && (
        <div
          className={cx(styles.errorMessage, {
            [styles.errorMessageMob]: isMobile,
          })}
        >
          {error}
        </div>
      )}
    </div>
  );
};
export default PaymentVerificationModal;
