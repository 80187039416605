import React from "react";
import styles from "./FooterMain.module.scss";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../context";
const FooterMain = () => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      className={cx(styles.footer, {
        [styles.footerMob]: isMobile,
      })}
    >
      <span className={styles.copyright}>tetherX &copy; 2024</span>
      <div className={styles.allRightsReserved}>All rights reserved.</div>
      <div className={styles.privacyAndTerms}>
        <a
          href="https://docs.tetherx.co.in/platform/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.privacyPolicy}
        >
          Privacy
        </a>
        <a
          href="https://docs.tetherx.co.in/platform/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.termsOfUse}
        >
          Terms
        </a>
      </div>
    </div>
  );
};

export default FooterMain;
