import React, { useEffect, useState, useContext } from "react";
import cx from "classnames";
import { SEND_ENUM } from "../../enums/sendEnum";
import { AppContext } from "../../context";
import IndianFlag from "../../assets/INDLogo.svg";
import USFlagIcon from "../../assets/maticLogo.svg";
import RadeButton from "../../components/RadeButtons";
import styles from "./index.module.scss";
import { collection, doc, getDocs } from "firebase/firestore";
import { SUPPORT_ENUM } from "../../enums/supportEnum";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../../firebase";
import Spinner from "../../components/Spinner/Spinner";
import { useLocation } from "react-router-dom";
import NextIcon from "../../assets/NextIcon";
import copyIcon from "../../assets/copyIconWhite.svg";
import tickIcon from "../../assets/tickIconWhite.svg";

const MAX_DECIMAL_PLACE = 2;
const MAX_TX_HASH_LENGTH = 10; // Maximum length to display initially

const ShortenedText = ({ text, maxLength }) => {
  const truncatedText = `${text.slice(0, maxLength)}...`;

  return <span>{truncatedText}</span>;
};

const PurchaseHistory = () => {
  const {
    isMobile,
    profileEmail,
    setSupportFlowPageNumber,
    purchaseHistoryDetails,
    setPurchaseHistoryDetails,
  } = useContext(AppContext);
  // state to expand the purchase details
  const [openPurchaseDetails, setOpenPurchaseDetails] = useState({});

  //  state for storing the wallet details
  const [walletDetails, setWalletDetails] = useState([]);
  const [copiedIndex, setCopiedIndex] = useState(null); // Track the index of the currently copied text
  // get the order id passed from the transaction status modal
  const location = useLocation();
  const orderIdPassed = location.state?.selectedOrderId;
  //open the purchases details div if the order id passed matches the order id in purchaseHistoryDetails
  useEffect(() => {
    if (orderIdPassed) {
      setOpenPurchaseDetails((prevState) => {
        const updatedState = { ...prevState };
        updatedState[orderIdPassed] = true;
        return updatedState;
      });
    }
  }, [orderIdPassed]);

  useEffect(() => {
    // fetch the wallet details from firebase
    async function fetchUserDetails() {
      const userDataRef = doc(firestore, "userData", profileEmail);
      const walletDetailsRef = collection(
        userDataRef,
        "userWalletAddressDetails"
      );
      const querySnapshot = await getDocs(walletDetailsRef);
      const walletDetailsArray = [];
      querySnapshot.forEach((doc) => {
        const walletDetail = doc.data();
        walletDetailsArray.push({
          ...walletDetail,
          recipientWalletID: doc.id,
        });
      });

      // Store the account details in component state
      setWalletDetails(walletDetailsArray);

      // Process the retrieved data as needed
    }
    fetchUserDetails();
  }, [profileEmail]);

  const getPurchasehistoryData = async () => {
    // payload
    const purchaseDetails = {
      email: profileEmail, // User email
    };
    // Obtain ID token
    const idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true);

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add other headers if needed
      },
      Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
      body: JSON.stringify(purchaseDetails),
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_TETHERX_SERVER_URI + `/get-user-purchase-history`,
        requestOptions
      );
      if (response.ok) {
        // Request was successful
        const responseData = await response.json();
        // Handle the response data
        setPurchaseHistoryDetails(responseData.extractedDetails);
      } else {
        // Handle error response
        const errorData = await response.json();
        // Handle the error data
        console.log("errorData, purchase details:", errorData);
      }
    } catch (error) {
      // Handle network or fetch-related errors
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // Call the function to fetch data initially
    getPurchasehistoryData();
  }, [profileEmail]);

  // Function to convert the epoch time
  function formatEpochTime(epochTime) {
    const date = new Date(epochTime);

    // Define the desired date and time formats
    const dateFormat = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };

    const timeFormat = {
      hour: "2-digit",
      minute: "2-digit",
    };

    // Get the date and time strings in the desired format
    const dateStr = date.toLocaleDateString("en-US", dateFormat);
    const timeStr = date.toLocaleTimeString("en-US", timeFormat);

    // Combine the date and time strings
    const formattedTime = `${dateStr} ${timeStr}`;

    return formattedTime;
  }

  let navigate = useNavigate();

  const handleClickSupport = (orderId) => {
    navigate("/support", { state: { orderIds: [orderId] } });
    setSupportFlowPageNumber(2);
  };

  const handleClickExplorer = (txHash) => {
    const explorerURL =
      process.env.REACT_APP_TETHERX_EXPLORER_RPC + `${txHash}`; // Replace with your actual explorer URL

    // Open the URL in a new tab
    window.open(explorerURL, "_blank");
  };

  const handleClickTransactionDetails = (postId) => {
    setOpenPurchaseDetails((prevState) => {
      const updatedState = {};

      // Close all other divs
      for (const key in prevState) {
        updatedState[key] = false;
      }

      // Toggle the clicked div
      updatedState[postId] = !prevState[postId];

      return updatedState;
    });
  };

  const handleCopyClick = (value, index) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        // Copying to clipboard succeeded
        console.log("Copied to clipboard:", value);
        setCopiedIndex(index);
        setTimeout(() => {
          setCopiedIndex(false); // Change back to copy icon after a delay
        }, 5000);
      })
      .catch((error) => {
        // Copying to clipboard failed
        console.error("Failed to copy to clipboard:", error);
      });
  };
  if (!purchaseHistoryDetails) {
    return (
      <div className={styles.spinnerDiv}>
        <Spinner />
      </div>
    );
  } else if (purchaseHistoryDetails.length === 0) {
    return (
      <>
        <div className={styles.fetchHistoryDetails}>
          <h1
            className={cx(styles.noRecentTransaction, {
              [styles.noRecentTransactionMob]: isMobile,
            })}
          >
            {SEND_ENUM.purchaseHistory}
          </h1>
          <div
            className={cx(styles.youHaveNoTransaction, {
              [styles.youHaveNoTransactionMob]: isMobile,
            })}
          >
            {SEND_ENUM.noPurchases}
          </div>
        </div>
      </>
    );
  } else if (purchaseHistoryDetails.length > 0) {
    return (
      <div className={styles.fetchHistoryDetails}>
        <div
          className={cx(styles.purchaseTitleContainer, {
            [styles.purchaseTitleContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.yourRecentTransaction, {
              [styles.yourRecentTransactionMob]: isMobile,
            })}
          >
            {SEND_ENUM.purchaseHistory}
          </div>
          <div>
            <RadeButton
              onClick={getPurchasehistoryData}
              customStyling={cx(styles.refreshButton, {
                [styles.refreshButtonMob]: isMobile,
              })}
            >
              {SEND_ENUM.refresh}
            </RadeButton>
          </div>
        </div>

        <div
          className={cx(styles.transferHistory, {
            [styles.transferHistoryMob]: isMobile,
          })}
        >
          {purchaseHistoryDetails
            .slice()
            .sort((a, b) => b.created_at - a.created_at)
            .map((post) => (
              <div key={post.order_id.S}>
                <div
                  className={cx(styles.transactionDetailsContainer, {
                    [styles.transactionDetailsContainerMob]: isMobile,
                  })}
                  onClick={() => handleClickTransactionDetails(post.order_id.S)}
                >
                  <div
                    className={cx(styles.amountContainer, {
                      [styles.amountContainerMob]: isMobile,
                    })}
                  >
                    {!isMobile ? (
                      <img
                        src={IndianFlag}
                        alt="IndiaFlagIcon"
                        className={styles.indiaFlagIcon}
                      />
                    ) : (
                      <img
                        src={USFlagIcon}
                        alt="USflag-icon"
                        className={styles.recieverIcon}
                      />
                    )}
                    <div className={styles.amountKey}>
                      {!isMobile
                        ? Number(post.inr_amount).toFixed(MAX_DECIMAL_PLACE)
                        : Number(post.usdt_amount).toFixed(MAX_DECIMAL_PLACE)}
                    </div>
                  </div>

                  {!openPurchaseDetails[post.order_id.S] && !isMobile && (
                    <div className={styles.recipientContainer}>
                      <img
                        src={USFlagIcon}
                        alt="USflag-icon"
                        className={styles.recieverIcon}
                      />
                      {Number(post.usdt_amount).toFixed(MAX_DECIMAL_PLACE)}
                    </div>
                  )}

                  <div
                    className={cx(styles.dateContainer, {
                      [styles.dateContainerMob]: isMobile,
                    })}
                  >
                    {formatEpochTime(Number(post.created_at))}
                  </div>
                </div>
                {openPurchaseDetails[post.order_id.S] && (
                  <div
                    className={cx(styles.transactionDetailsDisplay, {
                      [styles.transactionDetailsDisplayMob]: isMobile,
                    })}
                  >
                    <div
                      className={cx(styles.recieverContainer, {
                        [styles.recieverContainerMob]: isMobile,
                      })}
                    >
                      <div
                        className={cx(styles.recipientContainer, {
                          [styles.recipientContainerMob]: isMobile,
                          [styles.ifOpenPurchaseHistory]: openPurchaseDetails,
                        })}
                      >
                        {!isMobile ? (
                          <img
                            src={USFlagIcon}
                            alt="USflag-icon"
                            className={styles.recieverIcon}
                          />
                        ) : (
                          <img
                            src={IndianFlag}
                            alt="IndiaFlagIcon"
                            className={styles.indiaFlagIcon}
                          />
                        )}
                        {!isMobile
                          ? Number(post.usdt_amount).toFixed(MAX_DECIMAL_PLACE)
                          : Number(post.inr_amount).toFixed(MAX_DECIMAL_PLACE)}
                      </div>
                      {post.tx_status && (
                        <div>
                          {post.tx_status === "delivery_complete" && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonSuccess, {
                                [styles.statusButtonSuccessMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.success}
                            </RadeButton>
                          )}
                          {post.tx_status === "payment_rejected" && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonFailed, {
                                [styles.statusButtonFailedMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.failed}
                            </RadeButton>
                          )}

                          {(post.tx_status === "created" ||
                            post.tx_status === "payment_init") && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonProcessing, {
                                [styles.statusButtonProcessingMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.paymentInitButton}
                            </RadeButton>
                          )}
                          {post.tx_status === "processing" && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonProcessing, {
                                [styles.statusButtonProcessingMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.processingButton}
                            </RadeButton>
                          )}
                          {post.tx_status === "payment_received" && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonProcessing, {
                                [styles.statusButtonProcessingMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.paymentReceivedButton}
                            </RadeButton>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      className={cx(styles.transferIDContainer, {
                        [styles.transferIDContainerMob]: isMobile,
                      })}
                    >
                      <div>
                        <div className={styles.heading}>
                          {SEND_ENUM.destination}
                        </div>
                        {walletDetails.find(
                          (address) =>
                            address.recipientWalletID === post.receiver_id
                        )?.walletAddress && (
                          <div className={styles.value}>
                            {
                              walletDetails.find(
                                (address) =>
                                  address.recipientWalletID === post.receiver_id
                              ).walletAddress
                            }
                          </div>
                        )}
                      </div>
                      <div className={styles.supportButtonContainer}>
                        <div
                          className={cx(styles.orderIdContainer, {
                            [styles.orderIdContainerMob]: isMobile,
                          })}
                        >
                          <div className={styles.heading}>
                            {SEND_ENUM.orderId}
                          </div>
                          <div className={styles.value}>{post.order_id.S}</div>
                        </div>

                        <div className={styles.supportButtonDiv}>
                          <RadeButton
                            customStyling={cx(styles.support, {
                              [styles.supportMob]: isMobile,
                            })}
                            onClick={() => handleClickSupport(post.order_id.S)}
                          >
                            {SUPPORT_ENUM.support}
                          </RadeButton>
                        </div>
                      </div>
                    </div>
                    <div
                      className={cx(styles.txOnChainContainer, {
                        [styles.txOnChainContainerMob]: isMobile,
                      })}
                    >
                      <div
                        className={cx(styles.transactionOnChainId, {
                          [styles.transactionOnChainIdMob]: isMobile,
                        })}
                      >
                        <div className={styles.heading}>
                          {SEND_ENUM.TxOnChainId}
                        </div>
                        <div className={styles.valueCopyContainer}>
                          {post.tx_status === "created" ||
                          post.tx_status === "payment_init" ? (
                            "- -"
                          ) : post.tx_status === "payment_received" ? (
                            "Pending Confirmation"
                          ) : post.tx_status === "delivery_complete" &&
                            post.tx_hash ? (
                            <ShortenedText
                              text={post.tx_hash}
                              maxLength={MAX_TX_HASH_LENGTH}
                            />
                          ) : (
                            "Undefined, contact support"
                          )}
                          {post.tx_hash && (
                            <img
                              src={
                                copiedIndex === post.order_id.S
                                  ? tickIcon
                                  : copyIcon
                              }
                              alt="Copy"
                              className={styles.copyIcon}
                              onClick={() =>
                                handleCopyClick(post.tx_hash, post.order_id.S)
                              }
                            />
                          )}
                          {copiedIndex === post.order_id.S && "Copied"}
                        </div>
                      </div>
                      <div
                        className={cx(styles.buttonContainer, {
                          [styles.buttonContainerMob]: isMobile,
                        })}
                      >
                        <RadeButton
                          outline
                          customStyling={cx(styles.explorerButton, {
                            [styles.explorerButtonMob]: isMobile,
                            [styles.disabledExplorer]:
                              post.tx_status !== "delivery_complete",
                          })}
                          disabled={post.tx_status !== "delivery_complete"}
                          onClick={() => handleClickExplorer(post.tx_hash)}
                        >
                          {SEND_ENUM.explorerButton}
                          <NextIcon />
                        </RadeButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  }
};

export default PurchaseHistory;
