export const PROFILE_ENUM = {
  proceed: "Proceed",
  fullName: "Full Name",
  phone: "Phone",
  address: "Address",
  update: "Update",
  email: "Email",
  invalidEmail: "Email is invalid",
  emptyInput: "Please fill up the required details!",
  invalidNumber: "Invalid phone number!",
  errorForFirstName: "Please enter the first name!",
  errorForLastName: "Please enter the last name!",
  errorForEmail: "Please enter the email!",
  errorForNumber: "Please enter the phone number!",
  errorForID: "Please upload the IDs",
  idUpload: "ID Upload",
  pancard: "Pancard",
  aadhar: "Aadhaar",
  attachment: "Attachment",
  updateProfileSuccesfully: "Details updated successfully!",
  editButton: "Edit",
  errorForPancard: "Please upload the Pancard Id!",
  errorForPancardHold:
    "Please upload the clear photo of you holding the Pancard!",
  errorForAadhar: "Please upload the Aadhaar card frontside!",
  errorForAadharBack: "Please upload the Aadhaar card backside!",
  errorForAadharHold:
    "Please upload the clear photo of you holding the Aadhaar!",
  profile: "Profile",
  profileIsNotverified:
    "Your profile is not verified. Verify your profile by completing KYC Verfification Process.",
  KycButton: " Start KYC",
  KYCApplicationSubmitted: "KYC Applicaiton submitted successfully.",
  weWillLetYouKnow: "We will let you know once your account is fully verified.",
  KYC: "KYC",
  statusOfKYC: "Status :",
  submittedAndWaiting: "Submitted and Awaiting for Approval.",
  yourAccountIsVerified:
    "Your account is Verified. Now you can start purchasing USDT.",
  yourApplicationIsRejected: "Your application is rejected",
  KycRejectedReason: " Reason: Reason statement",
  reSubmitKyc: "Resubmit KYC",
  changeFile: "Click thumbnail to change the file.",
  submit: "Submit",
  supportedFiles: "Allowed file types: PNG, JPEG and PDF.",
  loading: "Loading",
  idHolding: "Selfie holding Aadhaar card",
  aadharFront: "Aadhaar Frontside",
  aadharBack: "Aadhaar Backside",
  aadharHolding: "Holding Aadhaar",
  errorForPancardNumber: "Please enter your pancard number!",
  errorForAadharNumber: "Please enter your aadhaar number!",
};
