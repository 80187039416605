import React, { useState, useEffect, useContext } from "react";
import attachmentLogo from "../../assets/attachmentLogo.svg";
import styles from "./index.module.scss";
import { firestore } from "../../firebase";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AppContext } from "../../context";

function KycUpload({
  id,
  aadharHolding,
  setAadharHolding,
  setErrorForAadharHold,
  reSubmitKyc,
  setIsFileUploaded,
  setIsLoadingAadharHold,
}) {
  const { profileEmail } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const handleFileUpload = async (selectedFile) => {
    if (
      selectedFile &&
      selectedFile.size <= 5000000 &&
      /(jpeg|png)$/i.test(selectedFile.name)
    ) {
      if (id === "holdAadhar") {
        setIsLoadingAadharHold(true);
      }
      const storage = getStorage(); // Get the storage object
      const storageRef = ref(storage); // Create a reference to the storage root

      let filePath = ""; // Initialize the file path variable

      if (id === "holdAadhar") {
        filePath = `kycDetails/${profileEmail}/aadharHolding/${selectedFile.name}`;
      }

      if (filePath !== "") {
        const fileRef = ref(storageRef, filePath); // Create a reference to the file

        try {
          // Upload the file to Firebase Storage
          await uploadBytes(fileRef, selectedFile);
          // Retrieve the download URL for the uploaded file
          const downloadURL = await getDownloadURL(fileRef);

          if (id === "holdAadhar") {
            setAadharHolding(downloadURL); // Set the download URL
            setIsFileUploaded(true);
            setErrorForAadharHold(false); // Reset the error for aadhar card
          }

          setErrorMessage(null);
        } catch (error) {
          console.error("Error uploading file to Firebase Storage:", error);
        } finally {
          setIsLoadingAadharHold(false); // Stop loading
        }
      }
    } else {
      if (id === "holdAadhar") {
        setAadharHolding(null);
      }

      setErrorMessage(
        "Please select a valid file (JPEG or PNG) that is under 5MB!"
      );
    }
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    handleFileUpload(selectedFile);
  };

  async function fetchDetails() {
    const profileDataRef = collection(firestore, "userData"); // Reference to the "profileData" collection
    const profileDocRef = doc(profileDataRef, profileEmail); // Reference to the document with email ID as document ID
    const profileData = {
      AadharCardHold: aadharHolding,
    };
    await updateDoc(profileDocRef, profileData);
  }

  useEffect(() => {
    if (aadharHolding) {
      fetchDetails();
    }
  }, []);

  const handleImageClick = () => {
    if (reSubmitKyc !== "Pending" && reSubmitKyc !== "Verified") {
      // Trigger file selection when the image is clicked
      document.getElementById(`${id}-upload`).click();
    }
  };
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const profileDataRef = collection(firestore, "userData");
        const documentId = profileEmail; // Email of the user
        const userDocRef = doc(profileDataRef, documentId);
        const documentSnapshot = await getDoc(userDocRef);
        if (documentSnapshot.exists()) {
          const userData = documentSnapshot.data();
          // Set the values from Firestore to the state

          setAadharHolding(userData.AadharCardHold || "");
        } else {
          // The document does not exist
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error retrieving profile information:", error);
      }
    };

    fetchProfileData();
  }, [profileEmail]);

  return (
    <div className={styles.attachFileContainer}>
      {aadharHolding && id === "holdAadhar" ? (
        <div className={styles.previewDiv}>
          <img
            src={aadharHolding}
            alt="attachment-preview"
            className={styles.previewImage}
            onClick={handleImageClick}
          />
          <label htmlFor={`${id}-upload`}>
            <div className={styles.imageUpload}></div>
          </label>
          <input
            type="file"
            id={`${id}-upload`}
            accept=".jpeg,.png,.jpg"
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
        </div>
      ) : (
        <div className={styles.previewDivWithoutFile}>
          <label htmlFor={`${id}-upload`}>
            <div className={styles.imageUpload}>
              <div className={styles.uploadIcon}>
                <img src={attachmentLogo} alt="attachment-logo" />
              </div>
              <div className={styles.dragAndDropText}>
                <div className={styles.browseLink}>Upload attachment</div>
              </div>
            </div>
          </label>
        </div>
      )}
      <input
        type="file"
        id={`${id}-upload`}
        accept=".jpeg,.png,.jpg"
        onChange={handleFileSelect}
        style={{ display: "none" }}
      />
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
}

export default KycUpload;
