import React, { useContext, useEffect } from "react";
import styles from "./Card.module.scss";
import { AppContext } from "../../context";
import cx from "classnames";

const Card = ({ titleComponent, children }) => {
  const {
    isMobile,
    isNavBarClicked,
    setIsAnimationRequired,
    isAnimationRequired,
  } = useContext(AppContext);
  console.log("isNavBarClicked", isNavBarClicked);
  useEffect(() => {
    if (isNavBarClicked) {
      setIsAnimationRequired(true);
    }
  }, [isAnimationRequired, isNavBarClicked, setIsAnimationRequired]);

  return (
    <div
      className={cx(styles.rudMainDiv, {
        [styles.rudMainDivMob]: isMobile,
        [styles.appear]: isAnimationRequired,
      })}
    >
      <div className={styles.titleContainer}>{titleComponent}</div>
      <div className={styles.bodyContainer}>{children}</div>
    </div>
  );
};

export default Card;
