import React, { useState, useContext, useEffect } from "react";
import styles from "./index.module.scss";
import { POPUP_ENUM } from "../../enums/popUpEnum";
import ButtonRade from "../../components/RadeButtons";
import { AppContext } from "../../context";
import cx from "classnames";
import { firestore, auth } from "../../firebase";
import {
  collection,
  doc,
  getDocs,
  addDoc,
  query,
  where,
} from "firebase/firestore";

const AcceptsTermsToContinuePopUp = ({
  setModalIsOpen,
  walletAddress,
  selectedChain,
  nickName,
}) => {
  // import the required values from the context
  const {
    isMobile,
    setBuyUSDTFlowPageNumber,
    profileEmail,
    setRecipientWalletAddressID,
  } = useContext(AppContext);
  // state to check if the checkbox is marked or not
  const [isCheckedPrivacyAndTerms, setIsCheckedPrivacyAndTerms] =
    useState(false);
  const [isCheckedTds, setIsCheckedTds] = useState(false);
  // state to set error for continue button
  const [errorForContinue, setErrorForContinue] = useState("");
  // state for displaying error message
  const [error, setError] = useState("");

  // const selectedChainValue = selectedChain.value;
  // handle checkbox on change
  const handlePrivacyAndTermsCheckbox = () => {
    setErrorForContinue("");
    setIsCheckedPrivacyAndTerms(!isCheckedPrivacyAndTerms);
  };
  const handleTdsCheckboxChange = () => {
    setErrorForContinue("");
    setIsCheckedTds(!isCheckedTds);
  };
  // handle on click cancel button
  const handleClickCancelButton = () => {
    setModalIsOpen(false);
  };

  const handleClickContinueButton = async () => {
    if (!isCheckedPrivacyAndTerms) {
      setErrorForContinue(
        "Please agree to the terms of use and privacy policy to continue!"
      );
    } else if (!isCheckedTds) {
      setErrorForContinue(
        "Please acknowledge to the TDS consent note to continue!"
      );
    } else {
      try {
        const userDataRef = doc(firestore, "userData", profileEmail);
        const walletDataRef = collection(
          userDataRef,
          "userWalletAddressDetails"
        ); // Reference to the "userData" collection

        // Query the userWalletAddressDetails collection to find an existing nick name is there or not
        const querySnapshot = await getDocs(
          query(walletDataRef, where("nickName", "==", nickName))
        );
        let walletAccountId; // Declare the newWalletId variable

        // If this is a new user wallet details (new entry)
        let walletData = {
          nickName,
          walletAddress,
          selectedChain,
        };
        if (querySnapshot.empty) {
          const addedDoc = await addDoc(walletDataRef, walletData); // add the document data with the wallet data
          walletAccountId = addedDoc.id;
          setRecipientWalletAddressID(walletAccountId);
          console.log("Wallet address details stored in Firebase successfully");
        } else {
          // Existing wallet address details with the same nick name
          const matchedWalletAddress = querySnapshot.docs[0].data();

          if (
            walletAddress === matchedWalletAddress.walletAddress &&
            selectedChain.value === matchedWalletAddress.selectedChain.value
          ) {
            // If all details matched proceed with the existing entry
            walletAccountId = querySnapshot.docs[0].id;
            setRecipientWalletAddressID(walletAccountId);
            console.log("Wallet address details exists");
          } else {
            console.log("Nickname is already in use with different details");
          }
        }
      } catch (error) {
        console.error("Error saving wallet address information:", error);
        setError("Error saving wallet address. Please try again later.");
      }
      setModalIsOpen(false);
      setBuyUSDTFlowPageNumber(3);
    }
  };

  return (
    <div>
      <div
        className={cx(styles.acceptTerms, {
          [styles.acceptTermsMob]: isMobile,
        })}
      >
        {POPUP_ENUM.acceptTerms}
      </div>
      <div
        className={cx(styles.beforeContinuing, {
          [styles.beforeContinuingMob]: isMobile,
        })}
      >
        {POPUP_ENUM.beforeContinuing}
      </div>
      <div
        className={cx(styles.checklistOne, {
          [styles.checklistOneMob]: isMobile,
        })}
      >
        <div>
          <input
            type="checkbox"
            checked={isCheckedPrivacyAndTerms}
            onChange={handlePrivacyAndTermsCheckbox}
            className={cx(styles["checkbox-input"], {
              [styles.checked]: isCheckedPrivacyAndTerms,
            })}
          />
        </div>
        <div className={styles["checkbox-label"]}>
          I agree to the{" "}
          <a
            href="https://docs.tetherx.co.in/platform/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.termsOfUse}
          >
            terms of use
          </a>{" "}
          and{" "}
          <a
            href="https://docs.tetherx.co.in/platform/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.privacyPolicy}
          >
            privacy policy.
          </a>
        </div>
      </div>
      <div
        className={cx(styles.checklist, {
          [styles.checklistMob]: isMobile,
        })}
      >
        <div>
          <input
            type="checkbox"
            checked={isCheckedTds}
            onChange={handleTdsCheckboxChange}
            className={cx(styles["checkbox-input"], {
              [styles.checked]: isCheckedTds,
            })}
          />
        </div>
        <div className={styles["checkbox-label"]}>
          I also agree to abide by the Finance Bill of 2022 introduced a new
          section called 194S in the Income-Tax Act, 1961, to levy 1% TDS on any
          consideration paid for the transfer of Virtual Digital Assets (VDA).
        </div>
      </div>
      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        {/*custom styled cancel button */}
        <ButtonRade
          className={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={handleClickCancelButton}
        >
          {POPUP_ENUM.cancel}
        </ButtonRade>
        {/*custom styled continue button */}
        <ButtonRade
          className={cx(styles.continueButton, {
            [styles.continueButtonMob]: isMobile,
          })}
          onClick={handleClickContinueButton}
        >
          {POPUP_ENUM.continue}
        </ButtonRade>
      </div>
      {/* error message displaying section */}
      {errorForContinue && (
        <div
          className={cx(styles.errorMessage, {
            [styles.errorMessageMob]: isMobile,
          })}
        >
          {errorForContinue}
        </div>
      )}
      {error && (
        <div
          className={cx(styles.errorMessage, {
            [styles.errorMessageMob]: isMobile,
          })}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default AcceptsTermsToContinuePopUp;
