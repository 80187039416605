import React, { useContext } from "react";
import styles from "./index.module.scss";
import { POPUP_ENUM } from "../../enums/popUpEnum";
import ButtonRade from "../../components/RadeButtons";
import cx from "classnames";
import { AppContext } from "../../context";
import { useNavigate } from "react-router-dom";
import exclamationIcon from "../../assets/exclamationTriangle.svg";
const CompleteKycModal = ({ setModalIsOpen }) => {
  const { isMobile } = useContext(AppContext);
  const navigate = useNavigate();
  // handle the complete kyc button on click
  const handleClickCompleteKycButton = () => {
    setModalIsOpen(false);
    navigate("/profile"); // navigate to profile routes
  };
  const handleClickCancelButton = () => {
    setModalIsOpen(false);
  };
  const CardBody = () => (
    <div>
      {isMobile && (
        <div className={styles.exclamationContainer}>
          <img
            src={exclamationIcon}
            alt="exclamationIcon"
            className={styles.exclamation}
          />
        </div>
      )}
      <div
        className={cx(styles.acceptTerms, {
          [styles.acceptTermsMob]: isMobile,
        })}
      >
        {POPUP_ENUM.completeKycContent}
        {!isMobile && (
          <img
            src={exclamationIcon}
            alt="exclamationIcon"
            className={styles.exclamationIcon}
          />
        )}
      </div>

      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          className={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={handleClickCancelButton}
        >
          {POPUP_ENUM.cancel}
        </ButtonRade>
        <ButtonRade
          className={cx(styles.continueButton, {
            [styles.continueButtonMob]: isMobile,
          })}
          onClick={handleClickCompleteKycButton}
        >
          {POPUP_ENUM.completeKycButton}
        </ButtonRade>
      </div>
    </div>
  );
  return CardBody();
};

export default CompleteKycModal;
