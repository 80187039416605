import React, { useState, useContext } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
import RadioButton from "../../components/RadioButton/RadioButton";
import { SUPPORT_ENUM } from "../../enums/supportEnum";
import Card from "../../components/Card/Card";
import { SEND_ENUM } from "../../enums/sendEnum";
import NextIcon from "../../assets/NextIcon";

const SupportTicketRadioButtons = ({
  userModeSelection,
  setUserModeSelection,
}) => {
  // import the required values from the context
  const { isMobile, setSupportFlowPageNumber } = useContext(AppContext);
  //State to set the error message before proceed if user hasn't selected any options.
  const [error, setError] = useState(false);

  // Support ticket Options
  const issueTypes = [
    { value: "Failed Transaction", label: "Failed Transaction" },
    { value: "Feedback/Bug", label: "Feedback/Bug" },
  ];

  const handleSubmitProceedButton = () => {
    if (!userModeSelection) {
      setError(true);
    } else if (userModeSelection === "Failed Transaction") {
      setError(false);
      setSupportFlowPageNumber(2);
    } else if (userModeSelection === "Feedback/Bug") {
      setError(false);
      setSupportFlowPageNumber(3);
    }
  };

  const handleSubmitCancelButton = () => {
    setSupportFlowPageNumber(-1);
  };
  //Get the value choosen by the user (either Fiat or ETH)
  const handleChangeGettingRadioInput = (e) => {
    setError(false);
    setUserModeSelection(e.target.value);
  };

  const CardTitle = () => (
    <div
      className={cx(styles.supportTicketTitle, {
        [styles.supportTicketTitleMob]: isMobile,
      })}
    >
      {SUPPORT_ENUM.supportTicket}
    </div>
  );
  const CardBody = () => (
    <>
      <div
        className={cx(styles.buttonContentDiv, {
          [styles.buttonContentDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.paymentMethodChoose, {
            [styles.paymentMethodChooseMob]: isMobile,
          })}
        >
          {SUPPORT_ENUM.issueType}
        </div>
        <div
          className={cx(styles.paymentOptionsDiv, {
            [styles.paymentOptionsDivMob]: isMobile,
          })}
        >
          <RadioButton
            name="currency"
            value={userModeSelection}
            options={issueTypes}
            onChangeFunc={handleChangeGettingRadioInput}
            error={error}
          />
        </div>
        <div
          className={cx(styles.proceedButtonDiv, {
            [styles.proceedButtonDivMob]: isMobile,
          })}
        >
          {/* Custom styled cancel button */}
          <ButtonRade
            outline
            customStyling={cx(styles.cancelButton, {
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={handleSubmitCancelButton}
          >
            {SEND_ENUM.cancel}
          </ButtonRade>
          {/* Custom styled button for proceed */}
          <ButtonRade
            outline
            customStyling={cx(styles.proceedButton, {
              [styles.proceedButtonMob]: isMobile,
            })}
            onClick={() => {
              handleSubmitProceedButton();
            }}
          >
            {SEND_ENUM.proceedButton}
            <NextIcon />
          </ButtonRade>
        </div>
      </div>
    </>
  );

  return <Card titleComponent={<CardTitle />}>{CardBody()}</Card>;
};
export default SupportTicketRadioButtons;
