import React, { useContext, useState } from "react";
import { SEND_ENUM } from "../../enums/sendEnum";
import styles from "./index.module.scss";
import cx from "classnames";
import { AppContext } from "../../context";
import GoogleLoginCard from "../../components/GoogleLoginCard/GoogleLoginCard";
import ButtonRade from "../../components/RadeButtons";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { db, auth } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import passwordHideIcon from "../../assets/passwordHideIcon.svg";
import passwordShowIcon from "../../assets/passwordShowIcon.svg";
const EmailPswdSignIn = ({
  setProfileEmail,
  setIsLoggedIn,
  setGoogleLoginFlowPageNumber,
}) => {
  const { isMobile } = useContext(AppContext);
  // state for setting the user entered email
  const [email, setEmail] = useState("");
  // state for setting the user entered password
  const [password, setPassword] = useState("");
  // state for setting the error message if there is any
  const [errorMessage, setErrorMessage] = useState("");
  // State to track sign up mode
  const [isSignUp, setIsSignUp] = useState(false);
  // State to track incorrect sign-in attempt
  const [incorrectAttempt, setIncorrectAttempt] = useState(false);
  // State to track whether password is visible
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // handle on change the user entering email
  const handleOnChangeEmail = (e) => {
    setErrorMessage("");
    setEmail(e.target.value);
  };
  // handle on change the user entering password
  const handleOnChangePassword = (e) => {
    setErrorMessage("");
    setPassword(e.target.value);
  };
  // toggle between sign up and sign in stages
  const toggleMode = () => {
    setIsSignUp(!isSignUp);
    setErrorMessage("");
    setEmail("");
    setPassword("");
  };
  // toggle password visibility
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  // handle on change the sign in and sign up methods
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setErrorMessage("Please enter the email and password to login!");
      return;
    }

    try {
      if (isSignUp) {
        // If in sign up mode, create a new user
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;

        // Create user document in Firestore
        const userRef = doc(db, "userData", user.email);
        await setDoc(userRef, {
          email: user.email,
          firstName: "",
          lastName: "",
          phoneNumber: "",
          KYCStatus: "Not Initialized",
        });

        setIsLoggedIn(true);
        setProfileEmail(user.email);
      } else {
        // If in sign in mode, sign in with existing user
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;

        setIsLoggedIn(true);
        setProfileEmail(user.email);
      }

      // Reset incorrect attempt state on successful sign-in
      setIncorrectAttempt(false);
    } catch (error) {
      console.log("error", error);
      setErrorMessage(error.message);
      // Check for incorrect password error
      if (error.code === "auth/wrong-password") {
        setIncorrectAttempt(true);
      }
    }
  };

  // handle the forgot password method
  const forgotPasswordHandler = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setErrorMessage("Password reset email sent. Please check your inbox.");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };
  // handle on click back button
  const handleOnClickBack = () => {
    setGoogleLoginFlowPageNumber(0);
  };
  const CardTitle = () => (
    <>
      <div
        className={cx(styles.loginTitle, {
          [styles.loginTitleMob]: isMobile,
        })}
      >
        {SEND_ENUM.login}
      </div>
    </>
  );
  const CardBody = () => (
    <div
      className={cx(styles.emailPswdContainer, {
        [styles.emailPswdContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.emailInputField, {
          [styles.emailInputFieldMob]: isMobile,
        })}
      >
        <input
          placeholder="Email"
          type="email"
          value={email}
          onChange={handleOnChangeEmail}
          className={cx(styles.input, {
            [styles.inputWeb]: !isMobile,
            [styles.inputMob]: isMobile,
          })}
        />
      </div>
      <div
        className={cx(styles.paswdInputField, {
          [styles.pswdInputFieldMob]: isMobile,
        })}
      >
        <input
          placeholder="Password"
          type={isPasswordVisible ? "text" : "password"}
          value={password}
          onChange={handleOnChangePassword}
          className={cx(styles.input, {
            [styles.inputWeb]: !isMobile,
            [styles.inputMob]: isMobile,
          })}
        />
        <img
          src={isPasswordVisible ? passwordShowIcon : passwordHideIcon}
          alt="Toggle Password Visibility"
          className={styles.passwordIcon}
          onClick={togglePasswordVisibility}
        />
      </div>
      <div
        className={cx(styles.signInbuttonContainer, {
          [styles.signInbuttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          customStyling={cx(styles.backButton, {
            [styles.backButtonMob]: isMobile,
          })}
          onClick={handleOnClickBack}
        >
          {SEND_ENUM.cancel}
        </ButtonRade>
        <ButtonRade
          customStyling={cx(styles.signInButton, {
            [styles.signInButtonMob]: isMobile,
          })}
          onClick={onSubmit}
        >
          {isSignUp ? SEND_ENUM.signUp : SEND_ENUM.signIn}
        </ButtonRade>
      </div>
      {!isSignUp && !incorrectAttempt && (
        <div
          className={cx(styles.forgotPasswordContainer, {
            [styles.forgotPasswordContainerMob]: isMobile,
          })}
        >
          <ButtonRade
            customStyling={styles.forgotPasswordButton}
            onClick={forgotPasswordHandler}
          >
            {SEND_ENUM.forgotPswd}
          </ButtonRade>
        </div>
      )}
      <div className={styles.toggleText} onClick={toggleMode}>
        {isSignUp ? SEND_ENUM.alreadySignedIn : SEND_ENUM.newUser}
      </div>
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );

  return (
    <div>
      <GoogleLoginCard titleComponent={<CardTitle />}>
        {CardBody()}
      </GoogleLoginCard>
    </div>
  );
};

export default EmailPswdSignIn;
