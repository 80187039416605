import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../../context";
import Card from "../../components/Card/Card";
import styles from "./index.module.scss";
import { SEND_ENUM } from "../../enums/sendEnum";
import DisclaimerLogo from "../../assets/DisclaimerLogo.svg";
import NextIcon from "../../assets/NextIcon";
import RadeButton from "../../components/RadeButtons";
import cx from "classnames";
import Modal from "react-modal";
import AcceptsTermsToContinuePopUp from "../AcceptsTermsToContinuePopUp/AcceptsTermsToContinuePopUp";
import maticLogo from "../../assets/maticLogo.svg";
import SelectBox from "../../components/SelectBox/SelectBox";
import { isAddress } from "ethereum-address";
import { collection, doc, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";

// Custom hook to focus input on div click
const useFocusOnClick = () => {
  const inputRef = useRef(null);

  const handleDivClick = () => {
    inputRef.current.focus();
  };

  return {
    inputRef,
    handleDivClick,
  };
};

const EnterWalletAddress = () => {
  // import the required values from the context
  const { isMobile, profileEmail, setBuyUSDTFlowPageNumber } =
    useContext(AppContext);
  //state to set the wallet address of user
  const [walletAddress, setWalletAddress] = useState("");
  //state to set the modal to open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //state to set selected chain
  const [selectedChain, setSelectedChain] = useState(null);
  //state to set error for proceed
  const [errorForProceed, setErrorForProceed] = useState(false);
  //state to set error for chain selection
  const [errorForDropdown, setErrorForDropdown] = useState(false);
  //state to set error for wallet address
  const [errorForWalletAddress, setErrorForWalletAddress] = useState(false);
  //state to set error for invalid wallet address
  const [invalidWalletAddress, setInvalidWalletAddress] = useState(false);
  // state to store the nickname
  const [nickName, setNickName] = useState("");
  //state to set error for nick name
  const [errorForNickName, setErrorForNickName] = useState(false);
  // state for storing the account details fetch from the firebase
  const [storedWalletAddressDetails, setStoredWalletAddressDetails] = useState(
    []
  );

  // state for storing the account number from selected from the dropdown
  const [selectedWalletAddress, setSelectedWalletAddress] = useState("");
  //handle wallet address and validating the entered address
  const handleWalletAdressChange = (e) => {
    setErrorForProceed(false);
    setInvalidWalletAddress(false);
    setErrorForWalletAddress(false);
    const enteredValue = e.target.value;
    // const numericValue = enteredValue.replace(/\D/g, ""); // Remove non-digit characters
    const limitedValue = enteredValue.slice(0, 42); // Limit to 10 digits

    setWalletAddress(limitedValue);
  };
  // options in the dropdown
  const LoanTermOptions = [{ value: "MATIC20", label: "MATIC20" }];

  // On Selecting the Laon Term dropdown
  const handleSelectChain = () => {
    setErrorForProceed(false);
    setErrorForDropdown(false);
  };
  // handle the error messages on selecting the dropdown
  const dismissError = () => {
    setErrorForProceed(false);
    setErrorForDropdown(false);
    setErrorForWalletAddress(false);
  };
  // handle on click back button
  const handleClickBack = () => {
    setBuyUSDTFlowPageNumber(0);
  };
  // handle nickname input change
  const handleNicknameChange = (e) => {
    setErrorForNickName(false);
    setNickName(e.target.value);
  };

  useEffect(() => {
    // Fetch the stored wallet address details from Firebase
    const fetchWalletAddressDetails = async () => {
      try {
        const userDataRef = doc(firestore, "userData", profileEmail);
        const walletDataRef = collection(
          userDataRef,
          "userWalletAddressDetails"
        );
        // Query the userWalletAddressDetails collection to retrieve all documents
        const querySnapshot = await getDocs(walletDataRef);

        // Create an array to store the retrieved wallet address details
        const walletAddressDetails = [];
        // Iterate over each document in the query snapshot
        querySnapshot.forEach((doc) => {
          // Push the document data to the walletAddressDetails array
          walletAddressDetails.push(doc.data());
        });
        // Set the stored account details in the state
        setStoredWalletAddressDetails(walletAddressDetails);
      } catch (error) {
        console.error(
          "Error fetching wallet address details from Firebase: ",
          error
        );
      }
    };

    // Invoke the fetchWalletAddressDetails function when the profileEmail changes
    fetchWalletAddressDetails();
  }, [profileEmail]);

  const handleAccountSelection = (walletAddress) => {
    // Find the selected account details
    const selectedWalletDetails = storedWalletAddressDetails.find((wallet) => {
      return wallet.walletAddress === walletAddress.value.toString();
    });
    // Update the state values with the selected wallet details
    if (selectedWalletDetails) {
      setNickName(selectedWalletDetails.nickName);
      setWalletAddress(selectedWalletDetails.walletAddress);
      setSelectedChain(selectedWalletDetails.selectedChain);
    }
  };

  // handle on click proceed button
  const handleClickProceed = async () => {
    if (!walletAddress && !selectedChain && !nickName) {
      setErrorForProceed(true);
    } else if (!nickName) {
      setErrorForNickName(true);
    } else if (walletAddress && !selectedChain) {
      setErrorForProceed(false);
      setErrorForDropdown(true);
    } else if (!walletAddress && selectedChain) {
      setErrorForProceed(false);
      setErrorForWalletAddress(true);
    } else if (!isAddress(walletAddress)) {
      setInvalidWalletAddress(true);
    } else {
      setModalIsOpen(true);
    }
  };

  const { inputRef: inputRef1, handleDivClick: handleDivClick1 } =
    useFocusOnClick();
  const { inputRef: inputRef2, handleDivClick: handleDivClick2 } =
    useFocusOnClick();

  const CardTitle = () => (
    <div
      className={cx(styles.enterWalletAddress, {
        [styles.enterWalletAddressMob]: isMobile,
      })}
    >
      {SEND_ENUM.enterWalletAddress}
    </div>
  );
  const CardBody = () => (
    <div
      className={cx(styles.walletAddressMainDiv, {
        [styles.walletAddressMainDivMob]: isMobile,
      })}
    >
      <div className={styles.accountDropdownContainer}>
        <div className={styles.accountDropdownLabel}>
          {SEND_ENUM.accountDetailsDropdown}
        </div>

        {storedWalletAddressDetails.length !== 0 && (
          <div className={styles.accountDropdownValue}>
            <div className={styles.accountDropdownValue}>
              <SelectBox
                placeHolder="Select a nick name"
                options={storedWalletAddressDetails.map((account) => ({
                  label: account.nickName,
                  value: account.walletAddress,
                  key: account.walletAddress,
                }))}
                onChange={handleAccountSelection}
                setSelectedValue={setSelectedWalletAddress}
                selectedValue={selectedWalletAddress}
                errorDismissOnclick={dismissError}
              />
            </div>
          </div>
        )}
      </div>
      <div
        className={cx(styles.fillWalletAddress, {
          [styles.fillWalletAddressMob]: isMobile,
        })}
        onClick={handleDivClick1} // Attach the click event here
      >
        <div
          className={cx(styles.walletAddressKey, {
            [styles.walletAddressKeyMob]: isMobile,
          })}
        >
          {SEND_ENUM.nickName}
        </div>
        <div
          className={cx(styles.nickNameValue, {
            [styles.nickNameValueMob]: isMobile,
          })}
        >
          <input
            ref={inputRef1} // Attach the ref to the input element
            type="text"
            placeholder="Enter the nick name for wallet address"
            value={nickName}
            onChange={handleNicknameChange}
            autoFocus
            autoComplete="off"
            className={cx(styles.nickName, {
              [styles.nickNameMob]: isMobile,
            })}
          />
        </div>
      </div>
      <div
        className={cx(styles.fillWalletAddress, {
          [styles.fillWalletAddressMob]: isMobile,
        })}
        onClick={handleDivClick2} // Attach the click event here
      >
        <div
          className={cx(styles.walletAddressKey, {
            [styles.walletAddressKeyMob]: isMobile,
          })}
        >
          {SEND_ENUM.walletAddress}
        </div>
        <div
          className={cx(styles.walletAddressValue, {
            [styles.walletAddressValueMob]: isMobile,
          })}
        >
          <input
            ref={inputRef2} // Attach the ref to the input element
            type="text"
            value={walletAddress}
            onChange={handleWalletAdressChange}
            autoComplete="off"
            className={styles.inputValue}
          />
        </div>
      </div>
      {/*dropdown section*/}
      <div className={styles.selectDiv}>
        <SelectBox
          placeHolder="Select Chain"
          options={LoanTermOptions}
          setSelectedValue={setSelectedChain}
          selectedValue={selectedChain}
          onChange={handleSelectChain}
          errorDismissOnclick={dismissError}
        />
      </div>
      <div className={styles.infoDiv}>
        <div
          className={cx(styles.attentionDiv, {
            [styles.attentionDivMob]: isMobile,
          })}
        >
          <img src={DisclaimerLogo} alt="disclaimer-logo" />
          <span
            className={cx(styles.attention, {
              [styles.attentionMob]: isMobile,
            })}
          >
            {SEND_ENUM.attention}
          </span>
        </div>
        <div
          className={cx(styles.note, {
            [styles.noteMob]: isMobile,
          })}
        >
          {SEND_ENUM.disclaimerNote}
        </div>
        <div
          className={cx(styles.purchaseDiv, {
            [styles.purchaseDivMob]: isMobile,
          })}
        >
          <div className={styles.yourPurchase}>{SEND_ENUM.yourPurchase}</div>
          <div className={styles.maticImgandText}>
            <img src={maticLogo} alt="matic_logo"></img>
            <span className={styles.matic}>{SEND_ENUM.usdtOnMatic}</span>
          </div>
        </div>
      </div>
      {/* error message displaying section */}
      {errorForProceed && (
        <div
          className={cx(styles.errorMessage, {
            [styles.errorMessageMob]: isMobile,
          })}
        >
          {SEND_ENUM.proceedError}
        </div>
      )}
      {errorForDropdown && (
        <div
          className={cx(styles.errorMessage, {
            [styles.errorMessageMob]: isMobile,
          })}
        >
          {SEND_ENUM.dropdownError}
        </div>
      )}
      {errorForWalletAddress && (
        <div
          className={cx(styles.errorMessage, {
            [styles.errorMessageMob]: isMobile,
          })}
        >
          {SEND_ENUM.walletAddressError}
        </div>
      )}
      {invalidWalletAddress && (
        <div
          className={cx(styles.errorMessage, {
            [styles.errorMessageMob]: isMobile,
          })}
        >
          {SEND_ENUM.invalidWalletAddress}
        </div>
      )}
      {errorForNickName && (
        <div
          className={cx(styles.errorMessage, {
            [styles.errorMessageMob]: isMobile,
          })}
        >
          {SEND_ENUM.errorForNickName}
        </div>
      )}
      <div
        className={cx(styles.proceedButtonDiv, {
          [styles.proceedButtonDivMob]: isMobile,
        })}
      >
        {/*custom styled back button */}
        <RadeButton
          onClick={handleClickBack}
          customStyling={cx(styles.backButton, {
            [styles.backButtonMob]: isMobile,
          })}
        >
          {SEND_ENUM.back}
        </RadeButton>
        {/*custom styled proceed button */}
        <RadeButton
          onClick={handleClickProceed}
          customStyling={cx(styles.proceedButton, {
            [styles.proceedButtonMob]: isMobile,
          })}
        >
          {SEND_ENUM.proceedButton}
          <NextIcon />
        </RadeButton>
      </div>
    </div>
  );
  return (
    <>
      <div
        className={cx(styles.cardMainDiv, {
          [styles.cardMainDivMob]: isMobile,
        })}
      >
        <Card titleComponent={<CardTitle />}> {CardBody()} </Card>
      </div>
      {/* Code for opening the modal */}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <AcceptsTermsToContinuePopUp
          setModalIsOpen={setModalIsOpen}
          walletAddress={walletAddress}
          selectedChain={selectedChain}
          nickName={nickName}
        />
      </Modal>
    </>
  );
};
export default EnterWalletAddress;
