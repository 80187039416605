import React from "react";
import { useTimer } from "react-timer-hook";

function Timer({ expiryTimestamp, onTimerExpire }) {
  const { minutes, seconds } = useTimer({
    expiryTimestamp,
    onExpire: onTimerExpire,
  });
  return (
    <div
      style={{ textAlign: "center", fontFamily: "Roboto", fontSize: "20px" }}
    >
      <span>
        {minutes}:{seconds < 10 ? "0" : ""}
        {seconds}
      </span>
    </div>
  );
}
export default Timer;
