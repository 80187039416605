export const POPUP_ENUM = {
  acceptTerms: "Accept Terms to Continue",
  beforeContinuing:
    "Before continuing to use our services, please read our terms of use agreement and privacy policy.",
  cancel: "Cancel",
  continue: "Continue",
  areYouSure:
    "You will not be able to change your details until the verification process ends. Are you sure you want to submit?",
  checkBoxError:
    "Please agree to the terms of use and privacy policy to continue",
  submit: "Submit",
  completeKycButton: "Complete KYC",
  completeKycContent: "Please complete KYC to proceed",
  addAccountConfirm: "Click submit to add your bank account details",
  connectMetamask:
    "Your wallet is not connected. Please connect your metamask wallet.",
  connect: "Connect",
  serviceTicketSubmitted:
    "Service ticket has been successfully created. Please be patient while we fix your issue.",
  close: "Close",
  serviceTicketSubmittedTitle: "Request Submitted",
};
