import React, { useState, useEffect } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import { useContext } from "react";
import { PROFILE_ENUM } from "../../enums/profileEnum";
import styles from "./index.module.scss";
import KYC from "../Kyc/Kyc";
import Card from "../../components/Card/Card";
import { getDoc, doc, collection } from "firebase/firestore";
import { db } from "../../firebase";
const KycLandingPage = () => {
  const { isMobile, profileEmail, setKYCPageNumber } = useContext(AppContext);

  useEffect(() => {
    async function fetchKYCStatus() {
      const usersRef = collection(db, "userData");
      const userDocRef = doc(usersRef, profileEmail);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const kYCStatus = userData.KYCStatus;

        if (kYCStatus !== "Not Initialized") {
          setKYCPageNumber(1);
        } else {
          setKYCPageNumber(-1);
        }
      }
    }
    fetchKYCStatus();
  }, []);
  const CardTitle = () => (
    <div
      className={cx(styles.profileButtonDiv, {
        [styles.profileButtonDivMob]: isMobile,
      })}
    >
      {PROFILE_ENUM.profile}
    </div>
  );

  return (
    <div
      className={cx(styles.buttonTableDiv, {
        [styles.buttonTableDivMob]: isMobile,
      })}
    >
      <Card titleComponent={<CardTitle />}>
        <KYC />
      </Card>
    </div>
  );
};
export default KycLandingPage;
