export const SEND_ENUM = {
  enterAmountOfUSDWishToSend: "Enter the amount you wish to buy. ",
  youSend: "You need",
  RUD: "RUD",
  recipientGets: "You pay",
  INR: "INR",
  proceedButton: "Proceed",
  newToApp: "New to ",
  XXX: " Payezy?",
  learnMore: "Learn more",
  USD: "USD",
  login: "Login",
  loginUsingGoogleAccount: "Log in quickly using one of the following options.",
  continueWithGoogle: "Google",
  fullName: "Full Name",
  phone: "Phone",
  confirmAccountNumber: "Confirm Account Number",
  IFSCCode: "IFSC Code",
  enterTheDetailsOfReciep: "Enter the details of the recipient.",
  transactionSuccesfull: "Transaction Successful",
  pleaseBePatient:
    "Please be patient. Recipient will receive your funds in the next 48 hours. ",
  viewStatus: "Check Status",
  yourRecentTransaction: "Your Recent Transactions ",
  ID: "ID",
  date: "Date",
  amount: "Amount",
  recipient: "Recipient",
  receivers: "Receives (INR)",
  accNum: "Account Number",
  status: "Status",
  pleaseLoginToContinue: "Please login to continue!",
  enterWalletAddress: "Enter your wallet address",
  walletAddress: "Wallet Address",
  attention: "Attention",
  disclaimerNote:
    "Make sure the wallet address you enter is of the same coin and it’s respective chain.",
  yourPurchase: "Your Purchase",
  usdtOnMatic: "USDT on MATIC20",
  proceedError: "Please enter the required details!",
  walletAddressError: "Please enter the wallet address!",
  dropdownError: "Please select the chain!",
  invalidWalletAddress: "Invalid wallet address!",
  paymentVerification: "Verifying your payment. Please wait..",
  transactionInProgress: "Payment Verified. Transaction in progress..",
  paymentMethodTitle: "Open any UPI app and pay",
  scanQR: "Scan QR & pay",
  payUPI: "Pay UPI ID",
  upiId: "9567511644@okbizaxis",
  enterTransactionId: "Enter UPI Transaction ID",
  transactionNote:
    "Transaction verification will fail if you do not enter UPI transaction ID of your payment.",
  checkBoxNote:
    "After paying in UPI App, mark checkbox and click “I have paid” below.",
  paid: "I have paid",
  cancel: "Cancel",
  paidButton: "I HAVE PAID",
  checkBoxError: "Please check 'I have paid'",
  transactionIdError: "Please enter the UPI transaction ID!",
  success: "Success",
  failed: "Failed",
  chain: "Chain",
  orderId: "Order ID",
  fee: "Fee",
  destination: "Destination",
  explorerButton: "View on explorer",
  walletCheck:
    "Your USDT purchase order has been initiated. Please wait for some time and check purchase history to know the status of your order.",
  purchaseHistory: "Purchase History",
  back: "Back",
  remainingTime: "Time remaining",
  invalidTransactionId: "Invalid UPI transaction ID!",
  logoutMessage: "Are you sure you want to Logout? ",
  logout: "Logout",
  TxOnChainId: "Transaction OnChain ID",
  nickName: "Nick Name (Eg: My metamask wallet)",
  nickNameAddCheckbox:
    "Save this wallet address under a nickname for quick access",
  errorForNickName: "Please enter the nick name for the wallet address!",
  errorForRadioButton: "Select an option to proceed!",
  choosePaymentMethod: "Choose your payment method.",
  selectPaymentMethod: "Select Payment Method",
  netBanking: "Net Banking",
  payViaAxisBank: "Pay only via Axis Bank XXXX XXXX 0317",
  netBankingIMPS: "Net Banking - IMPS",
  sendFunds: "Open your banking app/web to send funds via",
  limit: "Limit:",
  fees: "Fees:",
  processingTime: "Processing time:",
  limitValue: "Min. ₹10,000 - Max. ₹5,00,000",
  feesValue: "0%",
  processingTimeValue: "Upto 2-12 hours after transfer",
  continue: "Continue",
  addBankAccount: "Add Bank Account",
  bankAccountContent:
    "Add your bank account details to enable NEFT based payment method.",
  bankRelatedTo: "BANK ACCOUNT SHOULD BELONG TO",
  accountNumber: "Account Number",
  reEnterAccountNumber: "Re-enter Account Number",
  bankAccountAlert:
    "The above bank account must belong to you. Any other bank account will be rejected.",
  impsRefund: "Transfer from any other account will be refunded in 15 days",
  impsfundReverse:
    "Fund transfer via UPI apps will be auto reversed to your bank account",
  impsUniqueCode:
    "Please enter 9826351655 as remarks while transferring for quicker payment verification",
  impsTransferModes:
    "Use only your bank app/website for transfer. You cannot transfer money from other modes like NEFT/RTGS/Wallet/Credit Card/Rental",
  purchaseInrt: "Purchase INRT",
  InrtPurchaseContent: "Enter the amount of INRT you want to purchase.",
  get: "You Get",
  pay: "You Pay",
  purchaseUSDT: "Purchase USDT",
  purchaseUsdtContent: "Enter the amount of USDT you want to purchase.",
  accountVerifiedContent: "Your bank account is verified.",
  bankTransferImps: "Bank Transfer-IMPS",
  accountNumberValue: "200001504308",
  bankName: "Bank Name",
  bankNameValue: "Equitas Small Finance Bank",
  remarks: "Remarks/Transaction Note/Message",
  remarksValue: "9826351655",
  IfscCodeValue: "ESFB0003003",
  recipientName: "Beneficiary/Recipient Name",
  recipientNameValue: "NTPL",
  accountTypeValue: "Current",
  accountType: "Account Type",
  payAmountViaImps:
    "Proceed to pay amount shown below using netbanking via IMPS.",
  impsTransactionNote:
    "Note : Do not forget to mention your phone number as remarks/transaction note/message.",
  inrtWalletCheckNote: "Check your wallet for INRT deposit.",
  processingButton: "Processing",
  comingSoon: "Coming Soon",
  paymentInitButton: "Initiated",
  paymentReceivedButton: "Awaiting settlement",
  loginToContinue: "Please login to continue",
  noPurchases: "You have no purchases to display.",
  refresh: "Refresh",
  transactionInitiated: "Transaction Initiated",
  tdsNote:
    "As per revised Income Tax regulations, the 1% TDS will be applicable on transfer of Virtual Digital Assets. Net amount includes TDS, which you may claim upon filing.",
  continueWithFacebook: "Meta",
  continueWithTwitter: "Sign in with",
  emailAndpswdLogin: "Continue with Email/Password",
  alreadySignedIn: "Already have an account? Sign In",
  newUser: "New User? Sign Up",
  forgotPswd: "Forgot Password?",
  signUp: "Sign Up",
  signIn: "Sign In",
  loginWithSocialAccounts: "Log in quickly using one of your social accounts",
  or: "Or",
};
