import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import GoogleLoginCard from "../../components/GoogleLoginCard/GoogleLoginCard";
import { SEND_ENUM } from "../../enums/sendEnum";
import googleIcon from "../../assets/googleIcon.svg";
import {
  auth,
  googleProvider,
  db,
  facebookProvider,
  twitterProvider,
} from "../../firebase";
import {
  signInWithPopup,
  linkWithCredential,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";
import styles from "./index.module.scss";
import "./firebaseui-styling.global.css";
import { collection, getDoc, doc, setDoc } from "firebase/firestore";
import MetaIcon from "../../assets/metaIcon.svg";
import xIcon from "../../assets/xIcon.svg";

const LoginOptions = ({
  setProfileEmail,
  setIsLoggedIn,
  setGoogleLoginFlowPageNumber,
  errorMessage,
  setErrorMessage,
  hideFacebookButton,
  setHideFacebookButton,
  hideTwitterButton,
  setHideTwitterButton,
}) => {
  const { isMobile, setLoading } = useContext(AppContext);

  useEffect(() => {
    return () => {
      console.log("Login Options unmounted");
    };
  }, []);

  // internal function for retrieving the stored pendingCredential in local storage
  const retrievePendingCred = () => {
    const pendingCredential = localStorage.getItem("pendingCredential");
    // remove localstorage item
    localStorage.removeItem("pendingCredential");
    return pendingCredential;
  };

  // Function to reset states after successful login
  const resetStates = () => {
    setErrorMessage("");
    setHideFacebookButton(false);
    setHideTwitterButton(false);
  };
  // Login function using google firbase auth
  const loginWithGoogle = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("result", result);
      const { user } = result;
      // call the retrievePendingCred function and get the error from PendingCred
      let errorFromPendingCred = retrievePendingCred();
      // parse the retrieved error from PendingCred
      const parsedErrorFromPendingCred = JSON.parse(errorFromPendingCred);
      if (errorFromPendingCred !== null) {
        let pendingCred;
        // check from which provider (facebook or twitter) the error is generated
        if (
          parsedErrorFromPendingCred.customData._tokenResponse.providerId ===
          "facebook.com"
        ) {
          // Facebook provider
          pendingCred = FacebookAuthProvider.credentialFromError(
            parsedErrorFromPendingCred
          );
          // As you have access to the pending credential, you can directly call the
          // link method.
          let user = await linkWithCredential(result.user, pendingCred);
          console.log("userInPendingCredFacebook", user);
        } else if (
          parsedErrorFromPendingCred.customData._tokenResponse.providerId ===
          "twitter.com"
        ) {
          // Twitter provider
          pendingCred = TwitterAuthProvider.credentialFromError(
            parsedErrorFromPendingCred
          );
          // As you have access to the pending credential, you can directly call the
          // link method.
          let user = await linkWithCredential(result.user, pendingCred);
          console.log("userInPendingCredTwitter", user);
        }
      }
      setProfileEmail(user.email);
      setIsLoggedIn(true);

      // Check if user email exists in Firestore
      const usersRef = collection(db, "userData");
      const documentId = user.email; // email for the logged in user, to check if exists
      const userDocRef = doc(usersRef, documentId);
      try {
        const documentSnapshot = await getDoc(userDocRef);
        if (documentSnapshot.exists()) {
          // Email exist in Firestore
          console.log("User logged in already");
        } else {
          // Email does not exist in Firestore
          console.log("new user");
          // Add email, firstName, lastName, and phoneNumber to userData collection
          const userDocRef = doc(usersRef, user.email); // Use email as the document ID
          await setDoc(userDocRef, {
            email: user.email,
            displayName: user.displayName,
            firstName: "",
            lastName: "",
            phoneNumber: "",
            KYCStatus: "Not Initialized",
          });
          setIsLoggedIn(true);
        }

        setLoading(false);
        resetStates(); // Reset states after successful login
      } catch (error) {
        console.error("Error getting document:", error);
      }
    } catch (error) {
      setLoading(false);
      console.log("Login Failed:", error);
    }
  };
  // Login function using facebook firbase auth
  const loginWithFacebook = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      console.log("result1", result);
      const { user } = result;

      try {
        // Check if user email exists in useData collection in firestore
        const docRef = doc(db, "userData", user.email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Email exist in Firestore
          console.log("User logged in already");
        } else {
          // Email does not exist in Firestore
          console.log("New user");
          // Add email, firstName, lastName, and phoneNumber to userData collection
          await setDoc(docRef, {
            email: user.email,
            displayName: user.displayName,
            firstName: "",
            lastName: "",
            phoneNumber: "",
            KYCStatus: "Not Initialized",
          });
        }

        setIsLoggedIn(true);
        setProfileEmail(user.email);
        setLoading(false);
        resetStates(); // Reset states after successful login
      } catch (error) {
        console.error("Error getting document:", error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.code === "auth/account-exists-with-different-credential") {
        // store pending cred into local storage
        localStorage.setItem("pendingCredential", JSON.stringify(error));
        setErrorMessage(
          "User account exists with a different credential. Please try logging in by using any other provider."
        );
        // Hide the Facebook button
        setHideFacebookButton(true);
        console.log("User account exists with a different credential");
      } else {
        console.log("Login Failed:", error);
      }
    }
  };
  // Login function using twitter firbase auth
  const loginWithTwitter = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, twitterProvider);
      console.log("result2", result);
      const { user } = result;

      try {
        // Check if user email exists in useData collection in firestore
        const docRef = doc(db, "userData", user.email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Email exist in Firestore
          console.log("User logged in already");
        } else {
          // Email does not exist in Firestore
          console.log("New user");
          // Add email, firstName, lastName, and phoneNumber to userData collection
          await setDoc(docRef, {
            email: user.email,
            displayName: user.displayName,
            firstName: "",
            lastName: "",
            phoneNumber: "",
            KYCStatus: "Not Initialized",
          });
        }

        setIsLoggedIn(true);
        setProfileEmail(user.email);
        setLoading(false);
        resetStates(); // Reset states after successful login
      } catch (error) {
        console.error("Error getting document:", error);
        setLoading(false);
      }
    } catch (error) {
      console.log("errorMessage", error.message);
      setLoading(false);
      if (error.code === "auth/account-exists-with-different-credential") {
        // store pending cred into local storage
        localStorage.setItem("pendingCredential", JSON.stringify(error));
        setErrorMessage(
          "User account exists with a different credential. Please try logging in by using any other provider."
        );
        console.log("User account exists with a different credential");
        // Hide the Twitter button
        setHideTwitterButton(true);
      } else {
        console.log("Login Failed:", error);
      }
    }
  };
  const loginWithEmailAndPassword = async () => {
    setGoogleLoginFlowPageNumber(1);
  };
  // Component for the card title
  const CardTitle = () => (
    <>
      <div
        className={cx(styles.loginTitle, {
          [styles.loginTitleMob]: isMobile,
        })}
      >
        {SEND_ENUM.login}
      </div>
    </>
  );
  // Component for the card body
  const CardBody = () => (
    <div className={styles.bodyContainer}>
      {/* Button to login with email/password */}
      <div className={styles.emailPswdButtonContainer}>
        <button
          className={cx(styles.continueWithGoogleButton, {
            [styles.continueWithGoogleButtonMob]: isMobile,
          })}
          onClick={() => loginWithEmailAndPassword()}
        >
          {SEND_ENUM.emailAndpswdLogin}
        </button>
      </div>
      <div className={styles.orContainer}>{SEND_ENUM.or}</div>
      <div className={styles.loginUsingGoogleAccount}>
        {SEND_ENUM.loginWithSocialAccounts}
      </div>

      <div className={styles.continueWithGoogleButtonContainer}>
        {/* Button to login with Google */}
        <button
          className={cx(styles.continueWithGoogleButton, {
            [styles.continueWithGoogleButtonMob]: isMobile,
          })}
          onClick={() => loginWithGoogle()}
        >
          <img src={googleIcon} alt="google" className={styles.googleIcon} />
          {SEND_ENUM.continueWithGoogle}
        </button>
        {/* Button to login with Facebook */}
        {!hideFacebookButton && (
          <button
            className={cx(styles.continueWithGoogleButton, {
              [styles.continueWithGoogleButtonMob]: isMobile,
            })}
            onClick={() => loginWithFacebook()}
          >
            <img src={MetaIcon} alt="MetaIcon" className={styles.metaIcon} />
            {SEND_ENUM.continueWithFacebook}
          </button>
        )}

        {/* Button to login with Twitter */}
        {!hideTwitterButton && (
          <button
            className={cx(styles.continueWithGoogleButton, {
              [styles.continueWithGoogleButtonMob]: isMobile,
            })}
            onClick={() => loginWithTwitter()}
          >
            {SEND_ENUM.continueWithTwitter}
            <img src={xIcon} alt="xIcon" className={styles.xIcon} />
          </button>
        )}

        {/* Display error message if there is one */}
        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <GoogleLoginCard titleComponent={<CardTitle />}>
        {CardBody()}
      </GoogleLoginCard>
    </div>
  );
};

export default LoginOptions;
