import React, { useContext } from "react";
import styles from "./index.module.scss";
import { POPUP_ENUM } from "../../enums/popUpEnum";
import ButtonRade from "../../components/RadeButtons";
import cx from "classnames";
import { AppContext } from "../../context";

const ConfirmUpdateProfile = ({
  confirmUpdateModalOpen,
  setConfirmUpdateModalOpen,
  setKYCPageNumber,
  handlesubmitUpdateProfile,
  modalIsOpen,
  setModalIsOpen,
}) => {
  const { isMobile } = useContext(AppContext);
  const handleClickCancelButton = () => {
    setConfirmUpdateModalOpen(false);
  };
  const handleClickConfirmButton = () => {
    if (confirmUpdateModalOpen) {
      setConfirmUpdateModalOpen(false);
    }
    if (modalIsOpen) {
      setModalIsOpen(false);
    }
    handlesubmitUpdateProfile();
    setKYCPageNumber(1);
  };
  const CardBody = () => (
    <div>
      <div
        className={cx(styles.acceptTerms, {
          [styles.acceptTermsMob]: isMobile,
        })}
      >
        {POPUP_ENUM.areYouSure}
      </div>

      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          className={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={handleClickCancelButton}
        >
          {POPUP_ENUM.cancel}
        </ButtonRade>
        <ButtonRade
          className={cx(styles.continueButton, {
            [styles.continueButtonMob]: isMobile,
          })}
          onClick={handleClickConfirmButton}
        >
          {POPUP_ENUM.submit}
        </ButtonRade>
      </div>
    </div>
  );
  return CardBody();
};

export default ConfirmUpdateProfile;
