import React, { useContext, useState, useEffect } from "react";
import styles from "./index.module.scss";
import { SEND_ENUM } from "../../enums/sendEnum";
import INDLogo from "../../assets/INDLogo.svg";
import USDLogo from "../../assets/maticLogo.svg";
import ButtonRade from "../../components/RadeButtons";
import CrossIcon from "../../assets/crossIcon";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context";
import cx from "classnames";
import { collection, doc, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";

const TransactionSuccessfulModal = ({ setModalIsOpen, paymentTxDetails }) => {
  const { isMobile, profileEmail } = useContext(AppContext);
  let navigate = useNavigate();
  const closeTransactionsuccessfulWindow = () => {
    setModalIsOpen(false);
    navigate("/purchases", {
      state: { selectedOrderId: paymentTxDetails.order_id }, // pass the order id along with the click
    }); //navigate to purchases
  };
  //  state for storing the wallet details
  const [walletDetails, setWalletDetails] = useState([]);
  // fetch the wallet details from firebase
  async function fetchUserDetails() {
    const userDataRef = doc(firestore, "userData", profileEmail);
    const walletDetailsRef = collection(
      userDataRef,
      "userWalletAddressDetails"
    );
    const querySnapshot = await getDocs(walletDetailsRef);
    const walletDetailsArray = [];
    querySnapshot.forEach((doc) => {
      const walletDetail = doc.data();
      walletDetailsArray.push({
        ...walletDetail,
        recipientWalletID: doc.id,
      });
    });

    // Store the account details in component state
    setWalletDetails(walletDetailsArray);

    // Process the retrieved data as needed
  }
  useEffect(() => {
    fetchUserDetails();
  }, []);
  // Function to convert the epoch time
  function formatEpochTime(epochTime) {
    const date = new Date(epochTime);

    // Define the desired date and time formats
    const dateFormat = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };

    const timeFormat = {
      hour: "2-digit",
      minute: "2-digit",
    };

    // Get the date and time strings in the desired format
    const dateStr = date.toLocaleDateString("en-US", dateFormat);
    const timeStr = date.toLocaleTimeString("en-US", timeFormat);

    // Combine the date and time strings
    const formattedTime = `${dateStr} ${timeStr}`;

    return formattedTime;
  }

  return (
    <div className={styles.transactionSuccessfulMainDiv}>
      <div className={styles.transactionSuccessfulContent}>
        <div
          className={cx(styles.transactionSuccessful, {
            [styles.transactionSuccessfulMob]: isMobile,
          })}
        >
          {paymentTxDetails.tx_status === "payment_received" &&
            SEND_ENUM.transactionSuccesfull}
          {(paymentTxDetails.tx_status === "created" ||
            paymentTxDetails.tx_status === "payment_init") &&
            SEND_ENUM.transactionInitiated}
          <div className={styles.button}>
            <button
              className={cx(styles.crossButton, {
                [styles.crossButtonMob]: isMobile,
              })}
              onClick={closeTransactionsuccessfulWindow}
            >
              <CrossIcon />
            </button>
          </div>
        </div>
      </div>
      <div
        className={cx(styles.INDRsDiv, {
          [styles.INDRsDivMob]: isMobile,
        })}
      >
        <div className={styles.firstFlex}>
          <img src={INDLogo} alt="IND-logo" />
          <div
            className={cx(styles.INDRs, {
              [styles.INDRsMob]: isMobile,
            })}
          >
            {paymentTxDetails.inr_amount} INR
          </div>
        </div>
        <div
          className={cx(styles.dateAndTime, {
            [styles.dateAndTimeMob]: isMobile,
          })}
        >
          {formatEpochTime(Number(paymentTxDetails.created_at))}
        </div>
      </div>
      <div
        className={cx(styles.USDTDiv, {
          [styles.USDTDivMob]: isMobile,
        })}
      >
        <div className={styles.firstFlex}>
          <img src={USDLogo} alt="USD-logo" />
          <div
            className={cx(styles.USDT, {
              [styles.USDTMob]: isMobile,
            })}
          >
            {paymentTxDetails.usdt_amount} USDT
          </div>
        </div>
        <div className={styles.successButtonDiv}>
          {paymentTxDetails.tx_status === "delivery_complete" && (
            <ButtonRade
              customStyling={cx(styles.statusButtonSuccess, {
                [styles.statusButtonSuccessMob]: isMobile,
              })}
            >
              {SEND_ENUM.success}
            </ButtonRade>
          )}
          {paymentTxDetails.tx_status === "payment_rejected" && (
            <ButtonRade
              customStyling={cx(styles.statusButtonFailed, {
                [styles.statusButtonFailedMob]: isMobile,
              })}
            >
              {SEND_ENUM.failed}
            </ButtonRade>
          )}

          {(paymentTxDetails.tx_status === "created" ||
            paymentTxDetails.tx_status === "payment_init") && (
            <ButtonRade
              customStyling={cx(styles.statusButtonProcessing, {
                [styles.statusButtonProcessingMob]: isMobile,
              })}
            >
              {SEND_ENUM.paymentInitButton}
            </ButtonRade>
          )}
          {paymentTxDetails.tx_status === "processing" && (
            <ButtonRade
              customStyling={cx(styles.statusButtonProcessing, {
                [styles.statusButtonProcessingMob]: isMobile,
              })}
            >
              {SEND_ENUM.processingButton}
            </ButtonRade>
          )}
          {paymentTxDetails.tx_status === "payment_received" && (
            <ButtonRade
              customStyling={cx(styles.statusButtonProcessing, {
                [styles.statusButtonProcessingMob]: isMobile,
              })}
            >
              {SEND_ENUM.paymentReceivedButton}
            </ButtonRade>
          )}
        </div>
      </div>
      <div
        className={cx(styles.transactionDetailsDiv, {
          [styles.transactionDetailsDivMob]: isMobile,
        })}
      >
        <div className={styles.chainDetails}>
          <div
            className={cx(styles.key, {
              [styles.keyMob]: isMobile,
            })}
          >
            {SEND_ENUM.chain}
          </div>
          <div
            className={cx(styles.value, {
              [styles.valueMob]: isMobile,
            })}
          >
            MATIC20
          </div>
        </div>
        {/** To be implemented later */}
        {/* <div className={styles.feeDetails}>
          <div
            className={cx(styles.key, {
              [styles.keyMob]: isMobile,
            })}
          >
            {SEND_ENUM.fee}
          </div>
          <div
            className={cx(styles.value, {
              [styles.valueMob]: isMobile,
            })}
          >
            0.14 USDT
          </div>
        </div> */}
      </div>
      <div
        className={cx(styles.orderDetails, {
          [styles.orderDetailsMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.key, {
            [styles.keyMob]: isMobile,
          })}
        >
          {SEND_ENUM.orderId}
        </div>
        <div
          className={cx(styles.value, {
            [styles.valueMob]: isMobile,
          })}
        >
          {paymentTxDetails.order_id}
        </div>
      </div>
      <div
        className={cx(styles.accountDetails, {
          [styles.accountDetailsMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.destination, {
            [styles.destinationMob]: isMobile,
          })}
        >
          {SEND_ENUM.destination}
        </div>
        {walletDetails.find(
          (address) =>
            address.recipientWalletID === paymentTxDetails.receiver_id
        )?.walletAddress && (
          <div
            className={cx(styles.address, {
              [styles.addressMob]: isMobile,
            })}
          >
            {
              walletDetails.find(
                (address) =>
                  address.recipientWalletID === paymentTxDetails.receiver_id
              ).walletAddress
            }
          </div>
        )}
      </div>
      <div
        className={cx(styles.walletCheck, {
          [styles.walletCheckMob]: isMobile,
        })}
      >
        {SEND_ENUM.walletCheck}
      </div>
    </div>
  );
};

export default TransactionSuccessfulModal;
