import React, { useContext } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import { PROFILE_ENUM } from "../../enums/profileEnum";
import ButtonRade from "../../components/RadeButtons";
import styles from "./index.module.scss";
import ConfirmUpdateProfile from "../ConfirmUpdateProfile/ConfirmUpdateProfile";
import Modal from "react-modal";

import ProfileDetailsFetch from "./ProfileDetailsFetch";
import { SEND_ENUM } from "../../enums/sendEnum";

const Profile = ({
  reSubmitKyc,
  setResubmitKyc,
  firstName,
  lastName,
  phoneNumber,
  email,
  handleChangeInputFieldFirstName,
  handleChangeInputFieldLastName,
  handlePhoneNumberChange,
  setErrorForID,
  panCard,
  setPanCard,
  aadharCard,
  setAadharCard,
  handleChangeInputFieldEmail,
  setErrorForPancard,
  setErrorForAadhar,
  handlesubmitUpdateProfile,
  invalidEmail,
  errorForProfileUpdate,
  invalidNumber,
  errorForEmail,
  errorForNumber,
  error,
  errorForID,
  updationSuccessful,
  modalIsOpen,
  setModalIsOpen,
  setUpdationSuccesful,
  errorForPancard,
  errorForAadhar,
  errorForFirstName,
  errorForLastName,
  isFileUploaded,
  setIsFileUploaded,
  pancardFileType,
  setPancardFileType,
  aadharFileType,
  setAadharFileType,
  aadharHolding,
  setAadharHolding,
  errorForAadharHold,
  setErrorForAadharHold,
  selectedAadharFileName,
  setSelectedAadharFileName,
  selectedPancardFileName,
  setSelectedPancardFileName,
  aadharBack,
  setAadharBack,
  errorForAadharBack,
  setErrorForAadharBack,
  aadharBackFileType,
  setAadharBackFileType,
  selectedAadharBackFileName,
  setSelectedAadharBackFileName,
  aadharNumber,
  pancardNumber,
  errorForAadharNumber,
  errorForPancardNumber,
  handleAadharNumberChange,
  handlePancardNumberChange,
}) => {
  const { isMobile, setKYCPageNumber } = useContext(AppContext);

  const handleSubmitBackButton = () => {
    setKYCPageNumber(-1);
  };
  const CardBody = () => (
    <div
      className={cx(styles.profileMainContainer, {
        [styles.profileMainContainerMob]: isMobile,
      })}
    >
      <ProfileDetailsFetch
        reSubmitKyc={reSubmitKyc}
        setResubmitKyc={setResubmitKyc}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        email={email}
        handleChangeInputFieldFirstName={handleChangeInputFieldFirstName}
        handleChangeInputFieldLastName={handleChangeInputFieldLastName}
        handlePhoneNumberChange={handlePhoneNumberChange}
        setErrorForID={setErrorForID}
        handleChangeInputFieldEmail={handleChangeInputFieldEmail}
        setErrorForPancard={setErrorForPancard}
        setErrorForAadhar={setErrorForAadhar}
        panCard={panCard}
        setPanCard={setPanCard}
        aadharCard={aadharCard}
        setAadharCard={setAadharCard}
        isFileUploaded={isFileUploaded}
        setIsFileUploaded={setIsFileUploaded}
        pancardFileType={pancardFileType}
        setPancardFileType={setPancardFileType}
        aadharFileType={aadharFileType}
        setAadharFileType={setAadharFileType}
        aadharHolding={aadharHolding}
        setAadharHolding={setAadharHolding}
        errorForAadharHold={errorForAadharHold}
        setErrorForAadharHold={setErrorForAadharHold}
        selectedAadharFileName={selectedAadharFileName}
        setSelectedAadharFileName={setSelectedAadharFileName}
        selectedPancardFileName={selectedPancardFileName}
        setSelectedPancardFileName={setSelectedPancardFileName}
        aadharBack={aadharBack}
        setAadharBack={setAadharBack}
        setErrorForAadharBack={setErrorForAadharBack}
        aadharBackFileType={aadharBackFileType}
        setAadharBackFileType={setAadharBackFileType}
        selectedAadharBackFileName={selectedAadharBackFileName}
        setSelectedAadharBackFileName={setSelectedAadharBackFileName}
        aadharNumber={aadharNumber}
        pancardNumber={pancardNumber}
        handleAadharNumberChange={handleAadharNumberChange}
        handlePancardNumberChange={handlePancardNumberChange}
      />

      <div
        className={cx(styles.updateProfileButtonDiv, {
          [styles.updateProfileButtonDivWeb]: !isMobile,
          [styles.updateProfileButtonDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.backButton, {
            [styles.backButtonWeb]: !isMobile,
            [styles.backButtonMob]: isMobile,
          })}
          onClick={handleSubmitBackButton}
        >
          {SEND_ENUM.back}
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={cx(styles.updateProfileButton, {
            [styles.updateProfileButtonWeb]: !isMobile,
            [styles.updateProfileButtonMob]: isMobile,
          })}
          onClick={handlesubmitUpdateProfile}
        >
          {PROFILE_ENUM.proceed}
        </ButtonRade>
      </div>
      {invalidEmail && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.invalidEmail}
        </div>
      )}
      {errorForProfileUpdate && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.emptyInput}
        </div>
      )}
      {invalidNumber && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.invalidNumber}
        </div>
      )}
      {errorForFirstName && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.errorForFirstName}
        </div>
      )}
      {errorForLastName && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.errorForLastName}
        </div>
      )}
      {errorForEmail && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.errorForEmail}
        </div>
      )}
      {errorForNumber && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.errorForNumber}
        </div>
      )}
      {errorForPancardNumber && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.errorForPancardNumber}
        </div>
      )}
      {errorForAadharNumber && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.errorForAadharNumber}
        </div>
      )}
      {error && <div className={styles.emailValidationError}>{error}</div>}
      {errorForID && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.errorForID}
        </div>
      )}
      {errorForPancard && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.errorForPancard}
        </div>
      )}
      {errorForAadhar && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.errorForAadhar}
        </div>
      )}
      {errorForAadharBack && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.errorForAadharBack}
        </div>
      )}
      {errorForAadharHold && (
        <div className={styles.emailValidationError}>
          {PROFILE_ENUM.errorForAadharHold}
        </div>
      )}
      {updationSuccessful && (
        <div className={styles.updateProfileSuccesfully}>
          {PROFILE_ENUM.updateProfileSuccesfully}
        </div>
      )}
    </div>
  );

  return (
    <>
      <div
        className={cx(styles.routesContainer, {
          [styles.routesContainerMob]: isMobile,
        })}
      >
        {" "}
        {CardBody()}
      </div>

      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ConfirmUpdateProfile
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          setUpdationSuccesful={setUpdationSuccesful}
          setKYCPageNumber={setKYCPageNumber}
          handlesubmitUpdateProfile={handlesubmitUpdateProfile}
        />
      </Modal>
    </>
  );
};

export default Profile;
