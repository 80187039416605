import React from "react";

function FormatTimer({ ms }) {
  const minutes = Math.floor(ms / 1000 / 60);
  const seconds = Math.floor((ms / 1000) % 60);
  return (
    <div style={{ textAlign: "center" }}>
      <span>
        {minutes}:{seconds < 10 ? "0" : ""}
        {seconds}
      </span>
    </div>
  );
}
export default FormatTimer;
